import * as React from "react"
import ButtonDropdown from "@rio-cloud/rio-uikit/ButtonDropdown"
import { FormattedMessage, useIntl } from "react-intl"
import EmptiesCsvExportButton from "./EmptiesCsvExportButton"
import moment, { Moment } from "moment-timezone"
import { useCallback, useEffect, useState } from "react"
import { TransportOrder, TransportOrderType } from "../../../model/transportorder/transportOrder"
import MaterialCsvExportButton from "./MaterialCsvExportButton"
import { MenuItemProps } from "@rio-cloud/rio-uikit/MenuItem"

interface CSVExportButtonDropdownProps {
    fromDate: Date | undefined
    toDate: Date | undefined
    transportOrders: TransportOrder[]
}

export const CSVExportButtonDropdown = (props: CSVExportButtonDropdownProps): React.ReactElement | null => {
    const intl = useIntl()
    const filterByType = useCallback(
        (type: TransportOrderType): TransportOrder[] => props.transportOrders.filter((it) => type === it.orderType),
        [props.transportOrders]
    )

    const getEarliestFromDate = useCallback(
        (): Moment  => {
            let earliestDate = moment().startOf("day")
            props.transportOrders.forEach((it) => {
                const createdDate: Moment = moment(it.created_at).tz("UTC")

                if (createdDate.isBefore(earliestDate)) {
                    earliestDate = createdDate.tz(moment.tz.guess()).startOf("day")
                }
            })

            return earliestDate
        },
        [props.transportOrders]
    )

    const [filteredTransportOrdersEmpties, setFilteredTransportOrdersEmpties] = useState<TransportOrder[]>([])
    const [filteredTransportOrdersMaterial, setFilteredTransportOrdersMaterial] = useState<TransportOrder[]>([])
    const [from, setDateFrom] = useState<Moment>(moment(props.fromDate))
    const [to, setDateTo] = useState<Moment>(moment(props.toDate))

    useEffect(() => {
        setFilteredTransportOrdersEmpties(filterByType(TransportOrderType.EMPTIES))
        setFilteredTransportOrdersMaterial(filterByType(TransportOrderType.MATERIAL))
    }, [props.transportOrders, filterByType])

    useEffect(() => {
        const earliestFrom = getEarliestFromDate()
        const newFrom = props.fromDate ? moment(props.fromDate) : earliestFrom
        const newTo = props.toDate ? moment(props.toDate) : moment().endOf("day")
        setDateFrom(newFrom)
        setDateTo(newTo)
    }, [props.fromDate, props.toDate, props.transportOrders, getEarliestFromDate])

    const formattedDateRange = `${from.format("YYYY-MM-DD_HHmm")}_${to.format("YYYY-MM-DD_HHmm")}`

    return <>
        <ButtonDropdown
            title={<>
                <span className="rioglyph rioglyph-document-out margin-right-5"/>
                <FormattedMessage id={"transportOrder.common.csvExport"}/>
            </>}
            items={[
                emptiesExportMenuItem(formattedDateRange, filteredTransportOrdersEmpties, intl.formatMessage({ id: "transportOrder.csvexport.empties.buttonText" })),
                materialsExportMenuItem(formattedDateRange, filteredTransportOrdersMaterial, intl.formatMessage({ id: "transportOrder.csvexport.material.buttonText" })),
            ]}
            disabled={props.transportOrders.length === 0}
            className={"hover-text-color-white"}
        />
    </>
}

export const emptiesExportMenuItem = (formattedDateRange: string, transportOrders: TransportOrder[], buttonText: string): MenuItemProps => {
    return {
        value: <EmptiesCsvExportButton
            transportOrders={transportOrders}
            buttonText={buttonText}
            formattedDateRange={formattedDateRange}
            active={true}
            className={"text-color-darkest text-decoration-none hover-text-color-white"}
        />
    }
}

const materialsExportMenuItem = (formattedDateRange: string, transportOrders: TransportOrder[], buttonText: string): MenuItemProps => {
    return {
        value: <MaterialCsvExportButton
            transportOrders={transportOrders}
            buttonText={buttonText}
            formattedDateRange={formattedDateRange}
            active={true}
            className={"text-color-darkest text-decoration-none hover-text-color-white"}
        />
    }
}
