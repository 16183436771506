import { Shipment, TransportOrder, TransportOrderType } from "../../model/transportorder/transportOrder"
import { ReactElement } from "react"
import { CustomerPresenter } from "./CustomerPresenter"
import { canTransportOrderBeAccepted } from "../../model/transportorder/transportOrderEditing"
import { ShipmentPresenter } from "./ShipmentPresenter"
import { MaterialOrderConfirmationInput } from "./confirmation/MaterialOrderConfirmationInput"
import { EmptiesOrderConfirmationInput } from "./confirmation/EmptiesOrderConfirmationInput"
import { ShipmentConfirmation } from "../../model/confirmation/Confirmation"
import { flattenHandlingUnitsOfShipment } from "../../model/transportorder/transportOrderFlattening"

export interface TransportOrderPresenterProps {
    transportOrder: TransportOrder
    onConfirmationsChange: (_: ShipmentConfirmation[]) => void
}

export const TransportOrderPresenter = (props: TransportOrderPresenterProps): ReactElement => {
    const transportOrder = props.transportOrder
    const customerData = transportOrder.customer_data
    const shipments: Shipment[] = (transportOrder._embedded?.shipments ?? [])
        .map((shipment) => flattenHandlingUnitsOfShipment(shipment))
    const shipmentPresenters = shipments.map((shipment, idx) => {
        return (
            <div key={shipment.id} data-testid={`ShipmentPresenter-${idx + 1}`}>
                <ShipmentPresenter
                    shipment={shipment}
                    shipmentNumber={idx + 1}
                    orderIdentifier={(transportOrder.orderType === TransportOrderType.EMPTIES)
                        ? transportOrder.external_order_number : undefined} />
            </div>
        )
    })

    return (
        <>
            <CustomerPresenter customerData={customerData}/>
            {canTransportOrderBeAccepted(props.transportOrder) && <ConfirmationInput {...props} />}
            {shipmentPresenters}
        </>
    )
}

const ConfirmationInput = (props: {
    transportOrder: TransportOrder
    onConfirmationsChange: (_: ShipmentConfirmation[]) => void
}): ReactElement => {
    switch (props.transportOrder.orderType) {
    case TransportOrderType.MATERIAL:
        return <MaterialOrderConfirmationInput
            transportOrder={props.transportOrder}
            onChange={props.onConfirmationsChange}/>
    case TransportOrderType.EMPTIES:
        return <EmptiesOrderConfirmationInput
            transportOrder={props.transportOrder}
            onChange={props.onConfirmationsChange}/>
    }
}
