/*
 Models converted in this file are deprecated.
 To be removed once old transport order service is replaced by transport order hub.
 Date of writing this: 2022-04-19
 */
import {
    Address,
    Buyer,
    Contact,
    CustomerData,
    DangerousGoodsInformation,
    LoadingAddress,
    LoadingAddressExternalIdentifier,
    Shipment,
    ShipmentPriority,
    ShipmentStatus,
    TimeWindow,
    TransportOrder,
    TransportOrderStatus,
    TransportOrderType,
    VehicleType
} from "../model/transportorder/transportOrder"
import { Confirmation } from "../model/confirmation/Confirmation"
import {
    ExternalAddress,
    ExternalBuyer,
    ExternalContact,
    ExternalFreightPayer,
    ExternalLoading,
    ExternalPartyExternalIdentifiers,
    ExternalPriority,
    ExternalTransportOrder,
    ExternalTransportOrderBundle,
    ExternalUnloading
} from "../client/transportorder-hub/model"
import { ExternalDangerousGoodsInformation, ExternalGoodsType } from "../client/transportorder-hub/goods.model"
import { convertExternalFreight } from "./transportOrderGoodsConverter"
import {
    ExternalFreightForwarderConfirmation,
    ExternalFreightForwarderResponse,
    ExternalLoading as FreightForwarderResponseExternalLoading,
    ExternalUnloading as FreightForwarderResponseExternalUnloading,
    ExternalVehicleType,
} from "../client/transportorder-hub/FreightForwarderResponse.model"

const convertLoadingAddressExternalIdentifiers = (src: ExternalPartyExternalIdentifiers): LoadingAddressExternalIdentifier[] => {
    const externalIdentifiers: LoadingAddressExternalIdentifier[] = []
    const loli = src.supplier_code
    if (loli) {
        externalIdentifiers.push({
            type: "VW_LOLI",
            identifier: loli
        })
    }
    const plantCode = src.plant_code
    if (plantCode) {
        externalIdentifiers.push({
            type: "VW_PLANT_CODE",
            identifier: plantCode
        })
    }
    return externalIdentifiers
}

const convertExternalLoading = (src: ExternalLoading): LoadingAddress => {
    return {
        address: convertExternalAddress(src.shipper.address),
        location_identification: { place_of_loading: src.place_of_loading },
        name: src.shipper.name,
        time_window: src.time_window ? {
            time_from: src.time_window.time_from,
            time_to: src.time_window.time_to
        } : undefined,
        contact: convertExternalContact(src.shipper.contact),
        accessCode: src.access_code,
        external_identifiers: convertLoadingAddressExternalIdentifiers(src.shipper.external_identifiers),
        duns: src.shipper.external_identifiers.duns,
    }
}

const convertExternalUnloading = (src: ExternalUnloading): LoadingAddress => {
    return {
        address: convertExternalAddress(src.recipient.address),
        location_identification: { place_of_discharge: src.place_of_discharge },
        name: src.recipient.name,
        time_window: src.time_window ? {
            time_from: src.time_window.time_from,
            time_to: src.time_window.time_to
        } : undefined,
        contact: convertExternalContact(src.recipient.contact),
        accessCode: src.access_code,
        external_identifiers: convertLoadingAddressExternalIdentifiers(src.recipient.external_identifiers),
        duns: src.recipient.external_identifiers.duns,
    }
}

const convertToShipmentStatus = (cancelled: boolean | undefined): ShipmentStatus => {
    return cancelled === true ? ShipmentStatus.CANCELLED : ShipmentStatus.UNPLANNED
}

const convertExternalPriority = (priority: ExternalPriority): ShipmentPriority => {
    return ShipmentPriority[priority.toString().toUpperCase()]
}

const convertExternalGoodsType: (goods_type: ExternalGoodsType) => TransportOrderType = (goods_type) => {
    if (goods_type === ExternalGoodsType.CARGO_EMPTIES) {
        return TransportOrderType.EMPTIES
    }
    return TransportOrderType.MATERIAL
}

const convertExternalFreightForwarderResponse = (src: ExternalFreightForwarderResponse): Confirmation => ({
    updatedAt: src.last_modified_at,
    asset: src.means_of_transport?.asset_id ? {
        id: src.means_of_transport.asset_id,
        licensePlate: src.means_of_transport.license_plate,
        countryCode: src.means_of_transport.country_code,
    } : undefined,
    accessCodeAtLoading: src.loading?.access_code,
    accessCodeAtUnloading: src.unloading?.access_code,
    loadingTimeWindow: src.loading?.time_window ? {
        time_from: src.loading.time_window.time_from,
        time_to: src.loading.time_window.time_to,
    }: undefined,
    unloadingTimeWindow: src.unloading?.time_window ? {
        time_from: src.unloading.time_window.time_from,
        time_to: src.unloading.time_window.time_to,
    }: undefined,
    phoneNumber: src.driver?.phone_number,
    vehicleType: src.billing_information?.vehicle_type,
    twoDrivers: src.billing_information?.two_drivers,
    loadId: src.load_id,
    crossDockId: src.cross_dock?.id
})

export const convertConfirmation = (src: Confirmation): ExternalFreightForwarderConfirmation => ({
    type: "CONFIRMATION",
    load_id: src.loadId,
    means_of_transport: src.asset ? {
        mode: "ROAD",
        asset_id: src.asset.id,
        license_plate: src.asset.licensePlate,
        country_code: src.asset.countryCode,
    }: undefined,
    billing_information: (src.vehicleType !== undefined || src.twoDrivers !== undefined) ? {
        vehicle_type: src.vehicleType !== undefined ? convertVehicleType(src.vehicleType) : undefined,
        two_drivers: src.twoDrivers,
    }: undefined,
    loading: convertToFreightForwarderResponseExternalLoadingOrUnloading(src.accessCodeAtLoading, src.loadingTimeWindow),
    unloading: convertToFreightForwarderResponseExternalLoadingOrUnloading(src.accessCodeAtUnloading, src.unloadingTimeWindow),
    driver: src.phoneNumber ? {
        phone_number: src.phoneNumber,
    } : undefined,
    cross_dock: src.crossDockId ? {
        id: src.crossDockId
    }: undefined
})

const convertVehicleType = (src: VehicleType): ExternalVehicleType => {
    switch (src) {
    case "PASSENGER_CAR":
        return ExternalVehicleType.PASSENGER_CAR
    case "VAN":
        return ExternalVehicleType.VAN
    case "LIGHT_TRUCK":
        return ExternalVehicleType.LIGHT_TRUCK
    case "MEDIUM_TRUCK":
        return ExternalVehicleType.MEDIUM_TRUCK
    }
}

const convertToFreightForwarderResponseExternalLoadingOrUnloading = (
    accessCode: string | undefined,
    timeWindow: TimeWindow | undefined
): FreightForwarderResponseExternalLoading | FreightForwarderResponseExternalUnloading | undefined => {
    if (accessCode === undefined && timeWindow === undefined) {
        return undefined
    }
    return {
        access_code: accessCode,
        time_window: timeWindow ? {
            // TODO: time window model can be reworked once we drop support for the old transport order service
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            time_from: timeWindow.time_from!,
            time_to: timeWindow.time_to,
        }: undefined
    }
}

function convertAndAddPrefillsToConfirmation(externalTransportOrder: ExternalTransportOrder, goods_type: ExternalGoodsType): Confirmation {
    const freightForwarderResponse = externalTransportOrder.freight_forwarder_response
    if (freightForwarderResponse !== undefined) {
        return convertExternalFreightForwarderResponse(freightForwarderResponse)
    }

    const orderType = convertExternalGoodsType(goods_type)
    switch (orderType) {
    case TransportOrderType.MATERIAL:
        return convertAndAddPrefillsToConfirmationMaterial(externalTransportOrder)
    case TransportOrderType.EMPTIES:
        return convertAndAddPrefillsToConfirmationEmpties(externalTransportOrder)
    }
}

const convertAndAddPrefillsToConfirmationMaterial = (externalTransportOrder: ExternalTransportOrder): Confirmation => {
    switch (externalTransportOrder.priority) {
    case ExternalPriority.REGULAR:
        return {
            accessCodeAtUnloading: externalTransportOrder.unloading.access_code,
            loadingTimeWindow: externalTransportOrder.loading.time_window,
        }
    case ExternalPriority.URGENT:
        return {
            accessCodeAtUnloading: externalTransportOrder.unloading.access_code,
            vehicleType: externalTransportOrder.proposed_vehicle_type,
            unloadingTimeWindow: externalTransportOrder.unloading.time_window,
            twoDrivers: externalTransportOrder.requires_two_drivers,
        }
    }
}

const convertAndAddPrefillsToConfirmationEmpties = (externalTransportOrder: ExternalTransportOrder): Confirmation => ({
    accessCodeAtLoading: externalTransportOrder.loading.access_code,
    loadingTimeWindow: externalTransportOrder.loading.time_window,
})

export const convertExternalTransportOrder = (src: ExternalTransportOrder, goods_type: ExternalGoodsType): Shipment => {
    return {
        external_identification: src.external_id,
        freight: convertExternalFreight(src.goods),
        processIndicator: src.process_indicator,
        id: src.id,
        loading_address: convertExternalLoading(src.loading),
        unloading_address: convertExternalUnloading(src.unloading),
        transport_order_id: src.bundle_id,
        status: convertToShipmentStatus(src.cancelled),
        confirmationDraft: convertAndAddPrefillsToConfirmation(src, goods_type),
        isConfirmed: src.freight_forwarder_response !== undefined,
        last_modified_date: src.last_modified_at,
        proposedVehicleType: src.proposed_vehicle_type,
        priority: convertExternalPriority(src.priority),
        requiresTwoDrivers: src.requires_two_drivers,
        version: src.etag,
    }
}

const convertExternalAddress = (src: ExternalAddress | undefined): Address | undefined => {
    return src ? {
        city: src.city,
        country: src.country,
        postal_code: src.postal_code,
        street: src.street
    } : undefined
}

const convertExternalContact = (src: ExternalContact | undefined): Contact | undefined => {
    return src ? {
        name: src.name,
        email: src.email,
        phone: src.phone,
    } : undefined
}

const convertFreightPayer = (external_freight_payer: ExternalFreightPayer | undefined): CustomerData | undefined => {
    return external_freight_payer && {
        name: external_freight_payer.name,
    }
}

export const convertExternalDangerousGoodsInformation = (src: ExternalDangerousGoodsInformation | undefined): DangerousGoodsInformation | undefined => {
    return src ? {
        undg_code: src.undg,
        description: src.description,
        exception_regulation: src.exception_regulation,
    } : undefined
}

const convertExternalTransportOrderBundleStatus = (externalTransportOrders: Array<ExternalTransportOrder>): TransportOrderStatus => {
    const cancelled = externalTransportOrders.every(order => order.cancelled)
    if (cancelled) {
        return TransportOrderStatus.CANCELLED
    }
    return TransportOrderStatus.UNPLANNED
}

const convertExternalBuyer = (external_buyer: ExternalBuyer | undefined): Buyer | undefined => {
    return external_buyer && {
        name: external_buyer.name,
        address: convertExternalAddress(external_buyer.address)
    }
}

const convertExternalBundleIdForEmpties = (external_bundle_id: string): string | undefined => {
    /*
    Original ticket of this logic: RIODISP-1758:
        The Leergutauftragsnummer (sometimes also called "interne Tournummer")
        is part of the RFF+AGK defined in VDA 4933 T2: AGK${LOLI, 10-stellig}${TOUR_NUMMER}.
     */
    const match = external_bundle_id.match(/^AGK.{10}(.+?)$/)
    if (match) {
        return match[1]
    } else {
        return external_bundle_id
    }
}

const getExternalOrderNumber = (external_bundle_id: string | undefined, goods_type: ExternalGoodsType): string | undefined => {
    if (external_bundle_id === undefined) {
        return undefined
    }
    const orderType = convertExternalGoodsType(goods_type)
    if (orderType === TransportOrderType.MATERIAL) {
        return external_bundle_id
    }
    return convertExternalBundleIdForEmpties(external_bundle_id)
}

export const convertExternalTransportOrderBundle = (src: ExternalTransportOrderBundle): TransportOrder => {
    const firstExternalTransportOrder = src._embedded.transport_orders[0]
    const externalGoodsType = firstExternalTransportOrder.goods.type
    return {
        _embedded: {
            shipments: src._embedded.transport_orders.map(
                it => convertExternalTransportOrder(it, externalGoodsType)
            )
        },
        customer_data: convertFreightPayer(firstExternalTransportOrder.freight_payer),
        buyer: convertExternalBuyer(firstExternalTransportOrder.buyer),
        created_at: src.created_at,
        external_order_created_date: src._embedded.transport_orders[0].external_order_created_date,
        id: src.id,
        orderType: convertExternalGoodsType(externalGoodsType),
        status: convertExternalTransportOrderBundleStatus(src._embedded.transport_orders),
        external_order_number: getExternalOrderNumber(src.external_bundle_id, externalGoodsType),
    }
}
