import { ExternalList } from "../common/ExternalList"
import { Asset } from "../../model/asset/asset"

export interface ExternalAsset {
    id: string
    name: string
    status: string
    type: string
    license_plate?: string
    license_plate_country_code?: string
}

export type ExternalAssetList = ExternalList<ExternalAsset>

export const convertToDomain = (externalAssetList: ExternalAssetList): Asset[] =>
    externalAssetList.items.map((item): Asset => ({
        id: item.id,
        name: item.name,
        licensePlate: item.license_plate,
        licensePlateCountryCode: item.license_plate_country_code,
        status: item.status,
        type: item.type
    }))
