import { ReactNode } from "react"

interface FormGroupWrapperProps {
    component: ReactNode
    label?: ReactNode
    required?: boolean
    hasError?: boolean
    hasWarning?: boolean
    hint?: ReactNode
    className?: string
    dataTestId?: string
}

const LabelWithRequiredIndicator = (props: { label: ReactNode, required: boolean }): ReactNode => {
    return <label className={"text-nowrap"}>{props.label}{props.required ? "*" : ""}</label>
}

const ErrorHint = (props: { show?: boolean, hint?: ReactNode }): ReactNode => {
    const errorIcon = props.show
        ? <span className={"form-control-feedback rioglyph rioglyph-error-sign"} aria-hidden={"true"}/>
        : null
    const errorHelpHint = props.show && props.hint
        ? <span className={"help-block"}><span>{props.hint}</span></span>
        : null
    return (
        <>
            {errorIcon}
            {errorHelpHint}
        </>
    )
}

export const FormGroupWrapper = (props: FormGroupWrapperProps): ReactNode => {
    const { label, component, hasError, hasWarning } = props

    if (!label) {
        return component
    }

    const formWrapperClassName = `form-group ${
        hasError ? "has-error has-feedback" : hasWarning ? "has-warning has-feedback" : ""} ${props.className || ""}`

    return (
        <div className={formWrapperClassName} data-testid={props.dataTestId}>
            <LabelWithRequiredIndicator label={label} required={props.required ?? false} />
            {component}
            <ErrorHint show={hasError || hasWarning} hint={props.hint}/>
        </div>
    )
}
