import { Asset } from "../model/asset/asset"
import { useEffect, useState } from "react"
import mfhClient from "../client/mfh/MfhClient"

export const useAssetsOfAccount = (): Asset[] => {
    const [assets, setAssets] = useState<Asset[]>([])
    useEffect((): void => {
        mfhClient.getAssets()
            .then(assets => assets.sort((a, b) => a.name.localeCompare(b.name)))
            .then(assets => {
                setAssets(assets)
            })
            .catch(() => {
                setAssets([])
            })
    }, [])
    return assets
}