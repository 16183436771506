import { ReactElement } from "react"
import { Contact } from "../../model/transportorder/transportOrder"
import { FormattedMessage } from "react-intl"
import { ReadOnlyDetailViewFormValue } from "../Form/ReadOnlyDetailViewFormValue"

export interface ContactPresenterProps {
    contact?: Contact
}

export const ContactPresenter = (props: ContactPresenterProps): ReactElement => {
    const contactOrDefault: Contact = props.contact || {}

    return (
        <div className="row">
            <ReadOnlyDetailViewFormValue
                value={contactOrDefault.name}
                xs={12}
                print={50}
                label={<FormattedMessage id="transportOrder.shipment.address.contact.name"/>}
            />
            <ReadOnlyDetailViewFormValue
                value={contactOrDefault.phone}
                xs={12}
                print={50}
                label={<FormattedMessage id="transportOrder.shipment.address.contact.phone"/>}
            />
            <ReadOnlyDetailViewFormValue
                value={contactOrDefault.email}
                xs={12}
                print={50}
                label={<FormattedMessage id="transportOrder.shipment.address.contact.email"/>}
            />
        </div>
    )
}
