import moment from "moment-timezone"
import { Address, HandlingUnit, LoadingAddress, TimeWindow } from "../model/transportorder/transportOrder"
import { pullUpArticles } from "../model/transportorder/transportOrderFlattening"
import { normalizeToKgm, normalizeToMtr } from "../model/transportorder/measurement"
import { CsvExportRow as EmptiesCsvExportRow } from "../components/TransportOrder/CSVExport/EmptiesCsvExportButton"
import { CsvExportRow as MaterialCsvExportRow } from "../components/TransportOrder/CSVExport/MaterialCsvExportButton"
import { IntlShape } from "react-intl"

export const toHumanReadableDate = (locale: string, dateString?: string, timezone: string | undefined = "UTC"): string | undefined => {
    if (!dateString || !timezone) {
        return undefined
    }
    return moment(dateString).tz(timezone).locale(locale).format("L")
}

export const toHumanReadableTime = (locale: string, dateString?: string, timezone: string | undefined = "UTC"): string | undefined => {
    if (!dateString || !timezone) {
        return undefined
    }
    return moment(dateString).tz(timezone).locale(locale).format("LT")
}

export const toHumanReadableDateAndTime = (locale: string, dateString?: string, timezone: string | undefined = "UTC"): string | undefined => {
    if (!dateString || !timezone) {
        return undefined
    }
    const localizedDate = moment(dateString).tz(timezone).locale(locale)
    return `${localizedDate.format("L")} ${localizedDate.format("LT")}`
}

export const toHumanReadableTimeWindow = (locale: string, timeWindow?: TimeWindow, timezone: string | undefined = "UTC"): string | undefined => {
    if (!timeWindow || !timezone) {
        return undefined
    }
    const startDateTime = timeWindow.time_from && moment(timeWindow.time_from).tz(timezone).locale(locale)
    const endDateTime = moment(timeWindow.time_to).tz(timezone).locale(locale)
    const formattedEndDateTime = `${endDateTime.format("L")} ${endDateTime.format("LT")}`
    if (startDateTime && !startDateTime.isSame(endDateTime)) {
        const formattedStartDateTime =  `${startDateTime.format("L")} ${startDateTime.format("LT")}`
        return `${formattedStartDateTime} - ${formattedEndDateTime}`
    } else {
        return formattedEndDateTime
    }
}

export const joinStreetAndHouseNumberOfAddress = (address?: Address): string => {
    const street = address?.street
    const houseNumber = address?.house_number
    return joinStreetAndHouseNumber(street, houseNumber)
}

export const joinStreetAndHouseNumberOfLoadingAddress = (loadingAddress?: LoadingAddress): string | undefined => {
    if (!loadingAddress || !loadingAddress.address || (!loadingAddress.address.street && !loadingAddress.address.house_number)) {
        return undefined
    }
    return joinStreetAndHouseNumberOfAddress(loadingAddress.address)
}

const joinStreetAndHouseNumber = (street?: string, houseNumber?: string): string => [street, houseNumber]
    .filter(it => it)
    .join(" ")


type StringifiedCsvExportRow = {[key: string]: string}

export const formatInUserLocale = (obj: EmptiesCsvExportRow | MaterialCsvExportRow, intl: IntlShape): StringifiedCsvExportRow => {
    const newObject = {}
    for (const prop in obj) {
        const propValue = obj[prop]
        if (typeof propValue === "number") {
            newObject[prop] = propValue.toLocaleString(intl.locale, { maximumFractionDigits: 5 })
        } else {
            newObject[prop] = propValue
        }
    }
    return newObject
}

export const calculateVolume = (handlingUnit: HandlingUnit): number | undefined => {
    const normalizedWidth = normalizeToMtr(handlingUnit.dimension?.width)
    const normalizedLength = normalizeToMtr(handlingUnit.dimension?.length)
    const normalizedHeight = normalizeToMtr(handlingUnit.dimension?.height)
    if (!normalizedWidth || !normalizedWidth.value ||
        !normalizedLength || !normalizedLength.value ||
        !normalizedHeight || !normalizedHeight.value) {
        return undefined
    }

    const volumePerHandlingUnitInMTQ = normalizedWidth.value * normalizedLength.value * normalizedHeight.value
    const quantity = handlingUnit.quantity ?? 1

    return (quantity * volumePerHandlingUnitInMTQ)
}

export const calculateWeight = (handlingUnit: HandlingUnit): number | undefined => {
    const normalizedWeight = normalizeToKgm(handlingUnit.weight)
    if (!normalizedWeight || !normalizedWeight.value) {
        return undefined
    }

    const quantity = handlingUnit.quantity ?? 1
    return (quantity * normalizedWeight.value)
}

export const aggregateNetExplosiveMassInG = (handlingUnit: HandlingUnit): number | undefined => {

    const multiply = (a: number | undefined, b: number | undefined): number | undefined => a !== undefined && b !== undefined ? a * b : undefined
    const add = (a: number | undefined, b: number | undefined): number | undefined => a !== undefined ? b !== undefined ? a + b : a : b

    const articles = pullUpArticles(handlingUnit)
    const nemArticlesAggregated = articles.content.reduce<number | undefined>(((previousValue, currentArticle) => {
        const currentNemKg = normalizeToKgm(currentArticle.net_explosive_mass)
        return add(previousValue, multiply(currentNemKg?.value, currentArticle.quantity))
    }), undefined)
    const nemTotal = multiply(nemArticlesAggregated, handlingUnit.quantity)
    return multiply(nemTotal, 1000)
}
