export interface ICrossDock {
    id: string
    name: string
}


export const CrossDock = (id: string, name: string): ICrossDock => {
    return {
        id, name
    }
}
