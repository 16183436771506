import { createIntl, createIntlCache } from "react-intl"
import "moment/dist/locale/cs"
import "moment/dist/locale/de"
import "moment/dist/locale/fr"
import "moment/dist/locale/hu"
import "moment/dist/locale/pl"
import "moment/dist/locale/sk"

import { createSelector } from "@reduxjs/toolkit"
import { State } from "./store"
// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache()

const messages: { [id: string]: { [key: string]: string } } = {
    "cs": (await import("../lang/cs-CZ.json")).default,
    "bg": (await import("../lang/bg-BG.json")).default,
    "da": (await import("../lang/da-DK.json")).default,
    "de": (await import("../lang/de-DE.json")).default,
    "el": (await import("../lang/el-GR.json")).default,
    "en": (await import("../lang/en-US.json")).default,
    "es": (await import("../lang/es-ES.json")).default,
    "et": (await import("../lang/et-EE.json")).default,
    "fi": (await import("../lang/fi-FI.json")).default,
    "fr": (await import("../lang/fr-FR.json")).default,
    "hr": (await import("../lang/hr-HR.json")).default,
    "hu": (await import("../lang/hu-HU.json")).default,
    "it": (await import("../lang/it-IT.json")).default,
    "ko": (await import("../lang/ko-KR.json")).default,
    "lt": (await import("../lang/lt-LT.json")).default,
    "lv": (await import("../lang/lv-LV.json")).default,
    "nb": (await import("../lang/nb-NO.json")).default,
    "nl": (await import("../lang/nl-NL.json")).default,
    "pl": (await import("../lang/pl-PL.json")).default,
    "pt": (await import("../lang/pt-PT.json")).default,
    "ro": (await import("../lang/ro-RO.json")).default,
    "sk": (await import("../lang/sk-SK.json")).default,
    "sl": (await import("../lang/sl-SI.json")).default,
    "sv": (await import("../lang/sv-SE.json")).default,
}

interface Locale {
    tag: string
    short: string
}

function bestFit(languages: ReadonlyArray<string>): Locale | undefined {
    return languages.map(locale => ({ tag: locale, short: locale.split("-")[0] }))
        .find(elem => messages[elem.short])
}

// See https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/languages
function getLocaleFromNavigatorLanguages(): Locale | undefined {
    if (navigator.languages === undefined) {
        return undefined
    }
    return bestFit(navigator.languages)
}

// Special for IE https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/languages
function getLocaleFromNavigatorUserLanguage(): Locale | undefined {
    // Typescript does not know about window.navigator.userLanguage
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const userLanguage = window.navigator["userLanguage"] as string | undefined
    if (userLanguage === undefined) {
        return undefined
    }
    return bestFit([userLanguage])
}

function guessLocale(preferred = ""): Locale {
    const preferredLocale = bestFit([preferred])
    if (preferredLocale) {
        return preferredLocale
    }
    const fromNavigatorLanguages = getLocaleFromNavigatorLanguages()
    if (fromNavigatorLanguages) {
        return fromNavigatorLanguages
    }
    const fromNavigatorUserLanguage = getLocaleFromNavigatorUserLanguage()
    if (fromNavigatorUserLanguage) {
        return fromNavigatorUserLanguage
    }
    const defaultLocale = "en-US"
    const defaultShortLocale = "en"

    return { tag: defaultLocale, short: defaultShortLocale }
}

export const getProfileLocale = (state: State) => state.login?.userProfile?.locale

export const getLanguageSettings = createSelector([getProfileLocale], profileLocale => {
    const locale = guessLocale(profileLocale)
    const localeMessages = messages[locale.short]
    const intl = createIntl({
        locale: locale.tag,
        messages: localeMessages
    }, cache)
    return { locale: locale.tag, messages: localeMessages, intl: intl }
})
