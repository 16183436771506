import { CountryOfOrigin } from "../../model/transportorder/transportOrder"
import * as React from "react"
import { ReactElement } from "react"
import { FormattedMessage } from "react-intl"
import { ReadOnlyDetailViewFormValue } from "../Form/ReadOnlyDetailViewFormValue"

interface Props {
    value?: CountryOfOrigin
    countryCodeClassName?: string
    dutiableGoodsClassName?: string
}

export const CountryOfOriginPresenter = (props: Props): React.ReactElement => {
    return <>
        <div className={props.countryCodeClassName}>
            <ReadOnlyDetailViewFormValue
                value={props.value?.country_code}
                label={<FormattedMessage id={"transportOrder.shipment.cargo.article.countryOfOrigin.countryCode"} />}
            />
        </div>
        <div className={props.dutiableGoodsClassName}>
            <ReadOnlyDetailViewFormValue
                value={props.value?.dutiable_goods}
                label={<FormattedMessage id={"transportOrder.shipment.cargo.article.countryOfOrigin.dutiableGoods"} />}
                formatProperty={(dutiableGoods: boolean): ReactElement => dutiableGoods
                    ? <FormattedMessage id={"yes"} />
                    : <FormattedMessage id={"no"} />
                } />
        </div>
    </>
}
