import { FormattedMessage, useIntl } from "react-intl"
import { LengthMeasurement, LengthMeasurementUnit } from "../../model/transportorder/measurement"
import { ReadOnlyDetailViewFormValue } from "./ReadOnlyDetailViewFormValue"
import { ReactNode } from "react"

export interface ILengthMeasurementInputProps {
    length?: LengthMeasurement
    label?: ReactNode
    placeholder?: string
}

export const LengthPresenter = (props: ILengthMeasurementInputProps): ReactNode => {
    const unitOptions = {
        [LengthMeasurementUnit.MMT]: <FormattedMessage id="transportOrder.shipment.cargo.length.unit.mmt"/>,
        [LengthMeasurementUnit.CMT]: <FormattedMessage id="transportOrder.shipment.cargo.length.unit.cmt"/>,
        [LengthMeasurementUnit.MTR]: <FormattedMessage id="transportOrder.shipment.cargo.length.unit.mtr"/>
    }

    const intl = useIntl()
    const label = props.label ?? <FormattedMessage id="transportOrder.shipment.cargo.length"/>

    const value = props.length?.value ? intl.formatNumber(props.length.value) : undefined
    const unit = props.length && unitOptions[props.length.unit] || ""

    return <ReadOnlyDetailViewFormValue
        label={label}
        value={value}
        suffix={unit}
        placeholder={props.placeholder}
    />
}
