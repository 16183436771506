import { FormattedMessage } from "react-intl"
import { ANNOUNCEMENT_APPLICATION_BANNER_TEST_ID } from "./AnnouncementApplicationBanner"
import Dialog from "@rio-cloud/rio-uikit/Dialog"
import { ReactElement, useState } from "react"
import { useCookies } from "react-cookie"
import { ShipmentPriority, TransportOrder, TransportOrderType } from "../../model/transportorder/transportOrder"
import { Confirmation } from "../../model/confirmation/Confirmation"

const ANNOUNCEMENT_BANNER_WAS_SHOWN_COOKIE = "ANNOUNCEMENT_BANNER_WAS_SHOWN_COOKIE"

interface Props {
    onPopupNotShown: () => void
    renderChildren: (triggerPopup: () => void) => ReactElement
    transportOrder: TransportOrder | undefined
    confirmations: Array<Confirmation> | undefined
}

export const ConfirmationInformationPopup = (props: Props): ReactElement => {
    const { renderChildren, onPopupNotShown, transportOrder, confirmations } = props

    const [showPopup, setShowPopup] = useState(false)
    const [cookies, setCookie] = useCookies([ANNOUNCEMENT_BANNER_WAS_SHOWN_COOKIE])

    const popupAlreadyShown =
        cookies.ANNOUNCEMENT_BANNER_WAS_SHOWN_COOKIE === true || cookies.ANNOUNCEMENT_BANNER_WAS_SHOWN_COOKIE === "true"
    const popupApplicable = isPopupApplicable(transportOrder, confirmations)

    const onClickCloseButton = (): void => {
        const now = new Date()
        const cookieExpires = new Date(now.setDate(now.getDate() + 14))

        setCookie(ANNOUNCEMENT_BANNER_WAS_SHOWN_COOKIE, true, { expires: cookieExpires })

        setShowPopup(false)
    }

    const triggerPopupFlow = (): void => {
        if (popupAlreadyShown || !popupApplicable) {
            onPopupNotShown()
        } else {
            setShowPopup(true)
        }
    }

    return <>
        <Dialog
            show={showPopup}
            body={<DialogContent />}
            showCloseButton={false}
            disableEsc={true}
            title={<FormattedMessage id="application.announcement.hint.title" />}
            footer={<button 
                onClick={onClickCloseButton} 
                className='btn btn-primary'>
                <FormattedMessage id={"application.close"} />
            </button>}
            bsSize={"lg"}
        />
        {renderChildren(triggerPopupFlow)}
    </>
}
const DialogContent = (): ReactElement => {
    return <div>
        <div className="white-space-pre-line padding-5" data-testid={ANNOUNCEMENT_APPLICATION_BANNER_TEST_ID}>
            <FormattedMessage
                id="application.announcement.banner.text" 
                values={{ b: (text) => <b>{text}</b> }}
            />
        </div>
    </div>
}
export const isPopupApplicable = 
  (transportOrder: TransportOrder | undefined, confirmations: Confirmation[] | undefined): boolean => {
      const hasMissingAccessCodeAtUnloading = !!confirmations && confirmations.some(it => !it.accessCodeAtUnloading)

      const shipments = transportOrder?._embedded?.shipments
      const isMaterials = transportOrder?.orderType === TransportOrderType.MATERIAL
      const isRegular = !!shipments && shipments.every(it => it.priority === ShipmentPriority.REGULAR)

      return isMaterials && isRegular && hasMissingAccessCodeAtUnloading
  }
