import { useMemo } from "react"
import { SortCriteria, transportOrderSlice } from "./transportorder.slice"
import { useAppDispatch } from "./store"

export const useSearchTransportOrders = () => {
    const dispatch = useAppDispatch()
    return useMemo(() => (searchPhrase: string | undefined) => dispatch(transportOrderSlice.actions.searchTransportOrderBundles(searchPhrase)), [dispatch])
}

export const useSortTransportOrders = () => {
    const dispatch = useAppDispatch()
    return useMemo(() => (sortCriteria: SortCriteria | undefined) => dispatch(transportOrderSlice.actions.sortTransportOrderBundles(sortCriteria)), [dispatch])
}
