import { TransportOrder } from "../model/transportorder/transportOrder"
import { State } from "./store"

export const getOverviewTransportOrders
    = (store: State): TransportOrder[] => store.transportOrder.overviewTransportOrders

export const getFailedFetchTransportOrderId
    = (store: State): string | undefined => store.transportOrder.failedFetchTransportOrderId

export const getTransportOrder = (state: State, id: string) => state.transportOrder.transportOrders.find(t => t.id === id)
