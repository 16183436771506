import { ColumnDetails } from "../OrderOverview"
import { SortBy } from "../../../redux/transportorder.slice"

export enum Column {
    WARNING_ICON = "WARNING_ICON",
    ORDER_DATE = "ORDER_DATE",
    EARLIEST_LOADING_TIMEWINDOW = "EARLIEST_LOADING_TIMEWINDOW",
    CUSTOMER = "CUSTOMER",
    PICKUP_WINDOW = "PICKUP_WINDOW",
    SHIPPER = "SHIPPER",
    RECEIVER = "RECEIVER",
    TRANSPORT_TYPE = "TRANSPORT_TYPE",
    WEIGHT = "WEIGHT",
    VOLUME = "VOLUME",
    QUANTITY = "QUANTITY",
    DANGEROUS_GOODS = "DANGEROUS_GOODS",
    ACTION_BUTTONS = "ACTION_BUTTONS",
    DROPDOWN = "DROPDOWN"
}

export const columns = [
    Column.WARNING_ICON,
    Column.ORDER_DATE,
    Column.EARLIEST_LOADING_TIMEWINDOW,
    Column.CUSTOMER,
    Column.PICKUP_WINDOW,
    Column.SHIPPER,
    Column.RECEIVER,
    Column.TRANSPORT_TYPE,
    Column.WEIGHT,
    Column.VOLUME,
    Column.QUANTITY,
    Column.DANGEROUS_GOODS,
    Column.ACTION_BUTTONS,
    Column.DROPDOWN
]

type ColumnToDetailsMap = { [K in keyof typeof Column]: ColumnDetails }

export const getSortByForColumnDetails = (columnDetails: ColumnDetails): SortBy | undefined => {
    return columnDetails.sortBy
}

export const columnDetailsByColumn: ColumnToDetailsMap = {
    [Column.WARNING_ICON]: {
        width: "2%",
        minWidth: "20px",
        label: "",
    },
    [Column.ORDER_DATE]: {
        width: "6%",
        minWidth: "60px",
        label: "transportOrder.orderDate",
        sortBy: SortBy.CREATED_AT,
    },
    [Column.EARLIEST_LOADING_TIMEWINDOW]: {
        width: "6%",
        minWidth: "60px",
        label: "transportOrder.earliestLoadingTimewindow",
        sortBy: SortBy.EARLIEST_LOADING_TIME_FROM,
    },
    [Column.CUSTOMER]: {
        label: "transportOrder.customer",
        sortBy: SortBy.CUSTOMER_NAME
    },
    [Column.PICKUP_WINDOW]: {
        label: "transportOrder.timeFrame",
    },
    [Column.SHIPPER]: {
        label: "transportOrder.shipper",
    },
    [Column.RECEIVER]: {
        label: "transportOrder.receiver",
    },
    [Column.TRANSPORT_TYPE]: {
        label: "transportOrder.transportType",
    },
    [Column.WEIGHT]: {
        width: "6%",
        minWidth: "60px",
        label: "transportOrder.totalWeight",
        alternateHead: <span className="rioglyph rioglyph-weight text-size-20"/>
    },
    [Column.VOLUME]: {
        width: "6%",
        minWidth: "60px",
        label: "transportOrder.totalVolume",
        alternateHead: <span className="rioglyph rioglyph-volume text-size-20"/>
    },
    [Column.QUANTITY]: {
        width: "6%",
        minWidth: "60px",
        label: "transportOrder.quantity",
        alternateHead: <span className="rioglyph rioglyph-parcel text-size-20"/>
    },
    [Column.DANGEROUS_GOODS]: {
        width: "6%",
        minWidth: "60px",
        label: "transportOrder.dangerous_goods",
        alternateHead: <span className="rioglyph rioglyph-dangerousgoods text-size-18"/>
    },
    [Column.ACTION_BUTTONS]: {
        width: "6%",
    },
    [Column.DROPDOWN]: {
        width: "3%",
        minWidth: "12px"
    }
}
