import { Confirmation } from "../confirmation/Confirmation"
import { LengthMeasurement, VolumeMeasurement, WeightMeasurement } from "./measurement"

export interface TransportOrder {
    id: string
    orderType: TransportOrderType
    created_at: string
    external_order_created_date?: string
    version?: number
    customer_data?: CustomerData
    buyer?: Buyer
    status?: TransportOrderStatus
    external_order_number?: string
    external_identification?: string
    external_identification_type?: string
    _embedded?: {
        shipments: Shipment[]
    }
}

export enum TransportOrderType {
    MATERIAL = "MATERIAL",
    EMPTIES = "EMPTIES",
}

export enum TransportOrderStatus {
    UNPLANNED = "UNPLANNED",
    CANCELLED = "CANCELLED",
}

export interface DangerousGoodsInformation {
    undg_code?: string | null
    description?: string | null
    exception_regulation?: string
}

export enum ShipmentStatus {
    UNPLANNED = "UNPLANNED",
    CANCELLED = "CANCELLED",
}

export const getNonCancelledShipments = (transportOrder: TransportOrder): Shipment[] =>
    (transportOrder._embedded?.shipments ?? []).filter(shipment => shipment.status !== ShipmentStatus.CANCELLED)

export interface Shipment {
    id: string
    transport_order_id: string
    version: number
    last_modified_date?: string
    freight: Freight
    processIndicator: "FTL" | "LTL" | "KEP"
    external_identification?: string
    external_identification_type?: string
    additional_information?: string
    loading_address: LoadingAddress
    unloading_address: LoadingAddress
    status?: ShipmentStatus
    confirmationDraft: Confirmation
    isConfirmed: boolean
    proposedVehicleType?: VehicleType
    requiresTwoDrivers?: boolean
    priority: ShipmentPriority
}

export enum ShipmentPriority {
    REGULAR = "REGULAR",
    URGENT = "URGENT",
}

export type VehicleType = "PASSENGER_CAR" | "VAN" | "LIGHT_TRUCK" | "MEDIUM_TRUCK"

export type FreightDiscriminator = "BULK_DRY" | "BULK_LIQUID" | "CARGO"

export interface Freight {
    type: FreightDiscriminator
    name?: string
    gross_weight?: WeightMeasurement
    gross_volume?: VolumeMeasurement
}

export type BulkFreightType = "BULK_DRY" | "BULK_LIQUID"

export interface BulkFreight extends Freight {
    type: BulkFreightType
    article_id?: string
    dangerous_goods_information?: DangerousGoodsInformation
}

export interface CargoFreight extends Freight {
    type: "CARGO"
    loading_meters?: LengthMeasurement
    handling_units: HandlingUnit[]
}

export type Packable = HandlingUnit | Article

export interface HandlingUnit {
    type: "HANDLING_UNIT"
    identifier?: string
    additional_information?: string
    quantity?: number
    packaging_material_id?: string
    dimension?: HandlingUnitDimension
    weight?: WeightMeasurement
    stacking_factor?: number
    content: Packable[]
    _frontendKey: string
}

export interface HandlingUnitDimension {
    length?: LengthMeasurement
    width?: LengthMeasurement
    height?: LengthMeasurement
}

export interface CountryOfOrigin {
    country_code?: string
    dutiable_goods: boolean
}

export interface Article {
    type: "ARTICLE"
    article_id?: string
    name?: string
    quantity?: number
    dangerous_goods_information?: DangerousGoodsInformation
    net_explosive_mass?: WeightMeasurement
    internal_place_of_destination?: string
    country_of_origin?: CountryOfOrigin
    _frontendKey: string
}

export interface CustomerData {
    name: string
    contact?: string
    address?: Address
    telephone?: string
    email?: string
}

export interface Buyer {
    name: string
    address?: Address
}

export interface Position {
    latitude: number
    longitude: number
}

export interface TimeWindow {
    time_from?: string
    time_to: string
}

export type LoadingAddressType = "LOAD" | "UNLOAD"

export interface LocationIdentification {
    place_of_discharge?: string
    place_of_loading?: string
}

export interface LoadingAddress {
    name: string
    address?: Address
    location_identification?: LocationIdentification
    position?: Position
    time_window?: TimeWindow
    contact?: Contact
    accessCode?: string
    duns?: string
    external_identifiers?: LoadingAddressExternalIdentifier[]
}

export interface Address {
    street: string
    house_number?: string
    addition?: string
    postal_code: string
    city: string
    country: string
}

export interface Contact {
    name?: string
    email?: string
    phone?: string
}

export interface LoadingAddressExternalIdentifier {
    type: string
    identifier: string
}
