import { FormattedMessage } from "react-intl"
import { CargoFreightPresenter } from "./CargoFreightPresenter"
import { BulkFreightPresenter } from "./BulkFreightPresenter"
import { WeightPresenter } from "../Form/WeightPresenter"
import { VolumePresenter } from "../Form/VolumePresenter"
import { BulkFreight, CargoFreight, Freight } from "../../model/transportorder/transportOrder"
import { ReadOnlyDetailViewFormValue } from "../Form/ReadOnlyDetailViewFormValue"
import { ReactNode } from "react"
import { SelectOption } from "@rio-cloud/rio-uikit"

const options: SelectOption[] = [
    { id: "CARGO", label: <FormattedMessage id="transportOrder.shipment.cargo.type.cargo"/> },
    { id: "BULK_DRY", label: <FormattedMessage id="transportOrder.shipment.cargo.type.bulk"/> },
    { id: "BULK_LIQUID", label: <FormattedMessage id="transportOrder.shipment.cargo.type.liquid"/> },
]

export interface FreightPresenterProps {
    freight: Freight
}

export const FreightPresenter = (props: FreightPresenterProps): ReactNode => {
    const freight = props.freight
    return (
        <>
            <div className="row">
                <ReadOnlyDetailViewFormValue
                    label={<FormattedMessage id="transportOrder.shipment.cargo.type"/>}
                    value={options.find(it => it.id === freight.type)?.label}
                    md={4}
                    print={25}
                />
                <ReadOnlyDetailViewFormValue
                    value={freight.name}
                    label={<FormattedMessage id="transportOrder.shipment.cargo.name"/>}
                    md={4}
                    print={25}
                />
                <div className="col-md-2 float-left-print width-25pct-print">
                    <WeightPresenter weight={freight.gross_weight}/>
                </div>
                <div className="col-md-2 float-left-print width-25pct-print">
                    <VolumePresenter volume={freight.gross_volume}/>
                </div>
            </div>
            <FreightPanel freight={props.freight}/>
        </>
    )
}

const FreightPanel = (props: { freight: Freight }): ReactNode => {
    if (props.freight.type === "CARGO") {
        return <CargoFreightPresenter freight={props.freight as CargoFreight}/>
    } else {
        return <BulkFreightPresenter freight={props.freight as BulkFreight}/>
    }
}
