export const uuid = (): string => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        // tslint:disable-next-line:no-bitwise
        // eslint-disable-next-line
        const r = Math.random() * 16 | 0;
        // tslint:disable-next-line:no-bitwise
        // eslint-disable-next-line
        const v = c === "x" ? r : (r & 0x3 | 0x8);
        return v.toString(16)
    })
}
