import "./polyfills"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Provider } from "react-redux"
import "./configuration/sentry/sentry"
import { ErrorBoundary } from "./components/ErrorBoundary"
import { store } from "./redux/store"
import { createRoot } from "react-dom/client"
import { FeatureToggleProvider } from "./configuration/featuretoggle/FeatureToggleProvider"
import { Application } from "./components/Application/Application"
import { config } from "./configuration/app/app-config"
import { handleLoginRedirect } from "./configuration/login/redirect"
import { enableServiceWorkerMock } from "./msw-setup"
import { signInAndRender } from "./configuration/login/login"

const renderApplication = () => {
    createRoot(document.getElementById("root") as HTMLElement).render(
        <ErrorBoundary>
            <Provider store={store}>
                <FeatureToggleProvider>
                    <BrowserRouter basename={"/"}>
                        <Routes>
                            <Route path={"/error"} element={<div>Error</div>}/>
                            <Route path={"/*"} element={<Application />}/>
                        </Routes>
                    </BrowserRouter>
                </FeatureToggleProvider>
            </Provider>
        </ErrorBoundary>
    )
}

if (window.location.href.startsWith(config.login.redirectUri)) {
    handleLoginRedirect()
} else {
    await enableServiceWorkerMock()
    await signInAndRender(renderApplication)
}
