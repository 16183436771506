/* eslint-disable camelcase, no-console */
import {
    InMemoryWebStorage,
    User,
    UserManager,
    type UserManagerSettings,
    type UserProfile as Profile,
    WebStorageStateStore,
} from "oidc-client-ts"
import { loginSlice, UserProfile } from "./login.slice"
import { config } from "../app/app-config"
import { AccessToken, tokenSlice } from "../token/token.slice"
import { accessToken } from "../token/accessToken"
import { store } from "../../redux/store"
import { EVENT_USER_LANGUAGE_CHANGED, EVENT_USER_PROFILE_CHANGED } from "@rio-cloud/rio-user-menu-component"
import { reportErrorToSentry } from "../sentry/sentry"
import * as storage from "./storage"

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000

interface OAuthConfig {
    onSessionExpired: Function
    onSessionRenewed: Function
}

const retrySigninSilent = (oauthConfig: OAuthConfig, userManager: UserManager) => {
    userManager.signinSilent().catch((error: Error) => {
        if (error.message === "login_required") {
            oauthConfig.onSessionExpired()
        } else {
            setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS)
        }
    })
}

type SessionRenewedResult = {
    accessToken: AccessToken
    idToken: Profile
    profile: UserProfile
    locale: string
};

const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
    accessToken: result.access_token,
    idToken: result.profile,
    locale: result.profile?.locale ?? "en-GB",
    profile: result.profile,
})

const oauthScope: string[] = [
    "openid",
    "profile",
    "email",
    "transport-order.read",
    "transport-order.write",
    "transport-order.confirm",
    "logistics.freight-forwarder",
    "dispatch-agreements.read",
    "dispatch-agreements.write",
    "dispatch-activations.read",
    "asset-administration.read",
    "asn-matching.read",
    "asn-matching.write",
    "asn-matching.write",
    "transport-order-notification-settings.read",
    "transport-order-notification-settings.write",
]

const createUserManager = () => {
    const redirectUri = config.login.redirectUri

    const settings: UserManagerSettings = {
        authority: `${config.login.authority}`,
        client_id: `${config.login.clientId}`,
        loadUserInfo: false,
        redirect_uri: redirectUri,
        response_type: "code",
        scope: oauthScope.join(" "),
        silent_redirect_uri: redirectUri,
        includeIdTokenInSilentRenew: false,
        automaticSilentRenew: true,
        monitorSession: true,
        staleStateAgeInSeconds: 600,
        userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
        filterProtocolClaims: false,
    }

    return new UserManager(settings)
}

const configureUserManager = (oauthConfig: OAuthConfig, userManager: UserManager) => {
    userManager.events.addUserLoaded((user) => {
        oauthConfig.onSessionRenewed(adaptPublishedInfo(user))
    })

    userManager.events.addUserUnloaded(() => {
        oauthConfig.onSessionExpired()
    })

    userManager.events.addSilentRenewError(() => {
        retrySigninSilent(oauthConfig, userManager)
    })

    userManager.events.addUserSignedOut(() => {
        oauthConfig.onSessionExpired()
    })

    return userManager
}

const configureMockUserManager = (oauthConfig: OAuthConfig, accessToken?: string): UserManager => {
    const signinSilent = () => {
        const userSettings = {
            access_token: accessToken || "valid-mocked-oauth-bogus-token",
            profile: {
                iss: "Issuer Identifier",
                aud: "Audience(s): client_id",
                exp: 10,
                iat: 5,
                account: "mockaccount",
                azp: "test-client",
                email: "test@example.com",
                family_name: "Client",
                given_name: "Test",
                name: "Test Client",
                sub: "prod-rio-users:mock-user",
                locale: "en-GB",
                tenant: "rio-eu.test",
            },
            id_token: "id_token",
            session_state: "session_state",
            refresh_token: "refresh_token",
            token_type: "token_type",
            scope: "scope",
            expires_at: 100000,
            state: "state",
        }

        const user = new User(userSettings)
        oauthConfig.onSessionRenewed(adaptPublishedInfo(user))
        return Promise.resolve(user)
    }

    const clearStaleState = () => Promise.resolve()

    return { signinSilent, clearStaleState } as UserManager
}

const oauthConfig: OAuthConfig = {
    onSessionExpired: () => {
        accessToken.discardAccessToken()
        store.dispatch(loginSlice.actions.userSessionExpired())
    },
    onSessionRenewed: (result: SessionRenewedResult) => {
        accessToken.saveAccessToken(result.accessToken)
        store.dispatch(tokenSlice.actions.accessTokenStored(result.accessToken))
        store.dispatch(tokenSlice.actions.idTokenStored(result.idToken))
        store.dispatch(loginSlice.actions.userProfileObtained(result.profile))
        store.dispatch(loginSlice.actions.userSessionRenewed())
    },
}

const testAccessToken = sessionStorage?.getItem("test_access_token") ?? undefined
const useMockUserManager = config.skipLogin || testAccessToken !== undefined

export const userManager: UserManager = useMockUserManager
    ? configureMockUserManager(oauthConfig, testAccessToken)
    : configureUserManager(oauthConfig, createUserManager())

const saveCurrentRoute = () => {
    const initialRoute = [window.location.pathname, window.location.search].join("")
    storage.routeStorage.saveRoute(initialRoute)
}

const trace = config.isDev ? console.log : () => { /* Noop*/ }

const attemptInitialSignIn = async (userManager: UserManager) => {
    const isFreshRedirect = window.location.href.includes("redirected")

    try {
        const user = await userManager.signinSilent()
        const initialRoute = storage.routeStorage.getRoute()

        trace("initialRoute lookup", initialRoute)

        if (initialRoute && isFreshRedirect) {
            trace(`Go to location "/${initialRoute}"`)
            window.location.replace(initialRoute)
        }

        storage.routeStorage.discardRoute()
        return user
    } catch (error) {
        trace("oidc.signinSilent failed, trying page redirect...", error)

        if (config.login.preventRedirect || sessionStorage?.getItem("test_access_token")) {
            console.warn("[feature/login] redirect prevented due to config. Error was", error)
        } else if (isFreshRedirect) {
            trace("oidc.signinSilent.error", "redirect prevented due to suspicious signin error", error)
            storage.routeStorage.discardRoute()
            reportErrorToSentry(error)
        } else {
            saveCurrentRoute()
            userManager.signinRedirect()
        }

        trace("auth problem?", error)
        throw new Error("Need to sign in")
    }
}

export const signInAndRender = async (renderApp: Function) => {

    const signinSilent = userManager.signinSilent.bind(userManager)
    document.addEventListener(EVENT_USER_LANGUAGE_CHANGED, () => signinSilent())
    document.addEventListener(EVENT_USER_PROFILE_CHANGED, () => signinSilent())

    try {
        await userManager.clearStaleState()
        await attemptInitialSignIn(userManager)
        renderApp()
    } catch (error) {
        trace("could not sign in silently", error)
    }
}
