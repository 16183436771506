import { PropsWithChildren, ReactElement, useState } from "react"
import { ConfirmationInformationPopup } from "../../Application/ConfirmationInformationPopup"
import { TransportOrder } from "../../../model/transportorder/transportOrder"
import { Confirmation } from "../../../model/confirmation/Confirmation"

type Props = PropsWithChildren<{
  onClick: () => Promise<void>
  btnClass?: string
  disabled?: boolean
  transportOrder?: TransportOrder
  confirmations?: Confirmation[]
}>

export const ConfirmationActionButton = (props: Props): ReactElement => {
    const [state, setState] = useState({ inProgress: false, success: false })
    const { onClick, btnClass, disabled, transportOrder, confirmations, children } = props
    const disabledClass = state.inProgress || disabled ? "disabled" : ""
    const classes = `btn ${btnClass ? " " + btnClass : ""} ${state.inProgress ? " btn-loading-overlay " : ""}${disabledClass}`
    const handleClick = (): Promise<void> => {
        setState({ ...state, inProgress: true })

        return onClick()
            .then(() => {
                setState({ success: true, inProgress: false })
                return Promise.resolve()
            })
            .catch(() => {
                setState({ success: false, inProgress: false })
                return Promise.resolve()
            })
    }

    return (
        <ConfirmationInformationPopup 
            onPopupNotShown={handleClick}
            renderChildren={(triggerPopup): ReactElement =>
                <div className={classes} onClick={(): void => {
                    !state.inProgress && !state.success && triggerPopup ()
                }}>
                    {children}
                </div>}
            transportOrder={transportOrder}
            confirmations={confirmations}
        />
    )
}
