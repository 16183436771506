import { Article, HandlingUnit, Packable } from "../../../model/transportorder/transportOrder"

export const getArticlesFromHandlingUnit = (handlingUnit: HandlingUnit | undefined): Article[] => {
    if(!handlingUnit) {
        return []
    }
    return getArticlesFromPackable(handlingUnit)
}

const getArticlesFromPackable = (packable: Packable): Article[] => {
    switch (packable.type) {
    case "ARTICLE":
        return [packable]
    case "HANDLING_UNIT":
        return packable.content.flatMap((packable) => getArticlesFromPackable(packable))
    }
}
