import { createContext } from "react"
import { uuid } from "../../../helper/uuid"

export interface ValidatationState {
    readonly forceShowValidationMessages: boolean
}

type ValidationStateChangeCallback = (state: ValidatationState) => void

export class ValidationContextState {
    private validationState: ValidatationState = {
        forceShowValidationMessages: false,
    }

    private callbacks = new Map<string, ValidationStateChangeCallback>()
    private validationErrors = new Set<string>()

    registerFormComponent = (callback: ValidationStateChangeCallback): string => {
        const componentId = uuid()
        this.callbacks.set(componentId, callback)
        return componentId
    }

    deregisterFormComponent = (componentId: string): void => {
        this.resolveError(componentId)
        this.callbacks.delete(componentId)
    }

    reportError = (componentId: string): void => {
        this.validationErrors.add(componentId)
    }

    resolveError = (componentId: string): void => {
        this.validationErrors.delete(componentId)
    }

    showAllValidationMessages = (): void => {
        this.validationState = {
            forceShowValidationMessages: true
        }
        this.callbacks.forEach(callback => callback(this.validationState))
    }

    hasAnyError = (): boolean => {
        return this.validationErrors.size > 0
    }

}

export const GlobalValidationContextState = new ValidationContextState()
export const ValidationContext = createContext<ValidationContextState>(GlobalValidationContextState)

export const ValidationContextProvider = ValidationContext.Provider
