
export interface InterfaceRouteDefinition {
  path: string
}

interface InterfaceRoutes {
  [name: string]: InterfaceRouteDefinition
}

const routingConfig: InterfaceRoutes = {
    landingPage: {
        path: "/transportorders",
    },
    transportOrderOverview: {
        path: "/transportorders",
    },
    transportOrderPresenter: {
        path: "/transportorder/:id",
    },
    closingDays: {
        path: "/closing-days"
    },
    notificationSettings: {
        path: "/notifications",
    }
}

export { routingConfig }