import { PropertyOfType } from "./index"
import { ReactElement, ReactNode } from "react"

export type ReadOnlyDetailViewFormValuePropsBase = {
    md?: number
    sm?: number
    xs?: number
    print?: number
    label?: ReactNode
    placeholder?: ReactNode
    suffix?: ReactNode
}

export type ReadOnlyDetailViewFormValueWithValueProps<T> = ReadOnlyDetailViewFormValuePropsBase & {
    data?: undefined
    property?: undefined
    formatProperty?: (value: T) => ReactNode
    value?: T
}

export type ReadOnlyDetailViewFormValueWithProperty<T, U> = ReadOnlyDetailViewFormValuePropsBase & {
    data: T
    property: PropertyOfType<T, U>
    formatProperty: (value: U) => ReactNode
    value?: undefined
}

export type ReadOnlyDetailViewFormValueProps<T, U> = ReadOnlyDetailViewFormValueWithProperty<T, U> | ReadOnlyDetailViewFormValueWithValueProps<U>

export const ReadOnlyDetailViewFormValue = <T, U>(props: ReadOnlyDetailViewFormValueProps<T, U>): ReactElement => {
    const { label, data, md, print, property, sm, xs, formatProperty } = props
    const rawPropValue = data !== undefined && data !== null && property
        ? data[property] as U
        : undefined

    const format = (u?: U, formatFunction?: (value: U) => ReactNode): ReactNode =>
        formatFunction && u !== undefined && u !== null ? formatFunction(u) : u as ReactNode
    const displayValue = format(rawPropValue ?? props.value, formatProperty)

    const placeholder = props.placeholder ?? "---"
    const responsiveColClasses = `${colClass("xs", xs)} ${colClass("sm", sm)} ${colClass("md", md)}`
    const printClass = print && printWidthClass(print) || ""

    if (!label) {
        return <div>{displayValue ?? placeholder}{displayValue && props.suffix}</div>
    }

    return (
        <div className={`${responsiveColClasses} ${printClass}`}>
            <label>{label}</label>
            <div>{displayValue ?? placeholder}{displayValue && props.suffix}</div>
        </div>
    )
}

const colClass = (name: string, width?: number): string => {
    return width ? `col-${name}-${width}` : ""
}

const printWidthClass = (percentage?: number): string => {
    return percentage ? `width-${percentage}pct-print float-left-print` : ""
}
