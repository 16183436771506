import { ReactElement, useContext, useMemo, useState } from "react"
import Checkbox from "@rio-cloud/rio-uikit/Checkbox"
import Dialog from "@rio-cloud/rio-uikit/Dialog"
import {
    getNonCancelledShipments,
    TimeWindow,
    TransportOrder,
    VehicleType
} from "../../../model/transportorder/transportOrder"
import { FormattedMessage } from "react-intl"
import {
    GlobalValidationContextState,
    ValidationContext,
    ValidationContextProvider
} from "../../Form/validation/VaildationContext"
import { AssetSelect } from "../../Asset/AssetSelect"
import {
    applyConfirmationToAllShipments,
    Confirmation,
    ShipmentConfirmation
} from "../../../model/confirmation/Confirmation"
import { TimeWindowBookingIdInput } from "./TimeWindowBookingIdInput"
import { TimeWindowInput } from "../timewindow/TimeWindowInput"
import { ValidFromAndTimeWindowCompleteValidator, ValidToAndTimeWindowCompleteValidator } from "./validator"
import { getTimezoneOfLoading } from "../../../timezones/timezones"
import {
    doesTransportOrderSupportDriverPhoneNumber,
    doesTransportOrderSupportVehicleType,
} from "../../../model/transportorder/transportOrderEditing"
import { DriverPhoneNumberInput } from "./DriverPhoneNumberInput"
import { VehicleTypeInput } from "./VehicleTypeInput"
import { DetailViewJumpPoint } from "./DetailViewJumpPoint"
import { InfoBox } from "./InfoBox"
import { BorderoNumberInput } from "./BorderoNumberInput"
import { ConfirmationActionButton } from "./ConfirmationActionButton"

interface Props {
    show: boolean
    transportOrder: TransportOrder
    onCancel: () => void
    onConfirm: (shipmentConfirmations: ShipmentConfirmation[]) => Promise<void>
}

interface InitialState {
    confirmation: Confirmation
    sameAccessCodes: boolean
    numberOfShipments: number
    timezone?: string
}

const getInitialState = (transportOrder: TransportOrder): InitialState => {
    const nonCancelledShipments = getNonCancelledShipments(transportOrder)
    const firstConfirmedShipment = nonCancelledShipments
        .find((it) => it.isConfirmed)

    const providedAccessCodes = nonCancelledShipments
        .map(it => it.confirmationDraft.accessCodeAtLoading)
        .filter((accessCode): accessCode is string => accessCode !== undefined && accessCode !== null)
    const hasSameAccessCodes = providedAccessCodes.every(it => it === providedAccessCodes[0])

    if (firstConfirmedShipment !== undefined) {
        return {
            confirmation: firstConfirmedShipment.confirmationDraft,
            sameAccessCodes: hasSameAccessCodes,
            numberOfShipments: nonCancelledShipments.length,
            timezone: getTimezoneOfLoading(firstConfirmedShipment)
        }
    }

    const firstShipmentWithTimeWindow = nonCancelledShipments.find(it => it.loading_address.time_window !== undefined)
    return {
        confirmation: nonCancelledShipments.length > 0 ? nonCancelledShipments[0].confirmationDraft : {},
        sameAccessCodes: hasSameAccessCodes,
        numberOfShipments: nonCancelledShipments.length,
        timezone: getTimezoneOfLoading(firstShipmentWithTimeWindow)
    }
}

export const EmptiesOrderConfirmationDialog = (props: Props): ReactElement => {

    const ctx = useContext(ValidationContext)
    const transportOrder = props.transportOrder

    const initialState = useMemo(() => getInitialState(transportOrder), [transportOrder])
    const [confirmation, setConfirmation] = useState<Confirmation>(initialState.confirmation)
    const [appliesToAllShipments, setAppliesToAllShipments] = useState<boolean>(initialState.sameAccessCodes)

    const title = <FormattedMessage id={"transportOrder.confirmation.dialog.title"}/>

    const toggleCheckBox = (): void => {
        setAppliesToAllShipments(!appliesToAllShipments)
    }

    const getConfirmationForAllShipments = (): ReactElement => {
        return <>
            <AssetSelect
                label={<FormattedMessage id="transportOrder.confirmation.input.label.asset"/>}
                assetId={confirmation.asset?.id}
                onAssetSelected={(asset): void => {
                    const baseConfirmation: Confirmation = confirmation ? {
                        ...confirmation,
                    } : {}
                    setConfirmation({
                        ...baseConfirmation,
                        asset: asset ? {
                            id: asset.id,
                            licensePlate: asset.licensePlate,
                            countryCode: asset.licensePlateCountryCode
                        } : undefined
                    })
                }}
                className={"margin-bottom-20"}
            />
            <TimeWindowInput
                value={confirmation.loadingTimeWindow}
                timezone={initialState.timezone}
                labelFrom={<FormattedMessage id="transportOrder.confirmation.input.label.loadingTimewindow.from"/>}
                labelTo={<FormattedMessage id="transportOrder.confirmation.input.label.loadingTimewindow.to"/>}
                validateFrom={ValidFromAndTimeWindowCompleteValidator}
                validateTo={ValidToAndTimeWindowCompleteValidator}
                onChange={(value: TimeWindow | undefined): void => {
                    const baseConfirmation: Confirmation = confirmation || {}
                    setConfirmation({
                        ...baseConfirmation,
                        loadingTimeWindow: value,
                    })
                }}
                dateTimePropsTo={{
                    className: "margin-bottom-20",
                    datePickerProps: {
                        className: "margin-bottom-0",
                    }
                }}
                dateTimePropsFrom={{
                    className: "margin-bottom-20",
                    datePickerProps: {
                        className: "margin-bottom-0",
                    }
                }}
            />
            <TimeWindowBookingIdInput
                confirmation={confirmation}
                property={"accessCodeAtLoading"}
                onChange={(value: Confirmation): void => setConfirmation({
                    ...value,
                    accessCodeAtLoading: value.accessCodeAtLoading || undefined
                })}
                className={"margin-bottom-20"}
            />
            <DriverPhoneNumberInput
                show={doesTransportOrderSupportDriverPhoneNumber(transportOrder)}
                value={confirmation.phoneNumber}
                onChange={(value: string): void => setConfirmation({
                    ...confirmation,
                    phoneNumber: value || undefined,
                })}
                className={"margin-bottom-20"}
            />
            <VehicleTypeInput
                show={doesTransportOrderSupportVehicleType(transportOrder)}
                value={confirmation.vehicleType}
                onChange={(value: VehicleType | undefined): void => setConfirmation({
                    ...confirmation,
                    vehicleType: value,
                })}
                className={"margin-bottom-20"}
            />
            <BorderoNumberInput
                show={true}
                confirmation={confirmation}
                onChange={(value: Confirmation): void => {
                    setConfirmation({
                        ...confirmation,
                        loadId: value.loadId || undefined,
                    })}
                }
                className={"margin-bottom-20"}
            />
            <div className="margin-top-20">
                <InfoBox messageId={"transportOrder.confirmation.dialog.body.note"}/>
            </div>
        </>
    }

    const multipleAccessCodeI18nKey = "transportOrder.confirmation.dialog.multipleAccessCode.text"

    const body =
        <ValidationContextProvider value={GlobalValidationContextState}>
            <FormattedMessage
                id={"transportOrder.confirmation.dialog.body.text"}
                values={{
                    type: <strong><FormattedMessage id={"transportOrder.type.empties.term"}/></strong>
                }}/>
            <br/>
            {initialState.numberOfShipments > 1 ?
                <div className="display-flex justify-content-start">
                    <Checkbox
                        className="padding-top-10 padding-bottom-10"
                        onClick={toggleCheckBox}
                        checked={appliesToAllShipments}
                        disabled={!initialState.sameAccessCodes}
                    >
                        <FormattedMessage id={"transportOrder.confirmation.appliesToAllShipments.label"}/>
                    </Checkbox>
                </div>
                : null}
            {appliesToAllShipments
                ? getConfirmationForAllShipments()
                : <DetailViewJumpPoint
                    transportOrderId={transportOrder.id}
                    additionalInfoBoxMessageId={initialState.sameAccessCodes ? undefined : multipleAccessCodeI18nKey}
                />}
        </ValidationContextProvider>

    const footer =
        <ConfirmationActionButton
            btnClass="btn btn-primary"
            onClick={(): Promise<void> => {
                if (!ctx.hasAnyError()) {
                    return props.onConfirm(applyConfirmationToAllShipments(confirmation, transportOrder))
                } else {
                    ctx.showAllValidationMessages()
                    return Promise.resolve()
                }
            }}
            disabled={!appliesToAllShipments}
            transportOrder={transportOrder}
        >
            <div data-testid={"dialog-accept-transportorder-button"}>
                <FormattedMessage id={"transportOrder.accept"}/>
            </div>
        </ConfirmationActionButton>

    return <Dialog
        show={props.show}
        disableEsc={false}
        body={body}
        title={title}
        showCloseButton={true}
        footer={footer}
        bsSize={Dialog.SIZE_SM}
        onClose={props.onCancel}
    />
}
