export interface Measurement<UNIT> {
    value?: number
    unit: UNIT
}

export enum WeightMeasurementUnit {
    KGM = "KGM",
    TNE = "TNE",
}

export enum VolumeMeasurementUnit {
    DMQ = "DMQ",
    MTQ = "MTQ",
    LTR = "LTR",
}

export enum LengthMeasurementUnit {
    MMT = "MMT",
    CMT = "CMT",
    MTR = "MTR",
}

export type WeightMeasurement = Measurement<WeightMeasurementUnit>
export type VolumeMeasurement = Measurement<VolumeMeasurementUnit>
export type LengthMeasurement = Measurement<LengthMeasurementUnit>

export const normalizeToKgm = (weight: WeightMeasurement | undefined): WeightMeasurement | undefined => {
    if (weight === undefined) {
        return weight
    }
    if (weight.value === undefined) {
        return { ...weight, unit: WeightMeasurementUnit.KGM }
    }

    switch (weight.unit) {
    case WeightMeasurementUnit.KGM:
        return weight
    case WeightMeasurementUnit.TNE:
        return { ...weight, value: weight.value * 1000, unit: WeightMeasurementUnit.KGM }
    }
}

export const normalizeToMmt = (length: LengthMeasurement | undefined): LengthMeasurement | undefined => {
    if (length === undefined) {
        return undefined
    }
    if (length.value === undefined) {
        return { ...length, unit: LengthMeasurementUnit.MMT }
    }
    switch (length.unit) {
    case LengthMeasurementUnit.MMT:
        return length
    case LengthMeasurementUnit.CMT:
        return {
            value: length.value * 10.0,
            unit: LengthMeasurementUnit.MMT
        }
    case LengthMeasurementUnit.MTR:
        return {
            value: length.value * 1000.0,
            unit: LengthMeasurementUnit.MMT
        }
    }
}

export const normalizeToMtr = (length: LengthMeasurement | undefined): LengthMeasurement | undefined => {
    if (length === undefined) {
        return undefined
    }
    if (length.value === undefined) {
        return { ...length, unit: LengthMeasurementUnit.MTR }
    }
    switch (length.unit) {
    case LengthMeasurementUnit.MMT:
        return {
            value: length.value / 1000,
            unit: LengthMeasurementUnit.MTR
        }
    case LengthMeasurementUnit.CMT:
        return {
            value: length.value / 100,
            unit: LengthMeasurementUnit.MTR
        }
    case LengthMeasurementUnit.MTR:
        return length
    }
}

export const normalizeToMTQ = (volume: VolumeMeasurement | undefined): VolumeMeasurement | undefined => {
    if (volume === undefined) {
        return volume
    }
    if (volume.value === undefined) {
        return { ...volume, unit: VolumeMeasurementUnit.MTQ }
    }
    switch (volume.unit) {
    case VolumeMeasurementUnit.DMQ:
    case VolumeMeasurementUnit.LTR:
        return { ...volume, value: volume.value * 0.001, unit: VolumeMeasurementUnit.MTQ }
    case VolumeMeasurementUnit.MTQ:
        return volume
    }
}
