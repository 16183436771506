import { ReactNode } from "react"
import { FormattedMessage } from "react-intl"

export const InfoBox = (props: { messageId: string }): ReactNode => {

    const Strong = (chunks: ReactNode): ReactNode => (<strong>{chunks}</strong>)

    return <div className="alert alert-info">
        <div className={"display-flex align-items-center"}>
            <span className={"rioglyph rioglyph-info-sign text-size-20 margin-right-15"}/>
            <FormattedMessage
                id={props.messageId}
                values={{ strong: (chunks: ReactNode): ReactNode => Strong(chunks) }} tagName={"div"}/>
        </div>
    </div>
}