import { Fragment, ReactElement } from "react"

import { AddressPresenter } from "./AddressPresenter"
import { createEmptyAddress } from "../../helper/transportOrderHelper"
import { FormattedMessage } from "react-intl"
import { LoadingAddress, LoadingAddressType } from "../../model/transportorder/transportOrder"
import { ContactPresenter } from "./ContactPresenter"
import { retrieveAppropriateLocationDetails } from "../../helper/loadingAddressHelper"
import { ReadOnlyDetailViewFormValue } from "../Form/ReadOnlyDetailViewFormValue"
import { TimeWindowPresenter } from "./timewindow/TimeWindowPresenter"

export interface LoadingAddressPresenterProps {
    loadingAddress: LoadingAddress
    addressType: LoadingAddressType
}

export const LoadingAddressPresenter = (props: LoadingAddressPresenterProps): ReactElement => {
    const { loadingAddress, addressType } = props
    const address = loadingAddress.address ? loadingAddress.address : createEmptyAddress()
    const timeWindowFromLabel = `transportOrder.shipment.timewindow.from${addressType === "UNLOAD" ? ".estimated" : ""}`
    const timeWindowToLabel = `transportOrder.shipment.timewindow.to${addressType === "UNLOAD" ? ".estimated" : ""}`
    return (
        <Fragment>
            <div className="row form-group">
                <TimeWindowPresenter
                    value={loadingAddress.time_window}
                    labelFrom={<FormattedMessage id={timeWindowFromLabel}/>}
                    labelTo={<FormattedMessage id={timeWindowToLabel}/>}
                />
            </div>
            <div className="row">
                <div data-testid={"AddressInput_CompanyName"}>
                    <ReadOnlyDetailViewFormValue
                        value={loadingAddress.name}
                        md={6}
                        label={<FormattedMessage id="transportOrder.shipment.company.name"/>}
                    />
                </div>
                <ReadOnlyDetailViewFormValue
                    md={6}
                    label={<FormattedMessage id={props.addressType == "LOAD"
                        ? "transportOrder.shipment.address.addition.loading"
                        : "transportOrder.shipment.address.addition.unloading"
                    }/>}
                    value={retrieveAppropriateLocationDetails(loadingAddress.location_identification, address)}
                />
            </div>
            <AddressPresenter address={address} />
            <hr className="margin-top-20 margin-bottom-20 border-width-1 border-color-light margin-top-10-print"/>
            <div className="row">
                <div className={"col-md-6"}>
                    <ContactPresenter contact={loadingAddress.contact} />
                </div>
                <ReadOnlyDetailViewFormValue
                    value={loadingAddress.accessCode}
                    md={6}
                    label={<FormattedMessage id="transportOrder.shipment.address.accessCode"/>}
                />
            </div>
        </Fragment>
    )
}
