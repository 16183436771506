import { ReactNode } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { WeightMeasurement, WeightMeasurementUnit } from "../../model/transportorder/measurement"
import { ReadOnlyDetailViewFormValue } from "./ReadOnlyDetailViewFormValue"

export interface IWeightMeasurementInputProps {
    weight?: WeightMeasurement
    label?: ReactNode
    placeholder?: string
}

export const WeightPresenter = (props: IWeightMeasurementInputProps): ReactNode => {
    const unitOptions = {
        [WeightMeasurementUnit.KGM]: <FormattedMessage id="transportOrder.shipment.cargo.weight.unit.kgm"/>,
        [WeightMeasurementUnit.TNE]: <FormattedMessage id="transportOrder.shipment.cargo.weight.unit.tne"/>
    }

    const intl = useIntl()
    const label = props.label ?? <FormattedMessage id="transportOrder.shipment.cargo.weight" />

    const value = props.weight?.value ? intl.formatNumber(props.weight.value) : undefined
    const unit = props.weight && unitOptions[props.weight.unit] || ""

    return <ReadOnlyDetailViewFormValue
        label={label}
        value={value}
        suffix={unit}
        placeholder={props.placeholder}
    />
}
