import Notification from "@rio-cloud/rio-uikit/Notification"
import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger"
import Tooltip from "@rio-cloud/rio-uikit/Tooltip"
import { FormattedMessage } from "react-intl"
import { loadMainRunData } from "../../actions/mainRunData"
import { MainRunDataCsvWriter } from "../../helper/mainRunDataCsvTransformer"
import { saveAs } from "file-saver"
import moment from "moment-timezone"
import { Asset } from "../../model/asset/asset"
import { ICrossDock } from "../../model/crossdock/crossDock"
import { MenuItemProps } from "@rio-cloud/rio-uikit/MenuItem"

export const mainRunDownloadMenuItem = (assets: Asset[], crossDocks: ICrossDock[], setIsLoading: (isLoading: boolean) => void): MenuItemProps => {
    const csvWriter = new MainRunDataCsvWriter(() => "")

    const onSelect = (): void => {
        setIsLoading(true)
        const fileName = `mainRuns_${moment().format("YYYY-MM-DD_HHmm")}.csv`
        loadMainRunData()
            .then(response => {
                const csv = csvWriter.unparseMainRunDataCsv(response, assets, crossDocks)
                saveAs(new Blob([csv], { type: "text/csv" }), fileName)
            })
            .catch(() => {
                Notification.error(
                    <div data-testid={"notification-confirmation-error"}>
                        <FormattedMessage id={"mainRun.download.error"}/>
                    </div>
                )
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return {
        onSelect,
        value:
          <OverlayTrigger
              placement={"bottom"}
              overlay={
                  <Tooltip id="tooltip" allowOnTouch width={300} textAlignment={"left"}>
                      <FormattedMessage id="mainRun.download.button.popover.text"/>
                  </Tooltip>
              }
          >
              <div>
                  <span className="rioglyph rioglyph-cloud-download margin-right-5"/>
                  <FormattedMessage id={"common.download"}/>
              </div>
          </OverlayTrigger>
    }
}
