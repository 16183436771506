import * as React from "react"
import { ReactElement, useEffect, useState } from "react"
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout"
import Notification from "@rio-cloud/rio-uikit/Notification"
import Spinner from "@rio-cloud/rio-uikit/Spinner"
import { FormattedMessage } from "react-intl"
import { routingConfig } from "../Application/RoutingConfig"
import { TransportOrder, TransportOrderType } from "../../model/transportorder/transportOrder"
import { MaterialOrderConfirmationDialog } from "./confirmation/MaterialOrderConfirmationDialog"
import { EmptiesOrderConfirmationDialog } from "./confirmation/EmptiesOrderConfirmationDialog"
import { ShipmentConfirmation } from "../../model/confirmation/Confirmation"
import { TableToolBar } from "./TransportOrderTable/TableToolBar"
import { AnnouncementApplicationBanner } from "../Application/AnnouncementApplicationBanner"
import { useSearchTransportOrders, useSortTransportOrders } from "../../redux/transportorder.hooks"
import {
    fetchTransportOrderBundles,
    fetchTransportOrderBundlesAndEnablePolling
} from "../../actions/fetchTransportOrderBundles"
import { confirmShipments } from "../../actions/freightForwarderResponses"
import { useLocation, useNavigate } from "react-router-dom"
import { TransportOrderTable } from "./TransportOrderTable/TransportOrderTable"
import {
    getOverviewTransportOrders
} from "../../redux/transportorder.selector"
import moment from "moment"
import { TransportOrderBundleFetchParams } from "../../client/transportorder-hub/TransportOrderHubClient"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
    extractTableParamsFromUrl,
    mapSortByToQueryString,
    SortBy,
    SortCriteria, SortDirection
} from "../../redux/transportorder.slice"
import { getTransportOrderLoadingSpinnerState } from "../../redux/ui.selector"
import queryString from "query-string"

export interface ColumnDetails {
    label?: string
    width?: number | string
    minWidth?: number | string
    defaultWidth?: number
    maxWidth?: number
    sortBy?: SortBy
    alternateHead?: React.ReactElement
    additionalStyles?: string
}

const mapQueryStringToDate = (str: string | undefined, defaultValue: Date): Date => {
    if (str === undefined || str === "") {
        return defaultValue
    }
    const date = new Date(str)
    return isFinite(date.getTime()) ? date: defaultValue
}

const useUpdateHistoryWithTableParams = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const tableParams = extractTableParamsFromUrl(location.search)

    interface TableQueryParams {
        searchPhrase: string | undefined
        sortBy: string | undefined
        sortDir: SortDirection
        orderDateTo: string | undefined
        orderDateFrom: string | undefined
    }

    const tableQueryParams: TableQueryParams = {
        searchPhrase: tableParams.searchPhrase,
        sortBy: mapSortByToQueryString(tableParams.sortBy),
        sortDir: tableParams.sortDirection,
        orderDateFrom: tableParams.orderDateFrom,
        orderDateTo: tableParams.orderDateTo,
    }

    return (input: Partial<TableQueryParams>) => {
        const newTableQueryParams = { ...tableQueryParams, ...input }
        if (newTableQueryParams.sortBy === undefined) {
            delete newTableQueryParams.sortBy
        }
        if (newTableQueryParams.searchPhrase === "" || newTableQueryParams.searchPhrase === undefined) {
            delete newTableQueryParams.searchPhrase
        }
        navigate({ search: queryString.stringify(newTableQueryParams) })
    }
}

export const OrderOverview = (): ReactElement => {
    const transportOrders = useAppSelector(getOverviewTransportOrders)
    const isFetchingTransportOrders = useAppSelector(getTransportOrderLoadingSpinnerState)
    const [transportOrderToConfirm, setTransportOrderToConfirm] = useState<TransportOrder | undefined>(undefined)

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const searchTransportOrders = useSearchTransportOrders()
    const sortTransportOrders = useSortTransportOrders()

    const updateHistoryWithTableParams = useUpdateHistoryWithTableParams()
    const tableParams = extractTableParamsFromUrl(location.search)

    const initialOrderDateFrom = mapQueryStringToDate(tableParams.orderDateFrom, moment().subtract(14, "days").startOf("day").toDate())
    const initialOrderDateTo = mapQueryStringToDate(tableParams.orderDateTo, moment().add(14, "day").endOf("day").toDate())
    const [orderDateFrom, setOrderDateFrom] = useState<Date>(initialOrderDateFrom)
    const [orderDateTo, setOrderDateTo] = useState<Date>(initialOrderDateTo)

    useEffect(() => {
        dispatch(fetchTransportOrderBundlesAndEnablePolling({
            fromCreatedAt: orderDateFrom,
            toCreatedAt: orderDateTo,
        })).then(scrollToId)

        if (location.search) {
            const tableParams = extractTableParamsFromUrl(location.search)
            searchTransportOrders(tableParams.searchPhrase)
            sortTransportOrders({
                sortBy: tableParams.sortBy,
                sortDirection: tableParams.sortDirection
            })
        } else {
            searchTransportOrders(undefined)
            sortTransportOrders(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderDateTo, orderDateFrom])

    // search TOs every time the search phrase changes
    useEffect(() => {
        searchTransportOrders(tableParams.searchPhrase)
    }, [searchTransportOrders, tableParams.searchPhrase])

    // sort transportOrders every time sortBy or sortDirection changes
    useEffect(() => {
        sortTransportOrders({
            sortBy: tableParams.sortBy,
            sortDirection: tableParams.sortDirection
        })
    }, [sortTransportOrders, tableParams.sortBy, tableParams.sortDirection])

    const scrollToId = (): void => {
        const scrollPositionToId = location.state && location.state.scrollPositionToId
        if (scrollPositionToId) {
            scrollToTransportOrderAndHighlight(scrollPositionToId)
        }
    }

    const scrollToTransportOrderAndHighlight = (transportOrderId: string, highlightTimeout = 5000): void => {
        const element = document.getElementsByClassName(transportOrderId)
        if (element.length > 0) {
            const scrollIntoViewOptions: ScrollIntoViewOptions = {
                behavior: "auto",
                block: "center",
                inline: "nearest",
            }

            element[0].scrollIntoView(scrollIntoViewOptions)
            const e1: HTMLElement = element[0] as HTMLElement
            e1.classList.add("active")

            setTimeout(() => {
                e1.classList.remove("active")
            }, highlightTimeout)

        }
    }

    const updateSortBy = (sortBy: SortBy): void => {
        const tableParams = extractTableParamsFromUrl(location.search)
        const currentSort = { sortBy: tableParams.sortBy, sortDirection: tableParams.sortDirection }
        const reverseOrder = (criteria: SortCriteria): SortCriteria => {
            return {
                ...criteria,
                sortDirection: criteria.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
            }
        }
        const sortCriteria: SortCriteria = currentSort && currentSort.sortBy === sortBy
            ? reverseOrder(currentSort)
            : { sortBy: sortBy, sortDirection: SortDirection.ASC }

        updateHistoryWithTableParams({
            sortBy: mapSortByToQueryString(sortCriteria.sortBy),
            sortDir: sortCriteria.sortDirection,
        })
    }

    const openConfirmationDialog = (transportOrder: TransportOrder): Promise<void> => {
        return new Promise<void>(resolve => {
            setTransportOrderToConfirm(transportOrder)
            resolve()
        })
    }

    const jumpToDetailView = (transportOrder: TransportOrder): void => {
        const search = queryString.stringify({ redirect_uri: location.pathname + location.search })

        navigate({
            pathname: routingConfig.transportOrderPresenter.path.replace(":id", transportOrder.id),
            search,
        })
    }

    const provideCorrespondingConfirmationDialog = (transportOrder: TransportOrder): ReactElement => {
        switch (transportOrder.orderType) {
        case TransportOrderType.MATERIAL:
            return <MaterialOrderConfirmationDialog
                show={true}
                transportOrder={transportOrder}
                onCancel={hideOrderConfirmationDialog}
                onConfirm={applyConfirmations}
                toDetailsButtonCallback={(): void => jumpToDetailView(transportOrder)}
            />
        case TransportOrderType.EMPTIES:
            return <EmptiesOrderConfirmationDialog
                show={true}
                transportOrder={transportOrder}
                onCancel={hideOrderConfirmationDialog}
                onConfirm={applyConfirmations}
            />
        }
    }

    const hideOrderConfirmationDialog = (): void => setTransportOrderToConfirm(undefined)

    const applyConfirmations = (shipmentConfirmations: ShipmentConfirmation[]): Promise<void> => {
        if (transportOrderToConfirm === undefined) {
            return Promise.reject("Internal error: no transport order to confirm")
        }
        const fetchBundleParams: TransportOrderBundleFetchParams = {
            fromCreatedAt: orderDateFrom,
            toCreatedAt: orderDateTo
        }
        const reloadTransportOrderBundles = () => dispatch(fetchTransportOrderBundles(fetchBundleParams))

        return dispatch(confirmShipments(shipmentConfirmations))
            .then(reloadTransportOrderBundles)
            .then(hideOrderConfirmationDialog)
            .catch((err) => {
                Notification.error(<FormattedMessage id={"transportOrder.error.confirm"}/>)
                return Promise.reject(err)
            })
    }

    const updateSearchPhraseFilter = (value: string): void => {
        updateHistoryWithTableParams({ searchPhrase: value })
    }

    const changeOrderDateFrom = (date: Date): void => {
        const newDateFrom = moment(date).startOf("day").toDate()
        setOrderDateFrom(newDateFrom)
        updateHistoryWithTableParams({ orderDateFrom: newDateFrom?.toISOString(), orderDateTo: orderDateTo?.toISOString() })
    }

    const changeOrderDateTo = (date: Date): void => {
        const newDateTo = moment(date).endOf("day").toDate()
        setOrderDateTo(newDateTo)
        updateHistoryWithTableParams({ orderDateTo: newDateTo?.toISOString(), orderDateFrom: orderDateFrom?.toISOString() })
    }

    return (
        <ApplicationLayout.Body banner={<AnnouncementApplicationBanner/>}>
            <TableToolBar
                location={location.search}
                onSearchPhraseChange={updateSearchPhraseFilter}
                orderDateFrom={orderDateFrom}
                onChangeOrderDateFrom={changeOrderDateFrom}
                orderDateTo={orderDateTo}
                onChangeOrderDateTo={changeOrderDateTo}
                transportOrders={transportOrders}
            />
            {isFetchingTransportOrders
                ? <Spinner isFullSized/>
                : <TransportOrderTable
                    transportOrders={transportOrders}
                    onSortByChanged={updateSortBy}
                    onDetailsClicked={jumpToDetailView}
                    onConfirmClicked={openConfirmationDialog}/>
            }
            {transportOrderToConfirm
                ? provideCorrespondingConfirmationDialog(transportOrderToConfirm)
                : null}
        </ApplicationLayout.Body>
    )
}
