import { ReactElement } from "react"
import { FormattedMessage } from "react-intl"
import { InfoBox } from "./InfoBox"
import { routingConfig } from "../../Application/RoutingConfig"
import { useLocation, useNavigate } from "react-router-dom"

export type DetailViewJumpPointProps = {
    transportOrderId: string
    additionalInfoBoxMessageId?: string
}

export const DetailViewJumpPoint = (props: DetailViewJumpPointProps): ReactElement => {

    const navigate = useNavigate()
    const location = useLocation()
    const provideRouteToTransportOrderPresenterWithRedirectURI = (id = "new") => {
        const search = new URLSearchParams()
        search.set("redirect_uri", location.pathname + location.search)

        return {
            pathname: routingConfig.transportOrderPresenter.path.replace(":id", id),
            search: search.toString()
        }
    }

    const jumpToDetailedView = (transportOrderId: string): void => {
        navigate(provideRouteToTransportOrderPresenterWithRedirectURI(transportOrderId))
    }

    return <div className="panel panel-default">
        <div className="panel-body">
            <div className="display-flex align-items-center justify-content-center padding-50">
                <div className="text-center">
                    {props.additionalInfoBoxMessageId ?
                        <InfoBox messageId={props.additionalInfoBoxMessageId}/>
                        : null
                    }
                    <div className="alert alert-dismissable">
                        <i>
                            <FormattedMessage id="transportOrder.confirmation.dialog.body.toDetails.text" />
                        </i>
                    </div>
                    <button onClick={(): void => jumpToDetailedView(props.transportOrderId)}
                        data-testid={"to-details-button"}
                        className="btn-transportOrder-cancel btn btn-default btn-outline"
                    >
                        <FormattedMessage id="transportOrder.confirmation.dialog.body.toDetails.label"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
}
