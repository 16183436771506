import * as React from "react"
import ApplicationLayoutBodyBanner from "@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner"
import { FormattedMessage, useIntl } from "react-intl"

export const ANNOUNCEMENT_APPLICATION_BANNER_TEST_ID = "ANNOUNCEMENT_APPLICATION_BANNER_TEST_ID"

export const AnnouncementApplicationBanner = (): React.ReactElement | null => {
    const intl = useIntl()
    const text = intl.messages["application.announcement.banner.text"]

    if (!text) {
        return null
    }

    return (<ApplicationLayoutBodyBanner className="non-printable bg-info">
        <div className="white-space-pre-line padding-5" data-testid={ANNOUNCEMENT_APPLICATION_BANNER_TEST_ID}>
            <FormattedMessage
                id="application.announcement.banner.text"
                values = {{
                    b: (text) => <b>{text}</b>,
                }}
            />
        </div>
    </ApplicationLayoutBodyBanner>)
}
