import { ReactNode } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { VolumeMeasurement, VolumeMeasurementUnit } from "../../model/transportorder/measurement"
import { ReadOnlyDetailViewFormValue } from "./ReadOnlyDetailViewFormValue"

export interface IVolumeMeasurementInputProps {
    volume?: VolumeMeasurement
    label?: ReactNode
    placeholder?: string
}

export const VolumePresenter = (props: IVolumeMeasurementInputProps): ReactNode => {
    const unitOptions = {
        [VolumeMeasurementUnit.MTQ]: <FormattedMessage id="transportOrder.shipment.cargo.volume.unit.mtq"/>,
        [VolumeMeasurementUnit.DMQ]: <FormattedMessage id="transportOrder.shipment.cargo.volume.unit.dmq"/>,
        [VolumeMeasurementUnit.LTR]: <FormattedMessage id="transportOrder.shipment.cargo.volume.unit.ltr"/>
    }

    const intl = useIntl()
    const label = props.label ?? <FormattedMessage id="transportOrder.shipment.cargo.volume"/>

    const value = props.volume?.value ? intl.formatNumber(props.volume.value) : undefined
    const unit = props.volume && unitOptions[props.volume.unit] || ""

    return <ReadOnlyDetailViewFormValue
        label={label}
        value={value}
        suffix={unit}
        placeholder={props.placeholder}
    />
}
