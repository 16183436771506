import * as React from "react"
import { Context, ReactElement, ReactNode } from "react"
import ClearableInput from "@rio-cloud/rio-uikit/ClearableInput"
import { FormGroupWrapper } from "./FormGroupWrapper"
import { PropertyOfType } from "."
import { FormattedMessage } from "react-intl"
import { ValidatationState, ValidationContext, ValidationContextState } from "./validation/VaildationContext"
import { NotNullOrBlankValidator, Validator } from "./validation/Validators"
import { FormComponentState } from "./validation/FormComponentState"

type SupportedPropTypes = string | undefined

export interface IClearableFormInputProps<T> {
    data: T
    property: PropertyOfType<T, SupportedPropTypes>
    label?: string | React.ReactElement<typeof FormattedMessage>
    required?: boolean
    md?: number
    sm?: number
    xs?: number
    print?: number
    onPropertyChange?: (updated: T) => void
    disabled?: boolean
    placeholder?: ReactNode
    tabIndex?: number
    validate?: Validator<string>
    validateWarnings?: Validator<string>
}

export type ReadOnlyClearableStringPropertyProps<T> = Omit<Omit<IClearableFormInputProps<T>, "disabled">, "onPropertyChange">

export const ReadOnlyClearableStringProperty = <T,>(props: ReadOnlyClearableStringPropertyProps<T>): ReactElement => {
    return (
        <ClearableStringPropertyInput {...props} disabled={true} />
    )
}

const colClass = (name: string, width?: number): string => {
    return width ? `col-${name}-${width}` : ""
}

const printWidthClass = (percentage?: number): string => {
    return percentage ? `width-${percentage}pct-print float-left-print` : ""
}

export class ClearableStringPropertyInput<T> extends React.Component<IClearableFormInputProps<T>, FormComponentState> {

    static contextType: Context<ValidationContextState> = ValidationContext
    private readonly componentId: string

    constructor(props: IClearableFormInputProps<T>, context: ValidationContextState) {
        super(props, context)
        this.state = {
            touched: false,
            forceShowError: false
        }
        this.componentId = context.registerFormComponent((state: ValidatationState) => {
            this.setState({ forceShowError: state.forceShowValidationMessages })
        })
    }

    componentWillUnmount(): void {
        (this.context as ValidationContextState).deregisterFormComponent(this.componentId)
    }

    render(): ReactElement {
        const { label, md, sm, xs, print, required, data, property } = this.props
        const stringValue = data[property] && `${data[property]}`
        const errorMsgKey = this.validateInternal(stringValue)
        const warningMsgKey = this.validateWarnings(stringValue)
        const showError = errorMsgKey !== undefined && (this.state.touched || this.state.forceShowError)
        const showWarning = warningMsgKey !== undefined
        const hint = showError ? <FormattedMessage id={errorMsgKey}/> : showWarning ? <FormattedMessage id={warningMsgKey}/> : undefined
        const input = (
            <FormGroupWrapper label={label}
                required={required}
                hasError={showError}
                hasWarning={showWarning}
                hint={hint}
                component={<ClearableInput {...this.setupInput()} />}
            />
        )
        const printInput = (
            <FormGroupWrapper label={label}
                required={required}
                hasError={showError}
                hasWarning={showWarning}
                hint={hint}
                component={<div>{stringValue}</div>}
            />
        )

        const responsiveColClasses = `${colClass("xs", xs)} ${colClass("sm", sm)} ${colClass("md", md)}`

        if (print) {
            return (
                <>
                    <div className={`${responsiveColClasses} display-none-print`}>
                        {input}
                    </div>
                    <div className={`${responsiveColClasses} display-none display-block-print ${printWidthClass(print)}`}>
                        {printInput}
                    </div>
                </>
            )
        } else {
            return responsiveColClasses.trim()
                ? <div className={`${responsiveColClasses}`}>{input}</div>
                : input
        }
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    private setupInput = (): object => {
        const { property, data, onPropertyChange, disabled,  placeholder, tabIndex } = this.props

        // map null to undefined because somehow null can be the outcome of data[property]
        const value = data[property]?? undefined

        return {
            name: property,
            disabled,
            value: value,
            placeholder,
            tabIndex,
            onChange: (value: string): void => {
                const newValue = value || undefined
                onPropertyChange && onPropertyChange(Object.assign({}, data, { [property]: newValue }))
            },
            onBlur: (): void => {
                this.setState({ touched: true })
            },
            onClear: (event: Event): void => {
                event?.stopPropagation()
                this.setState({ touched: true })
            },
            onClick: (event: Event): void => event?.stopPropagation()
        }
    }

    private validateInternal = (value: string | undefined): string | undefined => {
        const { validate, required } = this.props
        const error: string | undefined = (required ? NotNullOrBlankValidator(value) : undefined)
                                                || (validate ? validate(value) : undefined)
        if (error) {
            (this.context as ValidationContextState).reportError(this.componentId)
        } else {
            (this.context as ValidationContextState).resolveError(this.componentId)
        }
        return error
    }

    private validateWarnings = (value: string | undefined): string | undefined => {
        const { validateWarnings } = this.props
        return validateWarnings ? validateWarnings(value) : undefined
    }
}
