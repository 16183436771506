import AsnMatchingClient, { ExternalCrossDock } from "../client/asn-matching/AsnMatchingClient"
import { ICrossDock } from "../model/crossdock/crossDock"
import { useEffect, useState } from "react"


export const fetchCrossDocks = async (): Promise<ICrossDock[]> => {
    return await AsnMatchingClient.getCrossDocks().then(
        crossDocks => crossDocks.map(crossDock => ExternalCrossDock(crossDock.id, crossDock.name).toDomain())
    )
}

interface CrossDocksResult {
    loading: boolean
    error?: Error
    crossDocks: ICrossDock[]
}

export const useCrossDocksOfAccount = (): CrossDocksResult => {
    const [result, setResult] = useState<CrossDocksResult>({ loading: true, error: undefined, crossDocks: [] })

    useEffect(() => {
        fetchCrossDocks().then(result => {
            setResult({ loading: false, error: undefined, crossDocks: result })
        }).catch(error => {
            setResult({ loading: false, error, crossDocks: [] })
        })
    }, [])

    return result
}