import { ReactNode, useRef } from "react"
import { FormattedMessage } from "react-intl"
import step1Duns from "./step1Duns.png"
import step2Howto from "./step2Howto.png"
import step3Addfleet from "./step3Addfleet.png"
import webTmsScreenArrangement from "./webTmsScreenArrangement.png"
import backGroundDispatchTeaser from "./backgroundDispatchTeaser.jpg"
import webTmsSystem from "./webtmsSystem.png"
import { Faq } from "./Faq"
import IframeResizer from "iframe-resizer-react"
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout"
import { config } from "../../configuration/app/app-config"

export function WelcomePage(): ReactNode {
    const supportRef = useRef<HTMLDivElement | null>(null)

    const scrollToSupport: () => void = () => supportRef.current?.scrollIntoView()

    return (
        <ApplicationLayout.Body>
            <>
                <div className="sectioncontainer">
                    <section className="bg-lighter sectioncontainer">
                        <div className="container-fluid fluid-default">

                            <div className="sectioncontainer padding-bottom-20">
                                <div className="sectionbox">
                                    <div className="panel panel-default panel-body padding-0">
                                        <div className="sectionBackgroundImage bg-position-top-center bg-size-cover bg-repeat-no "
                                            style={{ backgroundImage: `url(${backGroundDispatchTeaser})` }}>
                                            <div className="teaser">
                                                <div className="row">
                                                    <div className="col-md-7 padding-top-5pct margin-bottom--5pct padding-bottom-25 padding-right-25">
                                                        <img src={webTmsScreenArrangement} alt="RIO WebTMS"  className="img-responsive"/>
                                                    </div>
                                                    <div className="col-md-5 padding-top-15pct padding-left-15 padding-bottom-15">
                                                        <div
                                                            className="text-size-16 text-lighttext-color-white margin-20">
                                                            <h1 className="text-color-white padding-0 margin-0 text-size-18 text-size-300pct">
                                                            RIO <FormattedMessage id={"application.name"}/>
                                                            </h1>
                                                            <h2 className="text-color-lighter padding-0 margin-0 text-size-18 text-size-300pct">
                                                                <FormattedMessage id={"welcomePage.introduction.subheading"} />
                                                            </h2>
                                                        </div>
                                                        <div className="margin-20">
                                                            <a className="btn btn-primary btn-lg"
                                                                href={"/transportorders"}
                                                                target="_blank" rel="follow noopener noreferrer" >
                                                                <FormattedMessage
                                                                    id={"welcomePage.introduction.buttonText"} />
                                                                <span
                                                                    className="rioglyph rioglyph-chevron-right padding-left-5"
                                                                    aria-hidden="true">
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-size-20 margin-top-25 margin-left-25 padding-25">
                                            <p>
                                                <FormattedMessage id={"welcomePage.introduction.content"} />
                                                <strong> <FormattedMessage id={"welcomePage.introduction.helpText"} /> </strong>
                                                <FormattedMessage id={"welcomePage.introduction.goalText"} />
                                            </p>
                                            <p className="text-color-highlight">
                                                <FormattedMessage id={"welcomePage.introduction.continueText"} />
                                                <strong> <FormattedMessage id={"welcomePage.introduction.steps"} /></strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="sectionbox3">
                                <div className="sectionbox">
                                    <div className="row display-flex-sm">
                                        <div className="col-xs-12 col-sm-4 display-flex-sm">
                                            <div className="panel panel-default panel-body padding-25-sm width-100pct">
                                                <div className="teaser">
                                                    <div className="row ">
                                                        <div className="col-md-12">
                                                            <a href={config.contactForm.url} target="_blank" rel="noopener noreferrer">
                                                                <img src={step1Duns} alt="duns-senden" className="img-responsive"/>
                                                            </a>
                                                        </div>
                                                        <div className="col-md-12 ">
                                                            <div className="teaser_headline">
                                                                <h2 className="headline_text">
                                                                    <div className="counter color-highlight">
                                                                        <h3><FormattedMessage id={"welcomePage.duns.heading"} /></h3>
                                                                    </div>
                                                                </h2>
                                                            </div>
                                                            <div className="text-size-16">
                                                                <p><FormattedMessage id={"welcomePage.duns.description"}/></p>
                                                            </div>
                                                            <div>
                                                                <a className="btn btn-primary"
                                                                    href={config.contactForm.url}
                                                                    target="_blank" rel="follow noopener noreferrer"
                                                                    data-track-ga-event-trigger="click"
                                                                    data-track-ga-event-category="dispatch, welcomePage"
                                                                    data-track-ga-event-action="createTransportOrder" >
                                                                    <FormattedMessage id={"welcomePage.duns.button"}/>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-4 display-flex-sm">
                                            <div className="panel panel-default panel-body padding-25-sm width-100pct">
                                                <div className="teaser">
                                                    <div className="row ">
                                                        <div className="col-md-12">
                                                            <a onClick={scrollToSupport}>
                                                                <img src={step2Howto} alt="touren-anlegen" className="img-responsive"/>
                                                            </a>
                                                        </div>
                                                        <div className="col-md-12 ">
                                                            <div className="teaser_headline">
                                                                <h2 className="headline_text">
                                                                    <div className="counter color-highlight" style={{ counterIncrement: "rio-counter 1" }}>
                                                                        <h3><FormattedMessage id={"welcomePage.transportOrders.heading"}/></h3>
                                                                    </div>
                                                                </h2>
                                                            </div>
                                                            <div className="text-size-16">
                                                                <p>
                                                                    <FormattedMessage id={"welcomePage.transportOrders.description"}/>
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <a className="btn btn-primary"
                                                                    onClick={scrollToSupport}
                                                                    rel="follow noopener noreferrer"
                                                                    data-track-ga-event-trigger="click"
                                                                    data-track-ga-event-category="dispatch, welcomePage"
                                                                    data-track-ga-event-action="planTour" >
                                                                    <FormattedMessage id={"welcomePage.transportOrders.button"}/>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-4 display-flex-sm">
                                            <div className="panel panel-default panel-body padding-25-sm width-100pct">
                                                <div className="teaser">
                                                    <div className="row ">
                                                        <div className="col-md-12">
                                                            <a href={config.assetAdminFrontend.url} target="_blank" rel="noopener noreferrer">
                                                                <img src={step3Addfleet} alt="rioAssetAdministration" className="img-responsive"/>
                                                            </a>
                                                        </div>
                                                        <div className="col-md-12 ">
                                                            <div className="teaser_headline">
                                                                <h2 className="headline_text">
                                                                    <div className="counter color-highlight" style={{ counterIncrement: "rio-counter 2" }}>
                                                                        <h3><FormattedMessage id={"welcomePage.fleetAdmin.heading"} /></h3>
                                                                    </div>
                                                                </h2>
                                                            </div>
                                                            <div className="text-size-16">
                                                                <p>
                                                                    <FormattedMessage id={"welcomePage.fleetAdmin.description"}/>
                                                                </p>
                                                            </div>
                                                            <div className="btn-toolbar">
                                                                <a className="btn btn-primary"
                                                                    href={config.assetAdminFrontend.url}
                                                                    target="_blank"
                                                                    rel="follow noopener noreferrer"
                                                                    data-track-ga-event-trigger="click"
                                                                    data-track-ga-event-category="dispatch, welcomePage"
                                                                    data-track-ga-event-action="loadDriverApp" >
                                                                    <FormattedMessage id={"welcomePage.fleetAdmin.button"}/>
                                                                </a>
                                                                <a className="btn btn-primary"
                                                                    href={config.pocketDriverApp.playStoreUrl}
                                                                    target="_blank"
                                                                    rel="follow noopener noreferrer"
                                                                    data-track-ga-event-trigger="click"
                                                                    data-track-ga-event-category="dispatch, welcomePage"
                                                                    data-track-ga-event-action="loadDriverApp" >
                                                                    <FormattedMessage id={"welcomePage.driverApp.button"}/> - Android
                                                                </a>
                                                                <a className="btn btn-primary"
                                                                    href={config.pocketDriverApp.appStoreUrl}
                                                                    target="_blank"
                                                                    rel="follow noopener noreferrer"
                                                                    data-track-ga-event-trigger="click"
                                                                    data-track-ga-event-category="dispatch, welcomePage"
                                                                    data-track-ga-event-action="loadDriverApp" >
                                                                    <FormattedMessage id={"welcomePage.driverApp.button"}/> - iOS
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="sectioncontainer">
                    <section className="bg-lighter sectioncontainer js-man-showmore-content sm-man-autoexpand-on-first-visit smContentMAN0">
                        <div className="container-fluid fluid-default">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="text-center margin-top-5pct margin-bottom-25">
                                        <h1 className="headline_text visible"><FormattedMessage id={"welcomePage.goodToKnow.title"} /></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="sectionbox">
                                <div className="row flex-sm">
                                    <div className="col-md-12 flex-sm padding-top-5">
                                        <div className="panel panel-default panel-body padding-25-sm width-100pct">
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-6">
                                                    <h3 className="headline_text"><FormattedMessage id={"welcomePage.goodToKnow.headline"} /></h3>
                                                    <div className="text-size-16 margin-top-25 margin-bottom-25">
                                                        <p><FormattedMessage id={"welcomePage.goodToKnow.contentPart1"} /></p>
                                                        <p>
                                                            <FormattedMessage id={"welcomePage.goodToKnow.contentPart2"} /> <strong><FormattedMessage id={"welcomePage.goodToKnow.webTms"} /></strong>
                                                        </p>
                                                        <ul className="margin-top-25">
                                                            <li className="margin-top-25"><FormattedMessage id={"welcomePage.goodToKnow.item1"} /></li>
                                                            <li className="margin-top-25"><FormattedMessage id={"welcomePage.goodToKnow.item2"} /></li>
                                                            <li className="margin-top-25"><FormattedMessage id={"welcomePage.goodToKnow.item3"} /></li>
                                                            <li className="margin-top-25">
                                                                <FormattedMessage id={"welcomePage.goodToKnow.item4"} /><br /><FormattedMessage id={"welcomePage.goodToKnow.item4Continued"} />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-lg-6">
                                                    <img src={webTmsSystem} alt="rio4driverapp" className="img-responsive" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="sectioncontainer" ref={supportRef}>
                    <section className="bg-lighter sectioncontainer">
                        <div className="container-fluid fluid-default">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="text-center margin-top-5pct margin-bottom-25">
                                        <h1 className="headline_text visible"><FormattedMessage id={"welcomePage.support"} /></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="sectionbox">
                                <div className="row display-flex-sm">
                                    <div className="col-sm-12 display-flex-sm">
                                        <div className="panel panel-default panel-body padding-25-sm width-100pct">
                                            <div className="display-flex align-items-center justify-content-between margin-bottom-25">
                                                <h3 className="headline_text"><FormattedMessage id={"welcomePage.support.faq"} /></h3>
                                            </div>
                                            <Faq />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sectionbox">
                                <div className="row display-flex-sm">
                                    <div className="col-sm-12 display-flex-sm">
                                        <div className="panel panel-default panel-body margin-top-25 padding-25-sm width-100pct text-size-18">
                                            <div>
                                                <IframeResizer
                                                    className="iFrameResizer"
                                                    src={config.contactForm.url}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        </ApplicationLayout.Body>
    )
}
