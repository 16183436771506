import { ReactElement } from "react"
import { FormattedMessage } from "react-intl"
import { Address } from "../../model/transportorder/transportOrder"
import { ReadOnlyDetailViewFormValue } from "../Form/ReadOnlyDetailViewFormValue"

export interface AddressPresenterProps {
    address: Address
}

export const AddressPresenter = (props: AddressPresenterProps): ReactElement => {
    const address = props.address
    return (
        <>
            <div className="row">
                <ReadOnlyDetailViewFormValue
                    value={address.street}
                    xs={6}
                    label={<FormattedMessage id="transportOrder.shipment.address.street"/>}
                />
                <ReadOnlyDetailViewFormValue
                    value={address.house_number}
                    xs={6}
                    label={<FormattedMessage id="transportOrder.shipment.address.houseNumber"/>}
                />
            </div>
            <div className="row">
                <ReadOnlyDetailViewFormValue
                    value={address.postal_code}
                    xs={2}
                    label={<FormattedMessage id="transportOrder.shipment.address.postalCode"/>}
                />
                <ReadOnlyDetailViewFormValue
                    value={address.city}
                    xs={4}
                    label={<FormattedMessage id="transportOrder.shipment.address.city"/>}
                />
                <ReadOnlyDetailViewFormValue
                    value={address.country}
                    label={<FormattedMessage id="transportOrder.shipment.address.country"/>}
                    xs={6}
                />
            </div>
        </>
    )
}
