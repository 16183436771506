import { FormattedMessage, useIntl } from "react-intl"
import ExpanderList from "@rio-cloud/rio-uikit/ExpanderList"
import { ReactNode } from "react"
import { config } from "../../configuration/app/app-config"

export function Faq(): ReactNode {
    const intl = useIntl()
    const headerClassName = "text-size-18 text-color-highlight"
    const bodyClassName = "text-size-18"
    const faqListItems = [
        {
            header: <FormattedMessage id={"welcomePage.support.faq.item0.question"} />,
            headerClassName,
            body: <FormattedMessage id={"welcomePage.support.faq.item0.answer"} values={{
                _newLine: <br />
            }} />,
            bodyClassName
        },
        {
            header: <FormattedMessage id={"welcomePage.support.faq.item1.question"} />,
            headerClassName,
            body: <FormattedMessage id={"welcomePage.support.faq.item1.answer"} />,
            bodyClassName
        },
        {
            header: <FormattedMessage id={"welcomePage.support.faq.item2.question"} />,
            headerClassName,
            body: <FormattedMessage id={"welcomePage.support.faq.item2.answer"} />,
            bodyClassName
        },
        {
            header: <FormattedMessage id={"welcomePage.support.faq.item3.question"} />,
            headerClassName,
            body: <FormattedMessage id={"welcomePage.support.faq.item3.answer"} />,
            bodyClassName
        },
        {
            header: <FormattedMessage id={"welcomePage.support.faq.item4.question"} />,
            headerClassName,
            body: <FormattedMessage id={"welcomePage.support.faq.item4.answer"} values={{
                _mailTo: (<a href={"mailto:partners@rio.cloud"}>partners@rio.cloud</a>)
            }} />,
            bodyClassName
        },
        {
            header: <FormattedMessage id={"welcomePage.support.faq.item6.question"} values={
                {
                    _strong: (<strong><FormattedMessage id={"welcomePage.support.faq.item6.question.strong"} /></strong>)
                }
            } />,
            headerClassName,
            body: (
                <>
                    <FormattedMessage id={"welcomePage.support.faq.item6.answer.part1"} values={{
                        _newLine: <br />,
                        _strong: (<strong><FormattedMessage id={"welcomePage.support.faq.item6.answer.part1.strong"} /></strong>)
                    }} />
                    <ul>
                        <li><FormattedMessage id={"welcomePage.support.faq.item6.answer.step1"} /></li>
                        <li><FormattedMessage id={"welcomePage.support.faq.item6.answer.step2"} values={{
                            _urlAssetAdmin: config.assetAdminFrontend.url
                        }} /></li>
                        <li><FormattedMessage id={"welcomePage.support.faq.item6.answer.step3"} /></li>
                        <li><FormattedMessage id={"welcomePage.support.faq.item6.answer.step4"} /></li>
                        <li><strong><FormattedMessage id={"welcomePage.support.faq.item6.answer.step5"} /></strong></li>
                    </ul>
                    <FormattedMessage id={"welcomePage.support.faq.item6.answer.part2"} values={{
                        _strong1: <strong><FormattedMessage id={"welcomePage.support.faq.item6.answer.part2.strong1"} /></strong>,
                        _strong2: <strong><FormattedMessage id={"welcomePage.support.faq.item6.answer.part2.strong2"} /></strong>,
                    }} />
                </>
            ),
            bodyClassName
        },
        {
            header: <FormattedMessage id={"welcomePage.support.faq.item7.question"} />,
            headerClassName,
            body: (
                <>
                    <FormattedMessage id={"welcomePage.support.faq.item7.answer"} values={{
                        _link: <a href={intl.formatMessage({ id: "welcomePage.support.faq.item7.answer.link.href" })}
                            target="_blank"
                            rel="follow noopener noreferrer">
                            <FormattedMessage id={"welcomePage.support.faq.item7.answer.link.text"} />
                        </a>
                    }} />
                    <ul>
                        <li>RIO Box</li>
                        <li>Scania & Volvo Telematik (via rFMS Bridge)</li>
                        <li>Lost&Found</li>
                        <li>WebEye</li>
                        <li>YellowFox</li>
                        <li>TomTom Webfleet</li>
                        <li>WabcoTransics</li>
                        <li>RIO Pocket Driver App</li>
                    </ul>
                </>
            ),
            bodyClassName
        },
        {
            header: <FormattedMessage id={"welcomePage.support.faq.item8.question"} values={
                {
                    _strong: (<strong><FormattedMessage id={"welcomePage.support.faq.item8.question.strong"} /></strong>)
                }
            } />,
            headerClassName,
            body: <FormattedMessage id={"welcomePage.support.faq.item8.answer"} values={{
                _androidLink: <a href={ config.pocketDriverApp.playStoreUrl }
                    target="_blank"
                    rel="follow noopener noreferrer">
                    { config.pocketDriverApp.playStoreUrl }
                </a>,
                _iosLink: <a href={ config.pocketDriverApp.appStoreUrl }
                    target="_blank"
                    rel="follow noopener noreferrer">
                    { config.pocketDriverApp.appStoreUrl }
                </a>,
            }} />,
            bodyClassName
        },
        {
            header: <FormattedMessage id={"welcomePage.support.faq.item15.question"} />,
            headerClassName,
            body: <FormattedMessage id={"welcomePage.support.faq.item15.answer"} />,
            bodyClassName
        },
        {
            header: <FormattedMessage id={"welcomePage.support.faq.item16.question"} />,
            headerClassName,
            body: <FormattedMessage id={"welcomePage.support.faq.item16.answer"} />,
            bodyClassName
        },
        {
            header: <FormattedMessage id={"welcomePage.support.faq.item17.question"} />,
            headerClassName,
            body: <FormattedMessage id={"welcomePage.support.faq.item17.answer"} />,
            bodyClassName
        },
    ]

    return (
        <ExpanderList items={faqListItems} />
    )
}
