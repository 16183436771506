import * as React from "react"
import { ReactElement, useState } from "react"
import { FormattedMessage } from "react-intl"
import {
    ShipmentStatus,
    TimeWindow,
    TransportOrder,
    TransportOrderType,
    VehicleType
} from "../../../model/transportorder/transportOrder"
import { AssetSelect } from "../../Asset/AssetSelect"
import { TimeWindowBookingIdInput } from "./TimeWindowBookingIdInput"
import {
    applyConfirmationToAllShipments,
    areConfirmationsEqual,
    Confirmation,
    confirmationsForNonCancelled,
    ShipmentConfirmation
} from "../../../model/confirmation/Confirmation"
import {
    doesTransportOrderSupportDriverPhoneNumber,
    doesTransportOrderSupportLoadingTimeWindow,
    doesTransportOrderSupportTwoDrivers,
    doesTransportOrderSupportUnloadingTimeWindow,
    doesTransportOrderSupportVehicleType,
    isAnyShipmentConfirmed,
} from "../../../model/transportorder/transportOrderEditing"
import { DriverPhoneNumberInput } from "./DriverPhoneNumberInput"
import { VehicleTypeInput } from "./VehicleTypeInput"
import Checkbox from "@rio-cloud/rio-uikit/Checkbox"
import { CancelledStatusBadge } from "./CancelledStatusBadge"
import { TimeWindowInput } from "../timewindow/TimeWindowInput"
import { ValidFromAndTimeWindowCompleteValidator, ValidToAndTimeWindowCompleteValidator } from "./validator"
import { containsUrgentShipment, getShipmentFromTransportOrder } from "../../../helper/transportOrderHelper"
import { BorderoNumberInput } from "./BorderoNumberInput"
import { ICrossDock } from "../../../model/crossdock/crossDock"
import { CrossDockIdSelect } from "../../Form/CrossDockIdSelect"
import { useCrossDocksOfAccount } from "../../../hooks/crossDocks"
import { timezoneOfUnloadingAddress } from "../../../timezones/timezones"
import { MaterialOrderConfirmationLoadingTimeWindowInput } from "./MaterialOrderConfirmationLoadingTimeWindowInput"
import { TwoDriversCheckbox } from "./TwoDriversCheckBox"

interface Props {
    transportOrder: TransportOrder
    onChange(nonCancelledShipmentConfirmations: ShipmentConfirmation[]): void
}

interface TemporaryFormState {
    shipmentConfirmations: ShipmentConfirmation[]
    shipmentConfirmationsForNonCancelled: ShipmentConfirmation[]
    appliesToAllShipments: boolean
}

const replace = (shipmentConfirmations: ShipmentConfirmation[], replacement: ShipmentConfirmation): ShipmentConfirmation[] =>
    shipmentConfirmations.map(it => it.shipmentId === replacement.shipmentId ? replacement : it)

const initialFormStateFor = (transportOrder: TransportOrder): TemporaryFormState => {
    const shipmentConfirmations: ShipmentConfirmation[] = (transportOrder._embedded?.shipments ?? [])
        .map(shipment => ({
            shipmentId: shipment.id,
            shipmentStatus: shipment.status,
            confirmation: shipment.confirmationDraft,
            version: shipment.version,
        }))
    const shipmentConfirmationsForNonCancelled = confirmationsForNonCancelled(shipmentConfirmations)
    const appliesToAllShipments = shipmentConfirmationsForNonCancelled.every((shipmentConfirmation): boolean =>
        areConfirmationsEqual(shipmentConfirmation.confirmation, shipmentConfirmationsForNonCancelled[0].confirmation))
    return {
        shipmentConfirmations: shipmentConfirmations,
        shipmentConfirmationsForNonCancelled: shipmentConfirmationsForNonCancelled,
        appliesToAllShipments: appliesToAllShipments
    }
}

export const MaterialOrderConfirmationInput = (props: Props): React.ReactElement => {

    const { transportOrder } = props
    const [temporaryFormState, setTemporaryFormState] = useState(initialFormStateFor(props.transportOrder))

    const mergeTemporaryFormState = (changes: Partial<TemporaryFormState>): void => {
        const merged: TemporaryFormState = {
            ...temporaryFormState,
            ...changes
        }
        setTemporaryFormState(merged)
    }

    const notifyParentOfChanges = (shipmentConfirmationForNonCancelled: ShipmentConfirmation[]): void => {
        props.onChange(shipmentConfirmationForNonCancelled)
    }

    const AppliesToAllShipmentsCheckbox = (props: { show: boolean }): ReactElement => {
        if (!props.show) {
            return <></>
        }

        const mergeFirstConfirmationIntoAllShipments = (): void => {
            const shipmentConfirmationsForNonCancelled = applyConfirmationToAllShipments(
                temporaryFormState.shipmentConfirmationsForNonCancelled[0].confirmation,
                transportOrder
            )
            mergeTemporaryFormState({
                shipmentConfirmationsForNonCancelled
            })
            notifyParentOfChanges(shipmentConfirmationsForNonCancelled)
        }

        return (
            <div className="display-flex justify-content-start">
                <Checkbox
                    className="padding-top-10 padding-bottom-10"
                    onClick={(): void => {
                        const newAppliesToAllShipments = !temporaryFormState.appliesToAllShipments
                        if (newAppliesToAllShipments) {
                            mergeFirstConfirmationIntoAllShipments()
                        }
                        mergeTemporaryFormState({
                            appliesToAllShipments: newAppliesToAllShipments
                        })
                    }}
                    checked={temporaryFormState.appliesToAllShipments}
                >
                    <FormattedMessage id={"transportOrder.confirmation.appliesToAllShipments.sofa.label"}/>
                </Checkbox>
            </div>
        )
    }

    const displayLabelBasedOnShipmentPriority = (): string | undefined => {
        return containsUrgentShipment(props.transportOrder._embedded?.shipments) ?
            "transportOrder.material.confirmation.panel.title.urgent" : "transportOrder.material.confirmation.panel.title.regular"
    }

    const { crossDocks, loading } = useCrossDocksOfAccount()

    return <div
        className={"panel panel-default padding-bottom-5 callout callout-primary padding-0-print margin-bottom-0-print border-none-print overflow-visible"}
        data-testid="MaterialOrderConfirmationInput">
        <div className={"panel-heading text-color-darkest test-size-20 padding-0-print border-none-print"}>
            <h3 className={"margin-top-5 margin-bottom-5 text-size-20 text-color-darkest"}>
                <FormattedMessage id={displayLabelBasedOnShipmentPriority()}/>
            </h3>
        </div>
        <div className={"panel-body padding-0-print"}>
            <AppliesToAllShipmentsCheckbox show={temporaryFormState.shipmentConfirmationsForNonCancelled.length > 1}/>
            {temporaryFormState.appliesToAllShipments && temporaryFormState.shipmentConfirmationsForNonCancelled.length > 0
                ? <ConfirmationRow
                    crossDocks={crossDocks ?? []}
                    crossDocksLoaded={!loading}
                    title={undefined}
                    shipmentConfirmation={temporaryFormState.shipmentConfirmationsForNonCancelled[0]}
                    inputsVisible={true}
                    transportOrder={transportOrder}
                    onChange={(it: ShipmentConfirmation): void =>
                        notifyParentOfChanges(applyConfirmationToAllShipments(it.confirmation, transportOrder))
                    }/>
                : temporaryFormState.shipmentConfirmations.map((shipmentConfirmation, idx) => {
                    const isCancelled = shipmentConfirmation.shipmentStatus == ShipmentStatus.CANCELLED
                    return (
                        <ConfirmationRow
                            crossDocks={crossDocks ?? []}
                            crossDocksLoaded={!loading}
                            key={`shipmentConfirmationRow-${idx}`}
                            title={<h6
                                className={`max-width-100 ${isCancelled ? "text-decoration-line-through text-color-gray" : ""}`}>
                                <FormattedMessage id={"transportOrder.shipment.label.enumerated"}
                                    values={{ num: idx + 1 }}/>
                                <CancelledStatusBadge show={isCancelled} />
                            </h6>}
                            shipmentConfirmation={shipmentConfirmation}
                            inputsVisible={!isCancelled}
                            transportOrder={transportOrder}
                            onChange={(it: ShipmentConfirmation): void => {
                                const updatedShipmentConfirmations = replace(temporaryFormState.shipmentConfirmationsForNonCancelled, it)
                                mergeTemporaryFormState({
                                    shipmentConfirmations: updatedShipmentConfirmations,
                                    shipmentConfirmationsForNonCancelled: confirmationsForNonCancelled(updatedShipmentConfirmations)
                                })
                                notifyParentOfChanges(updatedShipmentConfirmations)
                            }
                            }/>
                    )
                })}
        </div>
    </div>
}

type ConfirmationRowProps = {
    title: React.ReactElement | undefined
    shipmentConfirmation: ShipmentConfirmation
    inputsVisible: boolean
    transportOrder: TransportOrder
    crossDocks: ICrossDock[]
    crossDocksLoaded: boolean

    onChange: (_: ShipmentConfirmation) => void
}

const ConfirmationRow = (props: ConfirmationRowProps): ReactElement => {
    const [shipmentConfirmation, setShipmentConfirmation] = useState<ShipmentConfirmation>(props.shipmentConfirmation)
    if (!props.inputsVisible) {
        return <>{props.title}</>
    }

    const confirmation = shipmentConfirmation.confirmation
    const transportOrder = props.transportOrder

    const updateConfirmation = (changes: Partial<Confirmation>): void => {
        const newShipmentConfirmation = {
            ...shipmentConfirmation,
            confirmation: {
                ...shipmentConfirmation.confirmation,
                ...changes
            }
        }
        setShipmentConfirmation(newShipmentConfirmation)
        props.onChange(newShipmentConfirmation)
    }

    const hasFirstTransportOrderRegularPriority = props.transportOrder._embedded?.shipments[0]?.priority === "REGULAR"
    const hideCrossDockIdSelect = !hasFirstTransportOrderRegularPriority
        || !props.crossDocksLoaded
        || props.crossDocks.length === 0

    const shipment = getShipmentFromTransportOrder(transportOrder, shipmentConfirmation.shipmentId)
    const accepted = isAnyShipmentConfirmed(transportOrder)
    const isMaterialTransport = transportOrder.orderType === TransportOrderType.MATERIAL
    const warnAboutMissingTimeSlotId = accepted && isMaterialTransport

    return (<div>
        {props.title}
        <div className="display-flex gap-15 flex-row-sm flex-wrap flex-column">
            <AssetSelect
                label={<FormattedMessage id="transportOrder.confirmation.input.label.asset"/>}
                assetId={confirmation?.asset?.id}
                onAssetSelected={(asset): void => {
                    updateConfirmation({
                        asset: asset ? {
                            id: asset.id,
                            licensePlate: asset.licensePlate,
                            countryCode: asset.licensePlateCountryCode,
                        } : undefined
                    })
                }}
                className={"flex-1-1 min-width-150"}
            />
            <VehicleTypeInput
                className={"flex-1-1 min-width-150"}
                show={doesTransportOrderSupportVehicleType(transportOrder)}
                value={confirmation.vehicleType}
                onChange={(newVehicleType: VehicleType | undefined): void => updateConfirmation({ vehicleType: newVehicleType })}
            />
            <DriverPhoneNumberInput
                show={doesTransportOrderSupportDriverPhoneNumber(transportOrder)}
                className={"flex-1-1 min-width-150"}
                value={confirmation.phoneNumber}
                onChange={(newPhoneNumber: string): void => updateConfirmation({ phoneNumber: newPhoneNumber || undefined })}
            />
            <TimeWindowBookingIdInput
                confirmation={confirmation}
                property={"accessCodeAtUnloading"}
                onChange={(value: Confirmation): void => updateConfirmation({ accessCodeAtUnloading: value.accessCodeAtUnloading || undefined })}
                className={"flex-1-1 min-width-150"}
                warnIfMissing={warnAboutMissingTimeSlotId}
            />
            <MaterialOrderConfirmationLoadingTimeWindowInput
                value={shipmentConfirmation.confirmation.loadingTimeWindow}
                show={doesTransportOrderSupportLoadingTimeWindow(transportOrder)}
                shipment={shipment}
                dateTimePropsFrom={{ className: "flex-1-1 min-width-150" }}
                dateTimePropsTo={{ className: "flex-1-1 min-width-150" }}
                onChange={(value: TimeWindow | undefined): void => updateConfirmation({ loadingTimeWindow: value })}
            />
            <TimeWindowInput
                value={shipmentConfirmation.confirmation.unloadingTimeWindow}
                show={doesTransportOrderSupportUnloadingTimeWindow(transportOrder)}
                timezone={timezoneOfUnloadingAddress(transportOrder, shipmentConfirmation.shipmentId)}
                labelFrom={<FormattedMessage id="transportOrder.confirmation.input.label.unloadingTimeWindow.from"/>}
                labelTo={<FormattedMessage id="transportOrder.confirmation.input.label.unloadingTimeWindow.to"/>}
                validateFrom={ValidFromAndTimeWindowCompleteValidator}
                validateTo={ValidToAndTimeWindowCompleteValidator}
                dateTimePropsFrom={{ className: "flex-1-1 min-width-150" }}
                dateTimePropsTo={{ className: "flex-1-1 min-width-150" }}
                onChange={(value: TimeWindow | undefined): void => updateConfirmation({ unloadingTimeWindow: value })
                }
            />
            <BorderoNumberInput
                show={true}
                confirmation={shipmentConfirmation.confirmation}
                onChange={(value: Confirmation): void => updateConfirmation({ loadId: value.loadId || undefined })}
                className={"flex-1-1 min-width-150"}
            />
            <CrossDockIdSelect
                hide={hideCrossDockIdSelect}
                className={"flex-1-1 min-width-150"}
                disabled={!props.crossDocksLoaded}
                options={props.crossDocks}
                value={shipmentConfirmation.confirmation.crossDockId}
                onChange={(value: string | undefined): void => updateConfirmation({ crossDockId: value })}  />
            <TwoDriversCheckbox
                show={doesTransportOrderSupportTwoDrivers(transportOrder)} confirmation={confirmation}
                className={"flex-1-1 min-width-150"}
                onChange={(newValue): void => updateConfirmation({ twoDrivers: newValue })}
            />
        </div>
    </div>)
}
