import { ReactElement, ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import ExpanderPanel from "@rio-cloud/rio-uikit/ExpanderPanel"

import { LoadingAddressPresenter } from "./LoadingAddressPresenter"
import { FreightPresenter } from "./FreightPresenter"
import { Shipment, ShipmentStatus } from "../../model/transportorder/transportOrder"
import { ProposedVehicleType } from "./ProposedVehicleTypeComponent"
import { ReadOnlyDetailViewFormValue } from "../Form/ReadOnlyDetailViewFormValue"

export interface ShipmentPresenterProps {
    shipment: Shipment
    shipmentNumber: number
    orderIdentifier?: string
}

export const ShipmentPresenter = (props: ShipmentPresenterProps): ReactElement => {

    const shipment = props.shipment
    const shipmentNumber = props.shipmentNumber
    const orderIdentifier = props.orderIdentifier

    const shipmentCancelled = (shipment.status === ShipmentStatus.CANCELLED)

    const shipmentStatusCancelledBadge = shipmentCancelled ? (
        <span className={"badge bg-rating-1 margin-left-10 margin-top-5 margin-bottom-5"}>
            <FormattedMessage id={`shipment.status.cancelled` } />
        </span>
    ) : null

    const shipmentTypeSuffix = shipment.priority === "URGENT"
        ? <FormattedMessage id={"transportOrder.transportType.urgent"} />
        : <>{props.shipment.processIndicator} </>

    const title = (
        <div className={"display-flex justify-content-between margin-top-10 margin-bottom-10 margin-bottom-0-print"}>
            <div className={"display-flex align-items-center"}>
                <h3 className={`margin-top-5 margin-bottom-5 text-size-20 ${!shipmentCancelled ? "text-color-darkest" : "text-color-gray"}`}>
                    <FormattedMessage id={"transportOrder.shipment.label.enumerated"} values={{ num: shipmentNumber }}/> - {shipmentTypeSuffix}
                </h3>
                {  shipmentStatusCancelledBadge }
            </div>
            <div className={"display-flex"}>
                {orderIdentifier !== undefined ?
                    <div className={"input-group width-200 padding-right-15"}>
                        <span className={"input-group-addon"}>
                            <span className={"rioglyph rioglyph-order"}/>
                        </span>
                        <input
                            value={orderIdentifier}
                            disabled={true}
                            className={"input-group form-control"}
                        />
                    </div> : undefined}
                <div className={"input-group width-250"}>
                    <span className={"rioglyph rioglyph-tag"} />
                    <FormattedMessage id={"transportOrder.shipment.external_id"}>
                        {(translatedPlaceholder: ReactNode): ReactElement =>
                            <ReadOnlyDetailViewFormValue
                                value={shipment.external_identification}
                                placeholder={translatedPlaceholder}
                            />
                        }
                    </FormattedMessage>
                </div>
            </div>
        </div>
    )

    const proposedVehicleTypePresentation = shipment.proposedVehicleType ? (
        <div className={"bg-lightest padding-15 padding-0-print margin-bottom-15 "}>
            <h5 className={"display-flex align-items-center"}>
                <span className={"rioglyph rioglyph-truck margin-right-5 text-size-16 text-color-primary"}/>
                <FormattedMessage id="transportOrder.shipment.vehicle"/>
            </h5>
            <ProposedVehicleType proposedVehicleType={shipment.proposedVehicleType}/>
        </div>
    ) : <></>

    return (
        <ExpanderPanel
            title={title}
            bsStyle="default"
            bodyClassName={"padding-0-print"}
            headerClassName={"padding-0-print"}
            className={"margin-bottom-25 border-none-print"}
            titleClassName={"width-100pct margin-0-print"}
            iconLeft={true}
            unmountOnExit={true}
            open={!shipmentCancelled}
        >
            <div className={"padding-10 padding-0-print"}>
                <div className="display-flex flex-row bg-lightest clearfix margin-bottom-15 margin-0-print">
                    <div className={"loading-address-load flex-1-1 padding-15 padding-0-print padding-right-15-print"} data-testid={"LoadingAddress_LOAD"}>
                        <h5 className={"display-flex align-items-center"}>
                            <span className={"rioglyph rioglyph-load margin-right-5 text-size-16 text-color-primary"} />
                            <FormattedMessage id="transportOrder.shippingAddress" />
                        </h5>
                        <LoadingAddressPresenter
                            loadingAddress={shipment.loading_address}
                            addressType={"LOAD"}
                        />
                    </div>
                    <div className={"loading-address-unload flex-1-1 padding-15 padding-0-print"} data-testid={"LoadingAddress_UNLOAD"}>
                        <h5 className={"display-flex align-items-center"}>
                            <span className={"rioglyph rioglyph-load-unload margin-right-5 text-size-16 text-color-primary"} />
                            <FormattedMessage id="transportOrder.receivingAddress" />
                        </h5>
                        <LoadingAddressPresenter
                            loadingAddress={shipment.unloading_address}
                            addressType={"UNLOAD"}
                        />
                    </div>
                </div>
                {proposedVehicleTypePresentation}
                <div className={"bg-lightest padding-15 padding-0-print"}>
                    <h5 className={"display-flex align-items-center"}>
                        <span className={"rioglyph rioglyph-parcel margin-right-5 text-size-16 text-color-primary"} />
                        <FormattedMessage id="transportOrder.shipment.freight" />
                    </h5>
                    <FreightPresenter freight={shipment.freight} />
                </div>
            </div>
        </ExpanderPanel>
    )
}
