import { Article, CargoFreight, HandlingUnit, Packable, Shipment, TransportOrder } from "./transportOrder"

export const flattenHandlingUnits = (transportOrder: TransportOrder): TransportOrder =>
    transportOrder._embedded === undefined ? transportOrder : {
        ...transportOrder,
        _embedded: {
            ...transportOrder._embedded,
            shipments: transportOrder._embedded.shipments.map(flattenHandlingUnitsOfShipment),
        }
    }

export const flattenHandlingUnitsOfShipment = (shipment: Shipment): Shipment =>
    shipment.freight.type === "CARGO" ? {
        ...shipment,
        freight: flattenHandlingUnitsOfCargoFreight(shipment.freight as CargoFreight),
    } : shipment

const flattenHandlingUnitsOfCargoFreight = (freight: CargoFreight): CargoFreight => ({
    ...freight,
    handling_units: freight.handling_units.map(pullUpArticles)
})

export const pullUpArticles = (handlingUnit: HandlingUnit): Omit<HandlingUnit, "content"> & { content: Article[] } => ({
    ...handlingUnit,
    content: pullUpArticlesFromPackables(handlingUnit.content),
})

const pullUpArticlesFromPackables = (packables: Packable[]): Article[] => packables.flatMap(pullUpArticlesFromPackable)

const pullUpArticlesFromPackable = (packable: Packable): Article[] => {
    if (packable.type === "ARTICLE") {
        return [packable]
    } else {
        return pullUpArticlesFromPackables(packable.content.map(it => multiplyQuantity(it, packable.quantity)))
    }
}

const multiplyQuantity = (packable: Packable, qty: number | undefined): Packable => {
    const multiply = (a: number | null | undefined, b: number | null | undefined): number | undefined =>
        a === undefined || a === null || b === undefined || b === null ? undefined : a * b

    return { ...packable, quantity: multiply(packable.quantity, qty) }
}
