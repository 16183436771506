import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export enum LoadingSpinner {
    FetchTransportOrders = "FetchTransportOrders",
}

type LoadingSpinnerMap = Record<LoadingSpinner, boolean>

export interface UIState {
    loadingSpinner: LoadingSpinnerMap
}

export const initialUIState: UIState = {
    loadingSpinner: {
        [LoadingSpinner.FetchTransportOrders]: false,
    },
}

export interface SetLoadingSpinner {
    loadingSpinnerName: LoadingSpinner
    spinning: boolean
    componentId?: string | undefined
}

export const uiSlice = createSlice({
    name: "ui",
    initialState: initialUIState,
    reducers: {
        setLoadingSpinner: (state, action: PayloadAction<SetLoadingSpinner>) => {
            const { loadingSpinnerName, componentId } = action.payload
            const key: string = componentId ? `${loadingSpinnerName}-${componentId}` : loadingSpinnerName
            state.loadingSpinner[key] = action.payload.spinning
        }
    }
})
