import { ReactElement } from "react"
import { FormattedMessage } from "react-intl"
import { Confirmation } from "../../../model/confirmation/Confirmation"
import { ClearableStringPropertyInput } from "../../Form/ClearableStringPropertyInput"
import { Validator } from "../../Form/validation/Validators"

interface BorderoNumberInputProps {
    show: boolean
    confirmation: Confirmation
    onChange: (confirmation: Confirmation) => void
    className?: string
}

export const BorderoNumberInput = (props: BorderoNumberInputProps): ReactElement | null => {
    if (!props.show) {
        return null
    }
    return (
        <div data-testid={"bordero-number-input"} className={props.className}>
            <ClearableStringPropertyInput
                data={props.confirmation}
                onPropertyChange={props.onChange}
                property={"loadId"}
                label={<FormattedMessage id={"transportOrder.confirmation.input.label.bordero"}/>}
                required={false}
                validate={(value): string | undefined => BorderoNumberValidator(value)}
            />
        </div>
    )
}

const BorderoNumberValidator: Validator<string> = (value?: string) => {
    return value ? /^[a-zA-Z\d]{1,17}$/.test(value) ? undefined : "validation.borderonumber.invalid" : undefined
}
