export enum ExternalGoodsType {
    CARGO_MATERIAL = "CARGO_MATERIAL",
    CARGO_EMPTIES = "CARGO_EMPTIES",
    BULK_DRY = "BULK_DRY",
    BULK_LIQUID = "BULK_LIQUID",
}

export type ExternalGoods = ExternalCargoMaterialGoods | ExternalCargoEmptiesGoods | ExternalBulkDryGoods | ExternalBulkLiquidGoods

export type ExternalCargoMaterialGoods = {
    type: ExternalGoodsType.CARGO_MATERIAL
    handling_units: Array<ExternalHandlingUnit>
}

export type ExternalCargoEmptiesGoods = {
    type: ExternalGoodsType.CARGO_EMPTIES
    handling_units: Array<ExternalHandlingUnitEmpties>
}

export type ExternalBulkDryGoods = {
    type: ExternalGoodsType.BULK_DRY
    article_id?: string
    dangerous_goods_information?: ExternalDangerousGoodsInformation
    volume_m3?: number
    weight_kg?: number
}

export type ExternalBulkLiquidGoods = {
    type: ExternalGoodsType.BULK_LIQUID
    article_id?: string
    dangerous_goods_information?: ExternalDangerousGoodsInformation
    volume_m3?: number
    weight_kg?: number
}

export interface ExternalWeight {
    gross_weight_kg?: number
}

export interface ExternalDangerousGoodsInformation {
    undg?: string
    description?: string
    exception_regulation?: string
}

export interface ExternalCountryOfOrigin {
    country_code?: string
    dutiable_goods: boolean
}

export type ExternalArticle = {
    internal_place_of_destination: { id?: string }
    net_explosive_mass_kg?: number
    type: ExternalPackableType.ARTICLE
    id?: string
    quantity?: { value: number }
    dangerous_goods_information?: ExternalDangerousGoodsInformation
    country_of_origin?: ExternalCountryOfOrigin
}

export type ExternalHandlingUnit = {
    type: ExternalPackableType.HANDLING_UNIT
    content: Array<ExternalPackable>
    weight?: ExternalWeight
    dimension?: ExternalDimension
    quantity?: number
    packaging_code?: string
    stacking_factor?: number
}

export type ExternalArticleEmpties = {
    id?: string
    quantity: number
    dangerous_goods_information?: ExternalDangerousGoodsInformation
}

export type ExternalHandlingUnitEmpties = {
    content: Array<ExternalArticleEmpties>
    gross_weight_kg?: number
    dimension?: ExternalDimension
    quantity?: number
    packaging_code?: string
    stacking_factor?: number
}

export enum ExternalPackableType {
    ARTICLE = "ARTICLE",
    HANDLING_UNIT = "HANDLING_UNIT",
}

export type ExternalPackable = ExternalHandlingUnit | ExternalArticle


export interface ExternalDimension {
    length_m?: number
    width_m?: number
    height_m?: number
}
