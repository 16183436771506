import * as React from "react"
import { FormattedMessage } from "react-intl"
import { FormGroupWrapper } from "../../Form/FormGroupWrapper"
import { DangerousGoodsInformation } from "../../../model/transportorder/transportOrder"
import { isDangerous } from "../../../model/transportorder/transportOrderDangerousGoods"
import { ReadOnlyDetailViewFormValue } from "../../Form/ReadOnlyDetailViewFormValue"
import { ReactElement } from "react"

export interface Props {
    value?: DangerousGoodsInformation
    selectClassName?: string
    undgCodeClassName?: string
    descriptionClassName?: string
    exceptionRegulationClassName?: string
}

export const DangerousGoodsPresenter = (props: Props): React.ReactElement => {
    const value = {
        isDangerous: isDangerous(props.value),
    }

    return <>
        <div className={props.selectClassName}>
            <FormGroupWrapper
                label={<FormattedMessage id={"transportOrder.dangerous_goods"}/>}
                component={
                    <ReadOnlyDetailViewFormValue
                        data={value}
                        property={"isDangerous"}
                        formatProperty={(isDangerous: boolean): ReactElement => isDangerous
                            ? <FormattedMessage id={"yes"}/>
                            : <FormattedMessage id={"no"}/>
                        }/>
                }
            />
        </div>
        <div className={props.undgCodeClassName}>
            <FormGroupWrapper
                label={<FormattedMessage id={"transportOrder.shipment.cargo.article.dangerousGoods.undgCode"}/>}
                component={<ReadOnlyDetailViewFormValue
                    value={props.value?.undg_code}
                />}
            />
        </div>
        <div className={props.descriptionClassName}>
            <FormGroupWrapper
                label={<FormattedMessage id={"transportOrder.shipment.cargo.article.dangerousGoods.description"}/>}
                component={<ReadOnlyDetailViewFormValue
                    value={props.value?.description}
                />}
            />
        </div>
        <div className={props.exceptionRegulationClassName}>
            <FormGroupWrapper
                label={<FormattedMessage id={"transportOrder.shipment.cargo.article.dangerousGoods.exceptionRegulation"}/>}
                component={<ReadOnlyDetailViewFormValue
                    value={props.value?.exception_regulation}
                />}
            />
        </div>
    </>
}
