import { ReactElement } from "react"
import { FormattedMessage } from "react-intl"
import { CustomerData } from "../../model/transportorder/transportOrder"
import { ReadOnlyDetailViewFormValue } from "../Form/ReadOnlyDetailViewFormValue"

interface CustomerPresenterProps {
    customerData?: CustomerData
}

export const CustomerPresenter = (props: CustomerPresenterProps): ReactElement => {
    const customerData = props.customerData ?? { name: "" }
    return (
        <div
            className={"panel panel-default padding-bottom-5 padding-0-print margin-bottom-0-print border-none-print"}
            data-testid="CustomerPresenter">
            <div className={"panel-heading text-color-darkest test-size-24 padding-0-print border-none-print"}>
                <h2 className={"margin-top-5 margin-bottom-5"}>
                    <FormattedMessage id={"transportOrder.customer"}/>
                </h2>
            </div>
            <div className={"panel-body padding-0-print"}>
                <div className={"row"}>
                    <ReadOnlyDetailViewFormValue
                        value={customerData.name}
                        label={<FormattedMessage id={"transportOrder.customer.name"}/>}
                        md={3}
                        print={50}
                    />
                    <ReadOnlyDetailViewFormValue
                        value={customerData.contact}
                        label={<FormattedMessage id={"transportOrder.customer.contact"}/>}
                        md={3}
                        print={50}
                    />
                    <ReadOnlyDetailViewFormValue
                        value={customerData.email}
                        label={<FormattedMessage id={"transportOrder.customer.email"}/>}
                        md={3}
                        print={50}
                    />
                    <ReadOnlyDetailViewFormValue
                        value={customerData.telephone}
                        label={<FormattedMessage id={"transportOrder.customer.phone"}/>}
                        md={3}
                        print={50}
                    />
                </div>
            </div>
        </div>
    )
}
