import { FormattedMessage } from "react-intl"
import { BulkFreight } from "../../model/transportorder/transportOrder"
import { DangerousGoodsPresenter } from "./dangerousgoods/DangerousGoodsPresenter"
import { ReadOnlyClearableStringProperty } from "../Form/ClearableStringPropertyInput"
import { ReactNode } from "react"

export interface BulkFreightPresenterProps {
    freight: BulkFreight
}

export const BulkFreightPresenter = (props: BulkFreightPresenterProps): ReactNode => {
    const freight = props.freight
    return (
        <>
            <div className="row">
                <ReadOnlyClearableStringProperty
                    data={freight}
                    property={"article_id"}
                    label={<FormattedMessage id="transportOrder.shipment.cargo.articleId"/>}
                    md={2}
                />
                <DangerousGoodsPresenter
                    value={freight.dangerous_goods_information}
                    selectClassName={"col-md-1 float-left-print width-20pct-print"}
                    undgCodeClassName={"col-md-1 float-left-print width-20pct-print"}
                    descriptionClassName={"col-md-1 word-break-all float-left-print width-20pct-print"}
                    exceptionRegulationClassName={"col-md-1 word-break-all float-left-print width-20pct-print"}
                />
            </div>
        </>
    )
}
