import { TransportOrder } from "../../../model/transportorder/transportOrder"
import { ReactElement } from "react"
import { TransportOrderTableRow } from "./TransportOrderTableRow"
import { columnDetailsByColumn, columns } from "./Columns"
import { TransportOrderTableHead } from "./TransportOrderTableHead"
import { SortBy } from "../../../redux/transportorder.slice"

export const TransportOrderTable = (props: {
    transportOrders: TransportOrder[]
    onSortByChanged: (sortBy: SortBy) => void
    onDetailsClicked: (transportOrder: TransportOrder) => void
    onConfirmClicked: (transportOrder: TransportOrder) => Promise<void>
}): ReactElement => {
    return (
        <table
            className={"table table-layout-fixed table-column-overflow-hidden table-bordered table-hover table-head-filled table-sticky tr.extendedRow"}
            data-testid={"OrderOverview_Table"}
        >
            <TransportOrderTableColGroup/>
            <TransportOrderTableHead onSortByChanged={props.onSortByChanged}/>
            <TransportOrderTableBody
                transportOrders={props.transportOrders}
                onDetailsClicked={props.onDetailsClicked}
                onConfirmClicked={props.onConfirmClicked}
            />
        </table>
    )
}

const TransportOrderTableColGroup = (): ReactElement => {
    const cols = columns.map(column => <col key={column} style={columnDetailsByColumn[column]}/>)

    return <colgroup>{cols}</colgroup>
}

const TransportOrderTableBody = (props: {
    transportOrders: TransportOrder[]
    onDetailsClicked: (transportOrder: TransportOrder) => void
    onConfirmClicked: (transportOrder: TransportOrder) => Promise<void>
}): ReactElement => {
    const transportOrderRows = props.transportOrders.map(transportOrder => TransportOrderTableRow(
        transportOrder,
        () => props.onDetailsClicked(transportOrder),
        () => props.onConfirmClicked(transportOrder)
    ))

    return (
        <tbody>
            {transportOrderRows}
        </tbody>
    )
}
