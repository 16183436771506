import { User } from "oidc-client-ts"
import { userManager } from "../../configuration/login/login"
import { config } from "../../configuration/app/app-config"

const getMockTokenFromSessionStorage = (): string | null => {
    // test token for mocking of authentication in production for monitoring
    return sessionStorage && sessionStorage.getItem("test_access_token")
}

export const getTokenFromSessionStorage = async (): Promise<string> => {
    const testAccessToken = getMockTokenFromSessionStorage()

    if (config.mockAccessToken) {
        return config.mockAccessToken
    } else if (testAccessToken) {
        return testAccessToken
    } else {
        const user: User | null = await userManager.getUser()
        if (!user) {
            throw new Error("User access token could not be read from session storage.")
        }
        return user.access_token
    }
}
