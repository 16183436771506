
import { Fragment, ReactElement } from "react"
import { FormattedDate } from "react-intl"
import { getClientTimezone } from "../timezones/timezones"

export const formatDateTimeColumn = (date?: string, timezone = "UTC"): ReactElement<{}> => {
    if (date) {
        return (
            <FormattedDate
                value={date}
                timeZone={timezone}
                year="numeric"
                month="numeric"
                day="2-digit"
                hour="numeric"
                minute="numeric"
            />
        )
    } else {
        return <Fragment />
    }
}

export const formatDateColumn = (date?: string): ReactElement<{}> => {
    if (date) {
        return (
            <FormattedDate
                value={date}
                timeZone={getClientTimezone()}
                year="numeric"
                month="numeric"
                day="2-digit"
            />
        )
    } else {
        return <Fragment />
    }
}

