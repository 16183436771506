import { ReactElement } from "react"
import { FormGroupWrapper } from "../../Form/FormGroupWrapper"
import { FormattedMessage } from "react-intl"
import Select from "@rio-cloud/rio-uikit/Select"
import { getAllPossibleVehicleTypes } from "../../../model/transportorder/transportOrderEditing"
import { VehicleType } from "../../../model/transportorder/transportOrder"
import { SelectOption } from "@rio-cloud/rio-uikit"

export interface VehicleTypeInputProps {
    show?: boolean
    disabled?: boolean
    className?: string
    value?: string
    onChange: (vehicleType: VehicleType | undefined) => void
    warning?: string
}

export const VehicleTypeInput = (props: VehicleTypeInputProps): ReactElement | null => {
    if (props.show == false) { // iff props.show set to true or undefined
        return null
    }
    const selectOptions = getAllPossibleVehicleTypes().map(option => {
        const i18nLabelKey = "transportOrder.shipment.vehicle." + option.replace("_", "").toLocaleLowerCase()
        const selectOption: SelectOption & { id: VehicleType } = {
            id: option,
            selected: props.value == option,
            label: <FormattedMessage id={i18nLabelKey}/>,
        }
        return selectOption
    })
    return (<FormGroupWrapper
        dataTestId={"driver-vehicletype-input"}
        className={props.className}
        label={<FormattedMessage id="transportOrder.confirmation.input.label.vehicleType"/>}
        component={
            <Select
                useClear={true}
                options={selectOptions}
                onChange={(option?: SelectOption & {id: VehicleType}): void => props.onChange(option?.id)}
                disabled={props.disabled}
            />
        }
    />)
}