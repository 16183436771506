import { Component, ReactElement } from "react"
import { CSVLink } from "react-csv"
import {
    Article,
    CargoFreight,
    HandlingUnit,
    Shipment,
    TransportOrder
} from "../../../model/transportorder/transportOrder"
import {
    aggregateNetExplosiveMassInG,
    calculateVolume,
    calculateWeight,
    formatInUserLocale,
    joinStreetAndHouseNumberOfAddress,
    joinStreetAndHouseNumberOfLoadingAddress,
    toHumanReadableDate,
    toHumanReadableTime
} from "../../../helper/csvExportHelpers"
import { getArticlesFromHandlingUnit } from "./ArticleExtractor"
import { articleIsDangerousGood } from "../../../model/transportorder/transportOrderDangerousGoods"
import { normalizeToMmt } from "../../../model/transportorder/measurement"
import { injectIntl, IntlShape, WrappedComponentProps } from "react-intl"
import {
    getClientTimezone,
    getTimezoneOfLoading,
    getTimezoneOfUnloading
} from "../../../timezones/timezones"

export interface MaterialCsvExportProps {
    transportOrders: TransportOrder[]
    buttonText: string
    formattedDateRange: string
    active: boolean
    className?: string
}

export interface CsvExportRow {
    shipmentExternalIdentification?: string
    referenceSupplier?: string
    externalOrderCreatedDate?: string
    pickUpDate?: string
    from?: string
    to?: string
    deliveryDate?: string
    senderName?: string
    senderCountry?: string
    senderPostalCode?: string
    senderCity?: string
    senderStreet?: string
    senderDUNS?: string
    senderLNo?: string
    buyerName?: string
    buyerCountry?: string
    buyerZip?: string
    buyerCity?: string
    buyerStreet?: string
    plantCustomer?: string
    recipientCID3?: string
    unloadingPoint?: string
    recipientName?: string
    recipientCountry?: string
    recipientZip?: string
    recipientCity?: string
    recipientStreet?: string
    productNumber?: string
    lengthHandlingUnit?: number
    widthHandlingUnit?: number
    heightHandlingUnit?: number
    numberOfPcc?: number
    innerPackagingMaterialId?: string
    handlingUnitQuantity?: number
    outerPackagingMaterialId?: string
    nrPackagingAid1?: string
    packagingAid1?: string
    nrPackagingAid2?: string
    packagingAid2?: string
    nrPackagingAid3?: string
    packagingAid3?: string
    unDangerousGoodsCode?: string
    descriptionDangerousGoods?: string
    dangerousGoodsClass?: string
    dangerousGoodsPackagingGroup?: string
    netExplosiveWeight?: number
    customsGoods?: string
    grossWeight?: number
    grossVolume?: number
    stackingFactor?: number
    orderNumber?: string
    transportNumber?: string
}

interface CSVHeaders {
    key: keyof CsvExportRow
    label: string
}

class MaterialCsvExportButton extends Component<MaterialCsvExportProps & WrappedComponentProps> {

    render(): ReactElement {
        const { intl } = this.props
        const headers: CSVHeaders[] = [
            {
                key: "shipmentExternalIdentification",
                label: intl.formatMessage({ id: "csvExport.headers.material.shipmentExternalIdentification" })
            },
            { key: "referenceSupplier", label: intl.formatMessage({ id: "csvExport.headers.material.referenceSupplier" }) },
            { key: "externalOrderCreatedDate", label: intl.formatMessage({ id: "csvExport.headers.material.externalOrderCreatedDate" }) },
            { key: "pickUpDate", label: intl.formatMessage({ id: "csvExport.headers.material.pickUpDate" }) },
            { key: "from", label: intl.formatMessage({ id: "csvExport.headers.material.from" }) },
            { key: "to", label: intl.formatMessage({ id: "csvExport.headers.material.to" }) },
            { key: "deliveryDate", label: intl.formatMessage({ id: "csvExport.headers.material.deliveryDate" }) },
            { key: "senderName", label: intl.formatMessage({ id: "csvExport.headers.material.senderName" }) },
            { key: "senderCountry", label: intl.formatMessage({ id: "csvExport.headers.material.senderCountry" }) },
            { key: "senderPostalCode", label: intl.formatMessage({ id: "csvExport.headers.material.senderPostalCode" }) },
            { key: "senderCity", label: intl.formatMessage({ id: "csvExport.headers.material.senderCity" }) },
            { key: "senderStreet", label: intl.formatMessage({ id: "csvExport.headers.material.senderStreet" }) },
            { key: "senderDUNS", label: intl.formatMessage({ id: "csvExport.headers.material.senderDUNS" }) },
            { key: "senderLNo", label: intl.formatMessage({ id: "csvExport.headers.material.senderLNo" }) },
            { key: "buyerName", label: intl.formatMessage({ id: "csvExport.headers.material.buyerName" }) },
            { key: "buyerCountry", label: intl.formatMessage({ id: "csvExport.headers.material.buyerCountry" }) },
            { key: "buyerZip", label: intl.formatMessage({ id: "csvExport.headers.material.buyerZip" }) },
            { key: "buyerCity", label: intl.formatMessage({ id: "csvExport.headers.material.buyerCity" }) },
            { key: "buyerStreet", label: intl.formatMessage({ id: "csvExport.headers.material.buyerStreet" }) },
            { key: "plantCustomer", label: intl.formatMessage({ id: "csvExport.headers.material.plantCustomer" }) },
            { key: "recipientCID3", label: intl.formatMessage({ id: "csvExport.headers.material.recipientCID3" }) },
            { key: "unloadingPoint", label: intl.formatMessage({ id: "csvExport.headers.material.unloadingPoint" }) },
            { key: "recipientName", label: intl.formatMessage({ id: "csvExport.headers.material.recipientName" }) },
            { key: "recipientCountry", label: intl.formatMessage({ id: "csvExport.headers.material.recipientCountry" }) },
            { key: "recipientZip", label: intl.formatMessage({ id: "csvExport.headers.material.recipientZip" }) },
            { key: "recipientCity", label: intl.formatMessage({ id: "csvExport.headers.material.recipientCity" }) },
            { key: "recipientStreet", label: intl.formatMessage({ id: "csvExport.headers.material.recipientStreet" }) },
            { key: "productNumber", label: intl.formatMessage({ id: "csvExport.headers.material.productNumber" }) },
            { key: "lengthHandlingUnit", label: intl.formatMessage({ id: "csvExport.headers.material.lengthHandlingUnit" }) },
            { key: "widthHandlingUnit", label: intl.formatMessage({ id: "csvExport.headers.material.widthHandlingUnit" }) },
            { key: "heightHandlingUnit", label: intl.formatMessage({ id: "csvExport.headers.material.heightHandlingUnit" }) },
            { key: "numberOfPcc", label: intl.formatMessage({ id: "csvExport.headers.material.numberOfPcc" }) },
            { key: "innerPackagingMaterialId", label: intl.formatMessage({ id: "csvExport.headers.material.innerPackagingMaterialId" }) },
            { key: "handlingUnitQuantity", label: intl.formatMessage({ id: "csvExport.headers.material.handlingUnitQuantity" }) },
            { key: "outerPackagingMaterialId", label: intl.formatMessage({ id: "csvExport.headers.material.outerPackagingMaterialId" }) },
            { key: "nrPackagingAid1", label: intl.formatMessage({ id: "csvExport.headers.material.nrPackagingAid1" }) },
            { key: "packagingAid1", label: intl.formatMessage({ id: "csvExport.headers.material.packagingAid1" }) },
            { key: "nrPackagingAid2", label: intl.formatMessage({ id: "csvExport.headers.material.nrPackagingAid2" }) },
            { key: "packagingAid2", label: intl.formatMessage({ id: "csvExport.headers.material.packagingAid2" }) },
            { key: "nrPackagingAid3", label: intl.formatMessage({ id: "csvExport.headers.material.nrPackagingAid3" }) },
            { key: "packagingAid3", label: intl.formatMessage({ id: "csvExport.headers.material.packagingAid3" }) },
            { key: "unDangerousGoodsCode", label: intl.formatMessage({ id: "csvExport.headers.material.unDangerousGoodsCode" }) },
            { key: "descriptionDangerousGoods", label: intl.formatMessage({ id: "csvExport.headers.material.descriptionDangerousGoods" }) },
            { key: "dangerousGoodsClass", label: intl.formatMessage({ id: "csvExport.headers.material.dangerousGoodsClass" }) },
            {
                key: "dangerousGoodsPackagingGroup",
                label: intl.formatMessage({ id: "csvExport.headers.material.dangerousGoodsPackagingGroup" })
            },
            { key: "netExplosiveWeight", label: intl.formatMessage({ id: "csvExport.headers.material.netExplosiveWeight" }) },
            { key: "customsGoods", label: intl.formatMessage({ id: "csvExport.headers.material.customsGoods" }) },
            { key: "grossWeight", label: intl.formatMessage({ id: "csvExport.headers.material.grossWeight" }) },
            { key: "grossVolume", label: intl.formatMessage({ id: "csvExport.headers.material.grossVolume" }) },
            { key: "stackingFactor", label: intl.formatMessage({ id: "csvExport.headers.material.stackingFactor" }) },
            { key: "orderNumber", label: intl.formatMessage({ id: "csvExport.headers.material.orderNumber" }) },
            { key: "transportNumber", label: intl.formatMessage({ id: "csvExport.headers.material.transportNumber" }) },
        ]
        const data = this.props.transportOrders.flatMap(transportOrder => mapTransportOrderToCsvData(transportOrder, intl))

        return <CSVLink
            data={data.map(row => formatInUserLocale(row, intl))}
            headers={headers}
            filename={`exported_materials_transportorders_${this.props.formattedDateRange}.csv`}
            className={this.props.className !== undefined ? this.props.className : `btn btn-primary ${this.props.active ? "" : "disabled"}`}
        >
            {this.props.buttonText}
        </CSVLink>
    }
}

const IntlMaterialCsvExportButton = injectIntl(MaterialCsvExportButton)
export default IntlMaterialCsvExportButton

export const mapTransportOrderToCsvData = (transportOrder: TransportOrder, intl: IntlShape): CsvExportRow[] => {
    if (!transportOrder._embedded) {
        return []
    }
    return transportOrder._embedded.shipments
        .flatMap(shipment => mapShipmentToCsvData(shipment, transportOrder, intl))
}

const mapShipmentToCsvData = (shipment: Shipment, transportOrder: TransportOrder, intl: IntlShape): CsvExportRow[] => {
    if (shipment.freight.type !== "CARGO") {
        return [mapHandlingUnitToCsvData(undefined, shipment, transportOrder, intl)]
    }
    return (shipment.freight as CargoFreight).handling_units
        .flatMap(handlingUnit => mapHandlingUnitToCsvData(handlingUnit, shipment, transportOrder, intl))
}

const distinct = (value: string | null | undefined, index: number, self: Array<string | null | undefined>): boolean => {
    return self.indexOf(value) === index
}

const getInternalPlaceOfDestination = (shipment: Shipment, articles: Article[]): string | undefined => {
    return articles.map(articles => articles.internal_place_of_destination)
        .filter(internalDestination => !!internalDestination)
        .filter(distinct)
        .join(" / ")
}

const mapHandlingUnitToCsvData = (handlingUnit: HandlingUnit | undefined, shipment: Shipment, transportOrder: TransportOrder, intl: IntlShape): CsvExportRow => {
    const articles = getArticlesFromHandlingUnit(handlingUnit)
    const firstArticleId = articles.map(articles => articles.article_id).find(id => (id !== undefined && id !== null))

    const combinedUndgCodes = articles.filter(articleIsDangerousGood)
        .map(article => article.dangerous_goods_information?.undg_code)
        .filter(undgCode => !!undgCode)
        .filter(distinct)
        .join(" / ")

    return {
        shipmentExternalIdentification: shipment.external_identification,
        referenceSupplier: shipment.loading_address.accessCode,
        externalOrderCreatedDate: toHumanReadableDate(intl.locale, transportOrder.external_order_created_date, getClientTimezone()),
        pickUpDate: toHumanReadableDate(intl.locale, shipment.loading_address.time_window?.time_from, getTimezoneOfLoading(shipment)),
        from: toHumanReadableTime(intl.locale, shipment.loading_address.time_window?.time_from, getTimezoneOfLoading(shipment)),
        to: toHumanReadableTime(intl.locale, shipment.loading_address.time_window?.time_to, getTimezoneOfLoading(shipment)),
        deliveryDate: toHumanReadableDate(intl.locale, shipment.unloading_address.time_window?.time_from, getTimezoneOfUnloading(shipment)),
        senderName: shipment.loading_address.name,
        senderCountry: shipment.loading_address.address?.country,
        senderPostalCode: shipment.loading_address.address?.postal_code,
        senderCity: shipment.loading_address.address?.city,
        senderStreet: joinStreetAndHouseNumberOfLoadingAddress(shipment.loading_address),
        senderDUNS: shipment.loading_address.duns,
        senderLNo: shipment.loading_address.external_identifiers?.find((it) => it.type === "VW_LOLI")?.identifier,
        buyerName: transportOrder.buyer?.name,
        buyerCountry: transportOrder.buyer?.address?.country,
        buyerZip: transportOrder.buyer?.address?.postal_code,
        buyerCity: transportOrder.buyer?.address?.city,
        buyerStreet: joinStreetAndHouseNumberOfAddress(transportOrder.buyer?.address),
        plantCustomer: shipment.unloading_address.external_identifiers?.find((it) => it.type === "VW_PLANT_CODE")?.identifier,
        recipientCID3: shipment.unloading_address.location_identification?.place_of_discharge,
        unloadingPoint: getInternalPlaceOfDestination(shipment, articles),
        recipientName: shipment.unloading_address.name,
        recipientCountry: shipment.unloading_address.address?.country,
        recipientZip: shipment.unloading_address.address?.postal_code,
        recipientCity: shipment.unloading_address.address?.city,
        recipientStreet: joinStreetAndHouseNumberOfLoadingAddress(shipment.unloading_address),
        productNumber: firstArticleId, // This is broken, but VW confirmed that this is the way to go.
        lengthHandlingUnit: normalizeToMmt(handlingUnit?.dimension?.length)?.value,
        widthHandlingUnit: normalizeToMmt(handlingUnit?.dimension?.width)?.value,
        heightHandlingUnit: normalizeToMmt(handlingUnit?.dimension?.height)?.value,
        numberOfPcc: undefined,
        innerPackagingMaterialId: undefined,
        handlingUnitQuantity: handlingUnit?.quantity,
        outerPackagingMaterialId: handlingUnit?.packaging_material_id,
        nrPackagingAid1: undefined,
        packagingAid1: undefined,
        nrPackagingAid2: undefined,
        packagingAid2: undefined,
        nrPackagingAid3: undefined,
        packagingAid3: undefined,
        unDangerousGoodsCode: combinedUndgCodes,
        descriptionDangerousGoods: undefined,
        dangerousGoodsClass: undefined,
        dangerousGoodsPackagingGroup: undefined,
        netExplosiveWeight: handlingUnit && aggregateNetExplosiveMassInG(handlingUnit),
        customsGoods: undefined,
        grossWeight: handlingUnit && calculateWeight(handlingUnit),
        grossVolume: handlingUnit && calculateVolume(handlingUnit),
        stackingFactor: handlingUnit?.stacking_factor,
        orderNumber: undefined,
        transportNumber: transportOrder.external_order_number
    }
}
