import { Component, ReactElement } from "react"
import { CSVLink } from "react-csv"
import {
    CargoFreight,
    HandlingUnit,
    Shipment,
    ShipmentStatus,
    TransportOrder
} from "../../../model/transportorder/transportOrder"
import {
    calculateVolume,
    calculateWeight,
    formatInUserLocale,
    joinStreetAndHouseNumberOfLoadingAddress,
    toHumanReadableDate,
    toHumanReadableDateAndTime,
    toHumanReadableTimeWindow
} from "../../../helper/csvExportHelpers"
import { getClientTimezone, getTimezoneOfLoading } from "../../../timezones/timezones"
import { injectIntl, IntlShape, WrappedComponentProps } from "react-intl"

export interface EmptiesCsvExportProps {
    transportOrders: TransportOrder[]
    buttonText: string
    formattedDateRange: string
    active: boolean
    className?: string
}

export interface CsvExportRow {
    shipmentExternalIdentification: string | undefined
    externalOrderCreatedDate: string | undefined
    shipmentLastModifiedTime: string | undefined
    shipmentLastModifiedLocation: undefined
    shipmentStatus: string | undefined
    shipFromPlantCode: string | undefined
    shipFromLoLi: string | undefined
    shipFromTimeWindow: string | undefined
    shipFromAddressAddition: string | undefined
    shipFromAccessCode: string | undefined
    transportOrderExternalOrderNumber: string | undefined
    shipFromName: string | undefined
    shipFromAddressStreet: string | undefined
    shipFromAddressPostalCode: string | undefined
    shipFromAddressCity: string | undefined
    shipFromAddressCountry: string | undefined
    shipToDuns: string | undefined
    shipToLoLi: string | undefined
    shipToName: string | undefined
    shipToAddressStreet: string | undefined
    shipToAddressPostalCode: string | undefined
    shipToAddressCity: string | undefined
    shipToAddressCountry: string | undefined
    handlingUnitArticleId: string | undefined
    handlingUnitEmptiesPackagingDetails: string | undefined
    handlingUnitQuantity: string | undefined
    handlingUnitVolume: number | undefined
    handlingUnitWeight: number | undefined
}

interface CsvHeaders {
    key: keyof CsvExportRow
    label: string
}

class EmptiesCsvExportButton extends Component<EmptiesCsvExportProps & WrappedComponentProps> {

    render(): ReactElement {
        const { intl } = this.props
        const headers: CsvHeaders[] = [
            {
                key: "shipmentExternalIdentification",
                label: intl.formatMessage({ id: "csvExport.headers.empties.shipmentExternalIdentification" })
            },
            { key: "externalOrderCreatedDate", label: intl.formatMessage({ id: "csvExport.headers.empties.externalOrderCreatedDate" }) },
            { key: "shipmentLastModifiedTime", label: intl.formatMessage({ id: "csvExport.headers.empties.shipmentLastModifiedTime" }) },
            {
                key: "shipmentLastModifiedLocation",
                label: intl.formatMessage({ id: "csvExport.headers.empties.shipmentLastModifiedLocation" })
            },
            { key: "shipmentStatus", label: intl.formatMessage({ id: "csvExport.headers.empties.shipmentStatus" }) },
            { key: "shipFromPlantCode", label: intl.formatMessage({ id: "csvExport.headers.empties.shipFromPlantCode" }) },
            { key: "shipFromLoLi", label: intl.formatMessage({ id: "csvExport.headers.empties.shipFromLoLi" }) },
            { key: "shipFromTimeWindow", label: intl.formatMessage({ id: "csvExport.headers.empties.shipFromTimeWindow" }) },
            { key: "shipFromAddressAddition", label: intl.formatMessage({ id: "csvExport.headers.empties.shipFromAddressAddition" }) },
            { key: "shipFromAccessCode", label: intl.formatMessage({ id: "csvExport.headers.empties.shipFromAccessCode" }) },
            {
                key: "transportOrderExternalOrderNumber",
                label: intl.formatMessage({ id: "csvExport.headers.empties.transportOrderExternalOrderNumber" })
            },
            { key: "shipFromName", label: intl.formatMessage({ id: "csvExport.headers.empties.shipFromName" }) },
            { key: "shipFromAddressStreet", label: intl.formatMessage({ id: "csvExport.headers.empties.shipFromAddressStreet" }) },
            { key: "shipFromAddressPostalCode", label: intl.formatMessage({ id: "csvExport.headers.empties.shipFromAddressPostalCode" }) },
            { key: "shipFromAddressCity", label: intl.formatMessage({ id: "csvExport.headers.empties.shipFromAddressCity" }) },
            { key: "shipFromAddressCountry", label: intl.formatMessage({ id: "csvExport.headers.empties.shipFromAddressCountry" }) },
            { key: "shipToDuns", label: intl.formatMessage({ id: "csvExport.headers.empties.shipToDuns" }) },
            { key: "shipToLoLi", label: intl.formatMessage({ id: "csvExport.headers.empties.shipToLoLi" }) },
            { key: "shipToName", label: intl.formatMessage({ id: "csvExport.headers.empties.shipToName" }) },
            { key: "shipToAddressStreet", label: intl.formatMessage({ id: "csvExport.headers.empties.shipToAddressStreet" }) },
            { key: "shipToAddressPostalCode", label: intl.formatMessage({ id: "csvExport.headers.empties.shipToAddressPostalCode" }) },
            { key: "shipToAddressCity", label: intl.formatMessage({ id: "csvExport.headers.empties.shipToAddressCity" }) },
            { key: "shipToAddressCountry", label: intl.formatMessage({ id: "csvExport.headers.empties.shipToAddressCountry" }) },
            { key: "handlingUnitArticleId", label: intl.formatMessage({ id: "csvExport.headers.empties.handlingUnitArticleId" }) },
            {
                key: "handlingUnitEmptiesPackagingDetails",
                label: intl.formatMessage({ id: "csvExport.headers.empties.handlingUnitEmptiesPackagingDetails" })
            },
            { key: "handlingUnitQuantity", label: intl.formatMessage({ id: "csvExport.headers.empties.handlingUnitQuantity" }) },
            { key: "handlingUnitVolume", label: intl.formatMessage({ id: "csvExport.headers.empties.handlingUnitVolume" }) },
            { key: "handlingUnitWeight", label: intl.formatMessage({ id: "csvExport.headers.empties.handlingUnitWeight" }) },
        ]
        const data = this.props.transportOrders.flatMap(transportOrder => mapTransportOrderToCsvData(transportOrder, intl))

        return <CSVLink
            data={data.map(row => formatInUserLocale(row, intl))}
            headers={headers}
            filename={`exported_empties_transportorders_${this.props.formattedDateRange}.csv`}
            className={this.props.className !== undefined ? this.props.className : `btn btn-primary ${this.props.active ? "" : "disabled"}`}
        >
            {this.props.buttonText}
        </CSVLink>
    }
}

const IntlEmptiesCsvExportButton = injectIntl(EmptiesCsvExportButton)
export default IntlEmptiesCsvExportButton

export const mapTransportOrderToCsvData = (transportOrder: TransportOrder, intl: IntlShape): CsvExportRow[] => {
    if (!transportOrder._embedded || !transportOrder._embedded.shipments) {
        return []
    }
    return transportOrder._embedded.shipments
        .flatMap(shipment => mapShipmentToCsvData(shipment, transportOrder, intl))
}

const mapShipmentToCsvData = (shipment: Shipment, transportOrder: TransportOrder, intl: IntlShape): CsvExportRow[] => {
    if (shipment.freight.type !== "CARGO") {
        return [mapHandlingUnitToCsvData(undefined, shipment, transportOrder, intl)]
    }
    return (shipment.freight as CargoFreight).handling_units
        .map(handlingUnit => mapHandlingUnitToCsvData(handlingUnit, shipment, transportOrder, intl))
}

const mapHandlingUnitToCsvData = (handlingUnit: HandlingUnit | undefined, shipment: Shipment, transportOrder: TransportOrder, intl: IntlShape): CsvExportRow => {
    return {
        shipmentExternalIdentification: shipment.external_identification,
        externalOrderCreatedDate: toHumanReadableDate(intl.locale, transportOrder.external_order_created_date, getClientTimezone()),
        shipmentLastModifiedTime: toHumanReadableDateAndTime(intl.locale, shipment.last_modified_date, getClientTimezone()),
        shipmentLastModifiedLocation: undefined,
        shipmentStatus: getShipmentStatus(shipment, intl),
        shipFromPlantCode: shipment.loading_address.external_identifiers?.find(it => it.type === "VW_PLANT_CODE")?.identifier,
        shipFromLoLi: shipment.loading_address.external_identifiers?.find(it => it.type === "VW_LOLI")?.identifier,
        shipFromTimeWindow: toHumanReadableTimeWindow(intl.locale, shipment.loading_address.time_window, getTimezoneOfLoading(shipment)),
        shipFromAddressAddition: shipment.loading_address.address?.addition,
        shipFromAccessCode: shipment.loading_address.accessCode,
        transportOrderExternalOrderNumber: transportOrder.external_order_number,
        shipFromName: shipment.loading_address.name,
        shipFromAddressStreet: joinStreetAndHouseNumberOfLoadingAddress(shipment.loading_address),
        shipFromAddressPostalCode: shipment.loading_address.address?.postal_code,
        shipFromAddressCity: shipment.loading_address.address?.city,
        shipFromAddressCountry: shipment.loading_address.address?.country,
        shipToDuns: shipment.unloading_address.duns,
        shipToLoLi: shipment.unloading_address.external_identifiers?.find(it => it.type === "VW_LOLI")?.identifier,
        shipToName: shipment.unloading_address.name,
        shipToAddressStreet: joinStreetAndHouseNumberOfLoadingAddress(shipment.unloading_address),
        shipToAddressPostalCode: shipment.unloading_address.address?.postal_code,
        shipToAddressCity: shipment.unloading_address.address?.city,
        shipToAddressCountry: shipment.unloading_address.address?.country,
        handlingUnitArticleId: handlingUnit?.packaging_material_id,
        handlingUnitEmptiesPackagingDetails: undefined,
        handlingUnitQuantity: handlingUnit?.quantity?.toString(),
        handlingUnitVolume: handlingUnit && calculateVolume(handlingUnit),
        handlingUnitWeight: handlingUnit && calculateWeight(handlingUnit)
    }
}

const getShipmentStatus = (shipment: Shipment, intl: IntlShape): string => {
    if (shipment.status === ShipmentStatus.CANCELLED) {
        return intl.formatMessage({ id: "csvExport.values.empties.shipmentStatus.pickupCancelled" })
    } else if (shipment.isConfirmed) {
        return intl.formatMessage({ id: "csvExport.values.empties.shipmentStatus.pickupConfirmed" })
    } else {
        return intl.formatMessage({ id: "csvExport.values.empties.shipmentStatus.pickupRequested" })
    }
}
