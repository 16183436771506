import * as React from "react"
import { useState } from "react"
import { TimeWindow } from "../../../model/transportorder/transportOrder"
import { DateTimeInput, Props as DateTimeInputProps } from "./DateTimeInput"
import { Validator } from "../../Form/validation/Validators"

export interface Props {
    value?: TimeWindow
    show?: boolean
    className?: string
    timezone?: string
    labelFrom: string | React.ReactElement
    labelTo: string | React.ReactElement
    validateTo?: Validator<TimeWindow>
    validateFrom?: Validator<TimeWindow>
    dateTimePropsFrom?: Partial<DateTimeInputProps>
    dateTimePropsTo?: Partial<DateTimeInputProps>
    onChange?: (_: TimeWindow | undefined) => void
}

export const TimeWindowInput = (props: Props): React.ReactElement | null => {

    const [value, setValue] = useState<TimeWindow | undefined>(props.value)

    if (props.show == false) {
        return null
    }

    const updateStateAndInvokeCallback = (newTimeWindow: TimeWindow): void => {
        if (newTimeWindow.time_from === undefined && newTimeWindow.time_to === undefined) {
            setValue(undefined)
            props.onChange && props.onChange(undefined)
        } else {
            setValue(newTimeWindow)
            props.onChange && props.onChange(newTimeWindow)
        }
    }

    return <>
        <div className={props.className}>
            <DateTimeInput
                {...(props.dateTimePropsFrom ?? {})}
                label={props.labelFrom}
                value={props.value?.time_from}
                timezone={props.timezone}
                validate={(): string | undefined => props.validateFrom && props.validateFrom(value)}
                onChange={(newValue): void => {
                    const newTimeWindow = { ...(value ?? {}), time_from: newValue || undefined } as TimeWindow
                    updateStateAndInvokeCallback(newTimeWindow)
                }}
            />
        </div>
        <div className={props.className}>
            <DateTimeInput
                {...(props.dateTimePropsTo ?? {})}
                label={props.labelTo}
                value={props.value?.time_to}
                timezone={props.timezone}
                validate={(): string | undefined => props.validateTo && props.validateTo(value)}
                onChange={(newValue): void => {
                    const newTimeWindow = { ...(value ?? {}), time_to: newValue || undefined } as TimeWindow
                    updateStateAndInvokeCallback(newTimeWindow)
                }}
            />
        </div>
    </>
}
