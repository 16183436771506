import { ReactElement } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { LengthPresenter } from "../Form/LengthPresenter"
import { WeightPresenter } from "../Form/WeightPresenter"
import { ArticlePresenter } from "./ArticlePresenter"
import { HandlingUnit } from "../../model/transportorder/transportOrder"
import { ReadOnlyDetailViewFormValue } from "../Form/ReadOnlyDetailViewFormValue"

interface HandlingUnitPresenterProps {
    handlingUnit: HandlingUnit
}

const orUndefined = <T, R>(input: T | undefined, extractor: (input: T) => R): R | undefined => {
    return input ? extractor(input) : undefined
}

export const HandlingUnitPresenter = (props: HandlingUnitPresenterProps): ReactElement => {
    const intl = useIntl()
    const handlingUnit = props.handlingUnit
    return <div className="row">
        <div className="col-sm-11">
            <div className="row form-group" data-testid="HandlingUnitPresenter_CargoDetails">
                <ReadOnlyDetailViewFormValue
                    data={handlingUnit}
                    property={"quantity"}
                    formatProperty={intl.formatNumber}
                    md={4}
                    print={25}
                    label={<FormattedMessage id="transportOrder.shipment.cargo.quantity"/>}/>
                <div className="col-md-4 width-25pct-print float-left-print">
                    <LoadCarrierPresenter selected={handlingUnit.packaging_material_id}/>
                </div>
            </div>
            <div className="row form-group">
                <div className="col-md-4 width-50pct-print float-left-print">
                    <WeightPresenter
                        weight={handlingUnit.weight}
                        label={<FormattedMessage id="transportOrder.shipment.cargo.weight.single"/>}/>
                </div>
                <ReadOnlyDetailViewFormValue
                    data={handlingUnit}
                    property="stacking_factor"
                    formatProperty={intl.formatNumber}
                    md={2}
                    print={50}
                    label={<FormattedMessage id="transportOrder.shipment.cargo.stackingFactor"/>}/>
                <div className="col-md-2 width-25pct-print float-left-print">
                    <LengthPresenter
                        length={orUndefined(handlingUnit.dimension, d => d.length)}
                        label={<FormattedMessage id="transportOrder.shipment.cargo.length"/>}/>
                </div>
                <div className="col-md-2 width-25pct-print float-left-print">
                    <LengthPresenter
                        length={orUndefined(handlingUnit.dimension, d => d.width)}
                        label={<FormattedMessage id="transportOrder.shipment.cargo.width"/>}/>
                </div>
                <div className="col-md-2 width-25pct-print float-left-print">
                    <LengthPresenter
                        length={orUndefined(handlingUnit.dimension, d => d.height)}
                        label={<FormattedMessage id="transportOrder.shipment.cargo.height"/>}/>
                </div>
            </div>
            {
                handlingUnit.content.map((it) => {
                    if(it.type === "ARTICLE") {
                        return (
                            <ArticlePresenter
                                article={it}
                                key={it._frontendKey}
                            />
                        )
                    }
                })
            }
        </div>
        <div className="col-sm-1"></div>
    </div>
}

const loadCarrierOptions = {
    "EURO": <FormattedMessage id="transportOrder.shipment.cargo.qty.unit.euro"/>,
    "MESH_BOX": <FormattedMessage id="transportOrder.shipment.cargo.qty.unit.meshbox"/>,
    "PALLET": <FormattedMessage id="transportOrder.shipment.cargo.qty.unit.pallet"/>
}

interface LoadCarrierPresenterProps {
    selected?: string
}

const LoadCarrierPresenter = (props: LoadCarrierPresenterProps): ReactElement => {
    const selected = props.selected ?? "EURO"
    return (
        <>
            <label className="control-label">
                <FormattedMessage id="transportOrder.shipment.cargo.handlingUnit"/>
            </label>
            <ReadOnlyDetailViewFormValue value={loadCarrierOptions[selected] ?? selected} />
        </>
    )
}
