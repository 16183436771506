import { ConfigCatProvider, createConsoleLogger, DataGovernance, LogLevel, PollingMode } from "configcat-react"
import { PropsWithChildren } from "react"
import { config } from "../app/app-config"

export const FeatureToggleProvider = (props: PropsWithChildren) =>
    <ConfigCatProvider
        sdkKey={config.configCat.sdkKey}
        pollingMode={PollingMode.LazyLoad}
        options={{
            cacheTimeToLiveSeconds: 600,
            logger: createConsoleLogger(LogLevel.Off),
            dataGovernance: DataGovernance.EuOnly,
        }}
    >
        {props.children}
    </ConfigCatProvider>
