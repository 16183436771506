import { Shipment, TransportOrder } from "../model/transportorder/transportOrder"
import { ISuccessFetchTransportOrderBundlesPayload } from "../actions/fetchTransportOrderBundles"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getEarliestLoadingTimeWindow } from "../components/TransportOrder/TransportOrderTable/TransportOrderTableRow"
import queryString from "query-string"

export enum SortDirection {
    ASC = "asc",
    DESC = "desc"
}

export enum SortBy {
    CREATED_AT = "CREATED_AT",
    EARLIEST_LOADING_TIME_FROM = "EARLIEST_LOADING_TIME_FROM",
    CUSTOMER_NAME = "CUSTOMER_NAME"
}

export interface SortCriteria {
    sortBy: SortBy
    sortDirection: SortDirection
}

interface TransportOrderWithKeywords {
    transportOrder: TransportOrder
    keywords: string[]
}

interface TransportOrderTableParameters {
    searchPhrase: string | undefined
    sortBy: SortBy
    sortDirection: SortDirection
    orderDateFrom: string | undefined
    orderDateTo: string | undefined
}

export const queryStringToSortDirectionMapping = new Map()
queryStringToSortDirectionMapping.set("asc", SortDirection.ASC)
queryStringToSortDirectionMapping.set("desc", SortDirection.DESC)

const mapQueryStringToSortDirection = (sortDirAsString: string | undefined): SortDirection => queryStringToSortDirectionMapping.get(sortDirAsString) || SortDirection.ASC

const getSortKey = (sortBy: SortBy, transportOrder: TransportOrder): string | undefined => {
    switch (sortBy) {
    case SortBy.CREATED_AT:
        return transportOrder.created_at
    case SortBy.EARLIEST_LOADING_TIME_FROM:
        return getEarliestLoadingTimeWindow(transportOrder)
    case SortBy.CUSTOMER_NAME:
        return transportOrder.customer_data?.name
    }
}

const queryStringToSortByMapping = (): Map<string | undefined, SortBy> => {
    const mapping = new Map()
    mapping.set("date", SortBy.CREATED_AT)
    mapping.set("loadingDate", SortBy.EARLIEST_LOADING_TIME_FROM)
    mapping.set("customer", SortBy.CUSTOMER_NAME)
    return mapping
}

const sortByToQueryStringMapping = (): Map<SortBy | undefined, string> => {
    const mapping = new Map()
    queryStringToSortByMapping().forEach((value, key) => {
        mapping.set(value, key)
    })
    return mapping
}

const mapQueryStringToSortBy = (sortByAsString: string | undefined): SortBy => queryStringToSortByMapping().get(sortByAsString) || SortBy.EARLIEST_LOADING_TIME_FROM

export const mapSortByToQueryString = (sortBy: SortBy | undefined): string | undefined => sortByToQueryStringMapping().get(sortBy) || ""

export const sortTransportOrders = (transportOrders: TransportOrder[],
    sortCriteria: SortCriteria | undefined): TransportOrder[] => {
    if (!sortCriteria) {
        return transportOrders
    }
    return doSorting(transportOrders, sortCriteria)
}

function doSorting(transportOrders: TransportOrder[], criteria: SortCriteria): TransportOrder[] {
    const orders = [...transportOrders]
    orders.sort((item1, item2) => {
        const sortKey1 = getSortKey(criteria.sortBy, item1)
        const sortKey2 = getSortKey(criteria.sortBy, item2)
        if (sortKey1 === undefined && sortKey2 === undefined) {
            return 0
        } else if (sortKey1 === undefined) {
            return criteria.sortDirection === SortDirection.ASC ? 1 : -1
        } else if (sortKey2 === undefined) {
            return criteria.sortDirection === SortDirection.ASC ? -1 : 1
        } else {
            const number = sortKey1.localeCompare(sortKey2)
            return criteria.sortDirection === SortDirection.ASC ? number : -number
        }
    })
    return orders
}

const keywordsFromShipments = (shipments: Shipment[] | undefined): string[] => {
    if (shipments) {
        return shipments
            .flatMap(shipment => [shipment.loading_address, shipment.unloading_address])
            .flatMap(loadingAddress => {
                const loadingAddressKeywords: string[] = [loadingAddress.name]
                if (loadingAddress.address) {
                    loadingAddressKeywords.push(loadingAddress.address.city, loadingAddress.address.street)
                }
                return loadingAddressKeywords
            })
    }
    return []
}
const withKeywords = (transportOrder: TransportOrder): TransportOrderWithKeywords => ({
    transportOrder,
    keywords: [transportOrder.customer_data?.name, ...keywordsFromShipments(transportOrder._embedded?.shipments)]
        .filter((it): it is string => it !== undefined)
})
// See https://stackoverflow.com/questions/4031900/split-a-string-by-whitespace-keeping-quoted-segments-allowing-escaped-quotes
export const requiredKeywordsFromSearchString = (searchString: string): string[] => {
    const tokens = searchString.match(/[^"\s]+|"(?:[^"])+"/g) || []
    return tokens.map(token => token.replace(/["]+/g, ""))
}
export const searchInTransportOrders = (transportOrders: TransportOrder[], searchString: string | undefined): TransportOrder[] => {
    if (!searchString || searchString === "") {
        return transportOrders
    }

    const requiredKeywords = requiredKeywordsFromSearchString(searchString)

    return transportOrders.map(withKeywords)
        .filter(transportOrderWithKeywords => {
            return requiredKeywords.every(requiredKeyword => {
                return transportOrderWithKeywords.keywords.some(
                    keyword => keyword.toLowerCase().indexOf(requiredKeyword.toLowerCase()) >= 0
                )
            })
        })
        .map(transportOrderWithKeywords => transportOrderWithKeywords.transportOrder)
}

export const extractTableParamsFromUrl = (locationSearch: string): TransportOrderTableParameters => {
    const urlQueryParams = queryString.parse(locationSearch.replace(/^\?/, ""), { decode: true })
    const searchPhrase = Array.isArray(urlQueryParams["searchPhrase"]) ?
        urlQueryParams["searchPhrase"]?.join(" ") : urlQueryParams["searchPhrase"]
    const sortBy = Array.isArray(urlQueryParams["sortBy"]) ?
        urlQueryParams["sortBy"]?.[0] : urlQueryParams["sortBy"]
    const sortDir = Array.isArray(urlQueryParams["sortDir"]) ?
        urlQueryParams["sortDir"]?.[0] : urlQueryParams["sortDir"]
    const orderDateFrom = Array.isArray(urlQueryParams["orderDateFrom"]) ?
        urlQueryParams["orderDateFrom"]?.[0] : urlQueryParams["orderDateFrom"]
    const orderDateTo = Array.isArray(urlQueryParams["orderDateTo"]) ?
        urlQueryParams["orderDateTo"]?.[0] : urlQueryParams["orderDateTo"]
    return {
        searchPhrase: searchPhrase || undefined,
        sortBy: mapQueryStringToSortBy(sortBy  || undefined),
        sortDirection: mapQueryStringToSortDirection(sortDir  || undefined),
        orderDateFrom: orderDateFrom || undefined,
        orderDateTo: orderDateTo || undefined,
    }
}

export interface TransportOrderState {
    transportOrders: TransportOrder[]
    overviewTransportOrders: TransportOrder[]
    failedFetchTransportOrderId?: string
    searchPhrase: string | undefined
    sortCriteria: SortCriteria | undefined
}

export const initialState: TransportOrderState = {
    transportOrders: [],
    overviewTransportOrders: [],
    failedFetchTransportOrderId: undefined,
    searchPhrase: undefined,
    sortCriteria: undefined,
}

export const transportOrderSlice = createSlice({
    name: "transportOrder",
    initialState,
    reducers: {
        setTransportOrderBundles: (state, action: PayloadAction<ISuccessFetchTransportOrderBundlesPayload>) => {
            state.transportOrders = action.payload.items
            state.failedFetchTransportOrderId = undefined
            state.overviewTransportOrders = sortTransportOrders(
                searchInTransportOrders(
                    state.transportOrders,
                    state.searchPhrase),
                state.sortCriteria)
        },
        setTransportOrderBundle: (state, action: PayloadAction<TransportOrder>) => {
            const index = state.transportOrders.findIndex(to => to.id === action.payload.id)
            if (index < 0) {
                state.transportOrders.push(action.payload)
            } else {
                state.transportOrders[index] = action.payload
            }
            state.failedFetchTransportOrderId = undefined
        },
        setTransportOrderBundleFetchError: (state, action: PayloadAction<{ transportOrderId: string }>) => {
            state.failedFetchTransportOrderId = action.payload.transportOrderId
        },
        sortTransportOrderBundles: (state, action: PayloadAction<SortCriteria | undefined>) => {
            state.sortCriteria = action.payload
            state.overviewTransportOrders = sortTransportOrders(
                searchInTransportOrders(
                    state.transportOrders,
                    state.searchPhrase),
                state.sortCriteria)
        },
        searchTransportOrderBundles: (state, action: PayloadAction<string | undefined>) => {
            state.searchPhrase = action.payload
            state.overviewTransportOrders = sortTransportOrders(
                searchInTransportOrders(
                    state.transportOrders,
                    state.searchPhrase),
                state.sortCriteria)
        },
    },
})
