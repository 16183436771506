import AsnMatchingClient from "../client/asn-matching/AsnMatchingClient"
import {
    LoadingTimeWindow,
    MainRunCarrier,
    MainRunData,
    MainRunProblems,
    MainRunViolation
} from "../model/mainrun/mainRunData"
import {
    ExternalLoadingTimeWindow,
    ExternalMainRunCarrier,
    ExternalMainRunData,
    ExternalMainRunProblems, ExternalMainRunViolation
} from "../client/asn-matching/model"

export const saveMainRunData = async (mainRunData: MainRunData[]): Promise<MainRunData[]> => {
    const externalMainRunData = mainRunData.map(mainRun => toExternalMainRun(mainRun))
    return await AsnMatchingClient.saveMainRunData(externalMainRunData)
        .then(toMainRuns)
        .catch(problems => {
            if (problems.main_run_violations !== undefined) {
                return Promise.reject(toMainRunProblems(problems))
            } else {
                return Promise.reject(problems)
            }
        })
}

export const loadMainRunData = async (): Promise<MainRunData[]> => {
    return await AsnMatchingClient.loadMainRunData()
        .then(toMainRuns)
}

const toMainRuns = (resp: { items: ExternalMainRunData[] }): MainRunData[] => {
    return resp.items.map(externalMainRun => toMainRun(externalMainRun))
}

const toExternalMainRun = (mainRun: MainRunData): ExternalMainRunData => {
    return {
        asn_tracking_number: mainRun.asnTrackingNumber,
        cross_dock_id: mainRun.crossDockId,
        supplier_duns: mainRun.supplierDuns,
        loading_time_window: toExternalLoadingTimeWindow(mainRun.loadingTimeWindow),
        main_run_carrier: toExternalMainRunCarrier(mainRun.mainRunCarrier),
    }
}

const toExternalLoadingTimeWindow = (loadingTimeWindow: LoadingTimeWindow): ExternalLoadingTimeWindow => {
    return {
        time_from: loadingTimeWindow.timeFrom,
        time_to: loadingTimeWindow.timeTo,
    }
}

const toExternalMainRunCarrier = (mainRunCarrier: MainRunCarrier): ExternalMainRunCarrier => {
    if (mainRunCarrier.type === "SELF") {
        return {
            type: "SELF",
            load_id: mainRunCarrier.loadId,
            asset_id: mainRunCarrier.assetId,
            access_code: mainRunCarrier.accessCode,
        }
    } else {
        return {
            type: "THIRD_PARTY",
            bundle_id: mainRunCarrier.bundleId,
        }
    }
}

const toMainRun = (externalMainRun: ExternalMainRunData): MainRunData => {
    return {
        asnTrackingNumber: externalMainRun.asn_tracking_number,
        crossDockId: externalMainRun.cross_dock_id,
        supplierDuns: externalMainRun.supplier_duns,
        loadingTimeWindow: toLoadingTimeWindow(externalMainRun.loading_time_window),
        mainRunCarrier: toMainRunCarrier(externalMainRun.main_run_carrier),
    }
}

const toLoadingTimeWindow = (externalLoadingTimeWindow: ExternalLoadingTimeWindow): LoadingTimeWindow => {
    return {
        timeFrom: externalLoadingTimeWindow.time_from,
        timeTo: externalLoadingTimeWindow.time_to,
    }
}

const toMainRunCarrier = (externalMainRunCarrier: ExternalMainRunCarrier): MainRunCarrier => {
    if (externalMainRunCarrier.type === "SELF") {
        return {
            type: "SELF",
            loadId: externalMainRunCarrier.load_id,
            assetId: externalMainRunCarrier.asset_id,
            accessCode: externalMainRunCarrier.access_code,
        }
    } else {
        return {
            type: "THIRD_PARTY",
            bundleId: externalMainRunCarrier.bundle_id,
        }
    }
}

const toMainRunProblems = (externalMainRunProblems: ExternalMainRunProblems): MainRunProblems => {
    return {
        violations: externalMainRunProblems.main_run_violations.map(toMainRunDataViolation),
    }
}

const toMainRunDataViolation = (externalMainRunDataViolation: ExternalMainRunViolation): MainRunViolation => {
    return {
        index: externalMainRunDataViolation.index,
        errors: externalMainRunDataViolation.errors,
    }
}
