import { config } from "./configuration/app/app-config"

const enableServiceWorkerMock = async (): Promise<void> => {
    if (process.env.NODE_ENV === "development" && config.useApiMocks) {
        const { worker } = await import("./mocks/browser")
        await worker.start()
        return Promise.resolve()
    } else {
        return Promise.resolve()
    }
}
export { enableServiceWorkerMock }
