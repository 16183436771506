import { ReactElement, ReactNode, useEffect, useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"
import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout"
import {
    useGetNotificationEmailQuery,
    useAddNotificationEmailMutation,
    useDeleteNotificationEmailMutation,
} from "../../client/notification-settings/notificationSettingsApi"
import Notification from "@rio-cloud/rio-uikit/lib/es/Notification"
import { isEmpty } from "lodash"
import RadioButton from "@rio-cloud/rio-uikit/RadioButton"
import ClearableInput from "@rio-cloud/rio-uikit/ClearableInput"
import Spinner from "@rio-cloud/rio-uikit/Spinner"
import ErrorState from "@rio-cloud/rio-uikit/ErrorState"

export const NotificationSettings = (): ReactElement => {
    const notificationEmailQuery = useGetNotificationEmailQuery()
    const [addNotificationEmailMutation, addNotificationEmailMutationState] = useAddNotificationEmailMutation()
    const [deleteNotificationEmailMutation, deleteNotificationEmailMutationState] = useDeleteNotificationEmailMutation()

    const [notificationEmail, setNotificationEmail] = useState<string>()
    const [sendNotifications, setSendNotifications] = useState<boolean>(false)

    const isLoading = addNotificationEmailMutationState.isLoading ||
        deleteNotificationEmailMutationState.isLoading ||
        notificationEmailQuery.isLoading

    useEffect(() => {
        if (notificationEmailQuery.isSuccess) {
            setNotificationEmail(notificationEmailQuery.data.email)
            setSendNotifications(!isEmpty(notificationEmailQuery.data.email))
        }
    }, [notificationEmailQuery])

    return (
        <ApplicationLayout.Body>
            {!notificationEmailQuery.isError && <div className="container fluid-default panel panel-default margin-y-25 position-relative">
                <div className="panel-body padding-20">
                    <EmailSettings
                        deleteNotificationEmailMutation={deleteNotificationEmailMutation}
                        sendNotifications={sendNotifications}
                        setSendNotifications={setSendNotifications}/>
                    <hr/>
                    <EmailInput
                        addNotificationEmailMutation={addNotificationEmailMutation}
                        notificationEmail={notificationEmail}
                        sendNotifications={sendNotifications}
                        setNotificationEmail={setNotificationEmail}/>
                </div>
                <Spinner show={isLoading} isFullSized />
            </div>}
            {notificationEmailQuery.isError &&
            <ErrorState
                headline={<FormattedMessage id={"application.error.state.headline"}/>}
                message={<FormattedMessage id={"application.error.state.message"}/>}
            />}
        </ApplicationLayout.Body>)
}
const EmailInput = (props: {
    notificationEmail?: string
    sendNotifications: boolean
    setNotificationEmail: Function
    addNotificationEmailMutation: Function
}) => {
    const regex = new RegExp("^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})$")
    const { notificationEmail, setNotificationEmail } = props

    const isEmailAddressPatternValid = (inputValue?: string) => !isEmpty(inputValue?.match(regex))
    const isEmailAddressEmptyOrValid = isEmpty(notificationEmail) || isEmailAddressPatternValid(notificationEmail)

    const saveNotificationEmail = (inputValue: string) => {
        if (!isEmailAddressPatternValid(inputValue)) {
            return
        }

        props.addNotificationEmailMutation({ email: inputValue })
            .unwrap()
            .then(() => {
                Notification.success(
                    <div>
                        <FormattedMessage id={"notification.settings.email.update.success"}/>
                    </div>
                )
            })
            .catch(() => {
                Notification.error(<FormattedMessage id={"notification.settings.email.update.error"}/>)
            })
    }

    return (
        <div className="display-flex flex-row-md flex-column align-items-start justify-content-start ">
            <div className="text-size-h5 min-width-250 min-width-350-lg margin-right-20 margin-bottom-25">
                <FormattedMessage id={"notification.setting.email"}/>
            </div>
            <div
                className="display-flex flex-column flex-1-0-md margin-left-20 margin-right-25 min-width-100 text-size-16">
                <span className="text-size-16 padding-top-5 padding-bottom-20">
                    <FormattedMessage id={"notification.setting.email.title"}/>
                </span>
                <div>
                    <div className={"padding-left-15"}>
                        <SaveableEmailInput
                            hasErrors={!isEmailAddressEmptyOrValid}
                            isValid={isEmailAddressPatternValid(notificationEmail)}
                            value={notificationEmail}
                            disabled={!props.sendNotifications}
                            onSave={saveNotificationEmail}
                            onChange={setNotificationEmail}
                        />
                        <div className={"width-350"}>
                            <label className="text-size-12 padding-top-5 padding-bottom-20">
                                <FormattedMessage id={"notification.setting.email.hint"}
                                    values={{
                                        b: (chunks: ReactNode) => <b>{chunks}</b>,
                                    }}
                                />
                            </label>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}
const EmailSettings = (props: {
    sendNotifications: boolean
    setSendNotifications: Function
    deleteNotificationEmailMutation: Function
}) => {

    const toggleSendEmailNotifications = (value: boolean) => {
        props.setSendNotifications(value)
        if (!value) {
            onDeleteNotificationEmailAction()
        }
    }

    const onDeleteNotificationEmailAction = () => {
        props.deleteNotificationEmailMutation()
            .unwrap()
            .then(() => {
                Notification.success(
                    <div>
                        <FormattedMessage id={"notification.settings.email.delete.success"}/>
                    </div>
                )
            })
            .catch(() => {
                Notification.error(<FormattedMessage id={"notification.settings.email.delete.error"}/>)
            })
    }


    return (
        <div className="display-flex flex-row-md flex-column align-items-start justify-content-start ">
            <div
                className="text-size-h5 min-width-250 min-width-350-lg margin-right-20 margin-bottom-25">
                <FormattedMessage id={"notification.settings.send.config"}/>
            </div>
            <div
                className="display-flex flex-column margin-left-20 margin-right-25 min-width-100 flex-1-0-md text-size-16">
                <span className="text-size-16 padding-top-5 padding-bottom-20">
                    <FormattedMessage id={"notification.settings.send.config.title"}/>
                </span>
                <div className="text-size-16 padding-top-5 padding-bottom-20 padding-left-15">
                    <RadioButton
                        onChange={() => toggleSendEmailNotifications(true)}
                        checked={props.sendNotifications}
                        inline>
                        <FormattedMessage id={"notification.settings.send.option.yes"}/>
                    </RadioButton>

                    <RadioButton
                        onChange={() => toggleSendEmailNotifications(false)}
                        checked={!props.sendNotifications}
                        inline>
                        <FormattedMessage id={"notification.settings.send.option.no"}/>
                    </RadioButton>
                </div>
            </div>
        </div>
    )
}

type SaveableEmailInputProps = {
    hasErrors: boolean
    isValid: boolean
    value?: string
    disabled: boolean
    onChange?: Function
    onSave?: Function
}
export const SaveableEmailInput = (props: SaveableEmailInputProps) => {
    const { hasErrors, disabled, isValid, onChange, value, onSave } = props
    const [editMode, setEditMode] = useState<boolean>(false)
    const buttonIconClasses = editMode ? "rioglyph-ok" : "rioglyph-pencil"
    const toggleButton = () => {
        const updatedEditMode = !editMode
        if(!updatedEditMode && value) {
            if(!hasErrors) {
                setEditMode(updatedEditMode)
                onSave?.(value)
            }
        } else {
            setEditMode(true)
        }
    }

    const onBlur = () => {
        if(editMode && !value) {
            setEditMode(false)
        }
    }

    useMemo(() => {
        if(disabled) {
            setEditMode(false)
        }
    }, [disabled])

    return (
        <div data-testid={"SAVEABLE_EMAIL_INPUT"}
            className={`form-group width-350 ${!hasErrors ? "" : "has-feedback has-error padding-bottom-10"}`}>
            <div className='input-group'>
                <ClearableInput
                    hasError={hasErrors}
                    disabled={!editMode || disabled}
                    value={value}
                    onBlur={onBlur}
                    onKeyPress={(key) => (key.key === "Enter") && toggleButton()}
                    onChange={value => onChange?.(value)}
                />
                { hasErrors && editMode &&
                    <span
                        style={{ marginRight: 30 }} className='form-control-feedback rioglyph rioglyph-error-sign'
                        aria-hidden='true'/>
                }
                <span className='input-group-btn'>
                    <button className='btn btn-primary btn-icon-only' type={"button"}
                        onClick={toggleButton}
                        disabled={disabled || (editMode && !isValid)}>
                        <span className={`rioglyph ${buttonIconClasses}`}/>
                    </button>
                </span>
            </div>
            {hasErrors && <span className='help-block'>
                <span><FormattedMessage id="notification.settings.email.invalid"/></span>
            </span>}
        </div>
    )
}