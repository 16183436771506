import {
    Article,
    BulkFreight,
    CargoFreight,
    DangerousGoodsInformation,
    HandlingUnit,
    Packable,
    Shipment,
    ShipmentStatus,
    TransportOrder
} from "./transportOrder"

export function hasDangerousGoods(transportOrder: TransportOrder): boolean {
    const shipments = transportOrder._embedded?.shipments.filter(
        (shipment) => shipment.status !== ShipmentStatus.CANCELLED
    )
    if (!shipments) {
        return false
    }
    return shipments.some(shipmentHasDangerousGoods)
}

function shipmentHasDangerousGoods(shipment: Shipment): boolean {
    switch (shipment.freight.type) {
    case "BULK_DRY":
    case "BULK_LIQUID":
        return bulkFreightHasDangerousGoods(shipment.freight as BulkFreight)
    case "CARGO":
        return cargoFreightHasDangerousGoods(shipment.freight as CargoFreight)
    }
}

function bulkFreightHasDangerousGoods(freight: BulkFreight): boolean {
    return isDangerous(freight.dangerous_goods_information)
}

function cargoFreightHasDangerousGoods(freight: CargoFreight): boolean {
    return freight.handling_units.some(handlingUnitContainsDangerousGoods)
}

function handlingUnitContainsDangerousGoods(handlingUnit: HandlingUnit): boolean {
    return handlingUnit.content.some(packableContainsDangerousGoods)
}

function packableContainsDangerousGoods(packable: Packable): boolean {
    switch (packable.type) {
    case "ARTICLE":
        return articleIsDangerousGood(packable)
    case "HANDLING_UNIT": {
        return packable.content.some(packableContainsDangerousGoods)
    }}
}

export function articleIsDangerousGood(article: Article): boolean {
    return isDangerous(article.dangerous_goods_information)
}

function hasNonBlankUndgCode(dangerousGoodsInformation: DangerousGoodsInformation): boolean {
    return !!dangerousGoodsInformation.undg_code?.trim()?.length
}

// Concerning classes see https://en.wikipedia.org/wiki/Dangerous_goods#Classification_and_labeling_summary_tables
// For undg code see https://en.wikipedia.org/wiki/Lists_of_UN_numbers
export function isDangerous(dangerousGoodsInformation: DangerousGoodsInformation | undefined): boolean {
    if (dangerousGoodsInformation === undefined) {
        return false
    }
    return hasNonBlankUndgCode(dangerousGoodsInformation)
}
