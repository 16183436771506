import ButtonDropdown from "@rio-cloud/rio-uikit/ButtonDropdown"
import { MainRunUploadDialog, mainRunUploadMenuItem } from "./MainRunUploadMenuItem"
import { mainRunDownloadMenuItem } from "./MainRunDownloadMenuItem"
import * as React from "react"
import { useState } from "react"
import { useCrossDocksOfAccount } from "../../hooks/crossDocks"
import { useAssetsOfAccount } from "../../hooks/assets"
import { FormattedMessage } from "react-intl"

export const MainRunButtonDropdown = (): React.ReactElement | null => {
    const [isLoading, setIsLoading] = useState(false)
    const [showUploadDialog, setShowUploadDialog] = useState(false)

    const crossDocks = useCrossDocksOfAccount().crossDocks
    const assets = useAssetsOfAccount()

    if(crossDocks.length === 0) {
        return null
    }

    return <>
        <ButtonDropdown
            disabled={isLoading}
            title={<>
                <span className="rioglyph rioglyph-document-out margin-right-5"/>
                <FormattedMessage id={"mainRun.dropdown.title"}/>
            </>}
            items={[
                mainRunUploadMenuItem(() => setShowUploadDialog(true)),
                mainRunDownloadMenuItem(assets, crossDocks, setIsLoading),
            ]}
        />
        <MainRunUploadDialog 
            assets={assets} 
            crossDocks={crossDocks} 
            showDialog={showUploadDialog} 
            closeDialog={(): void => setShowUploadDialog(false)}
        />
    </>
}