import {
    ShipmentPriority,
    TransportOrder,
    TransportOrderStatus,
    TransportOrderType,
    VehicleType
} from "./transportOrder"

export const isTransportOrderCancelled = (transportOrder: TransportOrder): boolean => {
    return transportOrder.status === TransportOrderStatus.CANCELLED
}
const isMaterialTransportOrder =  (transportOrder: TransportOrder): boolean =>
    transportOrder.orderType === TransportOrderType.MATERIAL
export const getPrioritiesOfTransportOrder = (transportOrder: TransportOrder): ShipmentPriority[] =>
    transportOrder._embedded?.shipments.map(it => it.priority) ?? []
const isUrgentTransportOrder = (transportOrder: TransportOrder): boolean =>
    getPrioritiesOfTransportOrder(transportOrder).includes(ShipmentPriority.URGENT)
const isRegularTransportOrder = (transportOrder: TransportOrder): boolean =>
    getPrioritiesOfTransportOrder(transportOrder).every(it => it === ShipmentPriority.REGULAR)
export const doesTransportOrderSupportLoadingTimeWindow = (transportOrder: TransportOrder): boolean =>
    isMaterialTransportOrder(transportOrder) && isRegularTransportOrder(transportOrder)
export const doesTransportOrderSupportUnloadingTimeWindow = (transportOrder: TransportOrder): boolean => isUrgentTransportOrder(transportOrder)
export const doesTransportOrderSupportDriverPhoneNumber = (transportOrder: TransportOrder): boolean => isUrgentTransportOrder(transportOrder)
export const doesTransportOrderSupportVehicleType = (transportOrder: TransportOrder): boolean => isUrgentTransportOrder(transportOrder)
export const doesTransportOrderSupportTwoDrivers = (transportOrder: TransportOrder): boolean => isUrgentTransportOrder(transportOrder)

export const getAllPossibleVehicleTypes = (): VehicleType[] => {
    return ["PASSENGER_CAR", "VAN", "LIGHT_TRUCK", "MEDIUM_TRUCK"]
}

export const canTransportOrderBeAccepted = (transportOrder: TransportOrder): boolean => {
    return !isTransportOrderCancelled(transportOrder)
}

export const isAnyShipmentConfirmed = (transportOrder: TransportOrder): boolean => {
    return transportOrder._embedded?.shipments.some(it => it.isConfirmed) ?? false
}
