export const CountryToTimezone = new Map([
    ["DE", "Europe/Berlin"],
    ["AT", "Europe/Vienna"],
    ["CH", "Europe/Zurich"],
    ["AL", "Europe/Tirane"],
    ["AD", "Europe/Andorra"],
    ["BY", "Europe/Minsk"],
    ["BE", "Europe/Brussels"],
    ["BA", "Europe/Sarajevo"],
    ["BG", "Europe/Sofia"],
    ["HR", "Europe/Zagreb"],
    ["CZ", "Europe/Prague"],
    ["DK", "Europe/Copenhagen"],
    ["EE", "Europe/Tallinn"],
    ["ES", "Europe/Madrid"],
    ["FO", "Atlantic/Faroe"],
    ["FI", "Europe/Helsinki"],
    ["FR", "Europe/Paris"],
    ["GI", "Europe/Gibraltar"],
    ["GR", "Europe/Athens"],
    ["HU", "Europe/Budapest"],
    ["IS", "Atlantic/Reykjavik"],
    ["IE", "Europe/Dublin"],
    ["IM", "Europe/Isle_of_Man"],
    ["IT", "Europe/Rome"],
    ["LV", "Europe/Riga"],
    ["LI", "Europe/Vaduz"],
    ["LT", "Europe/Vilnius"],
    ["LU", "Europe/Luxembourg"],
    ["MK", "Europe/Skopje"],
    ["MT", "Europe/Malta"],
    ["MD", "Europe/Chisinau"],
    ["MC", "Europe/Monaco"],
    ["ME", "Europe/Podgorica"],
    ["NL", "Europe/Amsterdam"],
    ["NO", "Europe/Oslo"],
    ["PL", "Europe/Warsaw"],
    ["PT", "Europe/Lisbon"],
    ["RO", "Europe/Bucharest"],
    ["RS", "Europe/Belgrade"],
    ["RU", "Europe/Moscow"],
    ["SE", "Europe/Stockholm"],
    ["SI", "Europe/Ljubljana"],
    ["SK", "Europe/Bratislava"],
    ["SM", "Europe/San_Marino"],
    ["TN", "Africa/Tunis"],
    ["TR", "Europe/Istanbul"],
    ["UA", "Europe/Kiev"],
    ["GB", "Europe/London"],
    ["VA", "Europe/Vatican"],
])
