import { ExternalTimeWindow } from "./model"

export type ExternalFreightForwarderResponse = ExternalFreightForwarderConfirmation

export interface ExternalFreightForwarderConfirmation {
    type: "CONFIRMATION"
    load_id?: string
    means_of_transport?: ExternalMeansOfTransport
    billing_information?: ExternalBillingInformation
    loading?: ExternalLoading
    unloading?: ExternalUnloading
    driver?: ExternalDriverContact
    created_at?: string
    last_modified_at?: string
    cross_dock?: ExternalCrossDock
}

export interface ExternalCrossDock {
    id: string
}

export type ExternalMeansOfTransport = {
    mode: "ROAD" | "RAIL" | "WATER" | "AIR" | "MAIL"
    asset_id?: string
    license_plate?: string
    country_code?: string
}

export enum ExternalVehicleType {
    PASSENGER_CAR = "PASSENGER_CAR",
    VAN = "VAN",
    LIGHT_TRUCK = "LIGHT_TRUCK",
    MEDIUM_TRUCK = "MEDIUM_TRUCK",
}

export interface ExternalBillingInformation {
    vehicle_type?: ExternalVehicleType
    two_drivers?: boolean
}

export interface ExternalLoading {
    access_code?: string
    time_window?: ExternalTimeWindow
}

export type ExternalUnloading = ExternalLoading

export interface ExternalDriverContact {
    phone_number?: string
}
