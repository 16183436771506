import React, { ReactNode, useEffect, useState } from "react"
import AutoSuggest, { AutoSuggestSuggestion } from "@rio-cloud/rio-uikit/AutoSuggest"

import { Asset } from "../../model/asset/asset"
import MfhClient from "../../client/mfh/MfhClient"
import { FormGroupWrapper } from "../Form/FormGroupWrapper"
import { FormattedMessage } from "react-intl"

interface Props {
    assetId?: string
    label?: ReactNode
    onAssetSelected: (asset?: Asset) => void
    className?: string
    disabled?: boolean
}

type AssetSuggestions = Array<{ label: string, assetId: string, assetType: string }>

const suggestionsFor = (assets: Asset[], query?: string): AssetSuggestions => assets
    .filter(asset => query ? asset.name.toLowerCase().includes(query.toLowerCase()) : true)
    .map(asset => ({ label: asset.name, assetId: asset.id, assetType: asset.type }))
    .sort((a, b) => a.label.localeCompare(b.label))

export const AssetSelect = (props: Props): React.ReactElement => {
    const [assets, setAssets] = useState<Asset[]>([])
    const [suggestions, setSuggestions] = useState<AssetSuggestions>([])
    const [selected, setSelected] = useState<Asset | undefined>(undefined)
    const [searchValue, setSearchValue] = useState<string | undefined>()
    const [displayValue, setDisplayValue] = useState<string | undefined>(props.assetId)
    const [displayIcon, setDisplayIcon] = useState<string>("rioglyph-truck")
    const hasError = selected === undefined && searchValue !== undefined && searchValue.length > 0

    const mfhClient = MfhClient

    useEffect((): void => {
        mfhClient.getAssets()
            .then(assets => assets.sort((a, b) => a.name.localeCompare(b.name)))
            .then(assets => {
                setAssets(assets)
                setSuggestions(suggestionsFor(assets))
                const asset = assets.find(it => it.id === props.assetId)
                setSelected(asset)
                setDisplayAndSearchValues(asset?.name)
                setDisplayIcon(iconClass(asset?.type))
            })
            .catch(() => {
                setAssets([])
                setSuggestions(suggestionsFor([]))
            })
    }, [mfhClient, props.assetId])

    const setDisplayAndSearchValues = (value?: string) => {
        setDisplayValue(value)
        setSearchValue(value)
    }
    const autoSuggest = <AutoSuggest
        inputProps={{
            onClear: (): void => {
                setDisplayAndSearchValues(undefined)
                setDisplayIcon("rioglyph-truck")
                setSuggestions(suggestionsFor(assets))
                setSelected(undefined)
                props.onAssetSelected(undefined)
            },
            value: displayValue,
            icon: displayIcon,
            onBlur: (): void => setDisplayValue(selected?.name),
            disabled: props.disabled
        }}
        suggestions={suggestions}
        noItemMessage={<FormattedMessage id={"transportOrder.suggestions.no.asset.available"}/>}
        onSuggestionsFetchRequested={({ value }): void => {
            setSuggestions(suggestionsFor(assets, value))
            setDisplayAndSearchValues(value)
        }}
        onSuggestionSelected={(_, selectedSuggestion): void => {
            const asset = assets.find(it => it.id === selectedSuggestion.suggestion.assetId)
            if (asset === undefined) {
                return
            }
            setDisplayAndSearchValues(selectedSuggestion.suggestion.label)
            setDisplayIcon(iconClass(asset.type))
            setSelected(asset)
            props.onAssetSelected(asset)
        }}
        renderSuggestion={(autoSuggestion: AutoSuggestSuggestion): React.ReactElement =>
            <div><IconForAssetType assetType={autoSuggestion.assetType as string} className={"padding-right-5"}/>{autoSuggestion.label}</div>}
    />

    return <div className={props.className} data-testid={"asset-select"}>
        <FormGroupWrapper
            label={props.label}
            component={autoSuggest}
            hasError={hasError}
            hint={<FormattedMessage id={"transportOrder.confirmation.no.asset.selected"}/>}
        />
    </div>
}

const IconForAssetType = (props: { assetType: string, className: string }): React.ReactElement =>
    <span className={`rioglyph ${iconClass(props.assetType)} ${props.className}`}/>

const iconClass = (assetType?: string): string => {
    switch (assetType?.toLowerCase()) {
    case "bus":
        return "rioglyph-bus"
    case "trailer":
        return "rioglyph-trailer"
    case "truck":
        return "rioglyph-truck"
    case "van":
        return "rioglyph-van"
    default:
        return "rioglyph-truck"
    }
}
