import * as React from "react"
import { ReactElement } from "react"
import { FormattedMessage } from "react-intl"
import { ValidationContextState } from "components/Form/validation/VaildationContext"
import Button from "@rio-cloud/rio-uikit/Button"
import { ConfirmationInformationPopup } from "../Application/ConfirmationInformationPopup"
import { Confirmation } from "../../model/confirmation/Confirmation"
import { TransportOrder } from "../../model/transportorder/transportOrder"

interface ButtonProps {
    show: boolean
    enabled?: boolean
    onClick: () => void
}

type AcceptButtonProps = ButtonProps & {
    validationContext: ValidationContextState
    transportOrder: TransportOrder | undefined
    confirmations: Array<Confirmation> | undefined
}

export interface IStickyFooterProps {
    acceptButtonProps: AcceptButtonProps
    backButtonProps: ButtonProps
    printButtonProps: ButtonProps
}

export const StickyFooter = (props: IStickyFooterProps): ReactElement => {
    return (
        <React.Fragment>
            <BackButton {...props.backButtonProps} />
            <div className={"btn-toolbar"}>
                <PrintButton {...props.printButtonProps} />
                <AcceptButton {...props.acceptButtonProps} />
            </div>
        </React.Fragment>
    )
}

const BackButton = (props: ButtonProps): ReactElement => {
    return (
        <Button onClick={props.onClick}
            className="btn-default btn-outline"
            data-testid="TransportOrderFrameFooter_BackButton">
            <FormattedMessage id="transportOrder.overview.back"/>
        </Button>
    )
}


export const AcceptButton = (props: AcceptButtonProps): ReactElement => {
    const ctx = props.validationContext

    if (!props.show) {
        return <React.Fragment/>
    }

    return (
        <ConfirmationInformationPopup
            transportOrder={props.transportOrder}
            confirmations={props.confirmations}
            onPopupNotShown={(): void => props.onClick()}
            renderChildren= {(triggerPopupFlow: () => void): ReactElement =>
                <Button
                    onClick={(): void => {
                        if (!ctx.hasAnyError()) {
                            triggerPopupFlow()
                        } else {
                            ctx.showAllValidationMessages()
                        }
                    }}
                    disabled={props.enabled  !== undefined ? !props.enabled: false}
                    className="btn-primary"
                    data-testid="TransportOrderFrameFooter_AcceptButton">
                    <span className={"rioglyph rioglyph-ok"} />
                    <FormattedMessage id="transportOrder.accept"/>
                </Button>
            }
        />
    )
}

export const PrintButton = (props: ButtonProps): React.ReactElement => {
    if (!props.show) {
        return <React.Fragment />
    }
    return (
        <Button onClick={props.onClick} className={"btn-default btn-outline"}>
            <span className={"rioglyph rioglyph-print"} />
            <FormattedMessage id={"transportOrder.print"} />
        </Button>
    )
}
