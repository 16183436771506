import cloneDeep from "lodash/cloneDeep"
import cloneDeepWith from "lodash/cloneDeepWith"
import isEqual from "lodash/isEqual"

export const isEqualIgnoringUndefined = (a, b): boolean => {
    const aClone = cloneDeep(a)
    const bClone = cloneDeep(b)
    const aWithoutUndefined = cloneDeepWith(aClone, deleteUndefinedValues)
    const bWithoutUndefined = cloneDeepWith(bClone, deleteUndefinedValues)
    return isEqual(aWithoutUndefined, bWithoutUndefined)
}

const deleteUndefinedValues = (value): void => {
    if (value && typeof value === "object") {
        for (const [k, v] of Object.entries(value)) {
            if (v === undefined) {
                delete value[k]
            }
        }
    }
}
