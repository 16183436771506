import * as Sentry from "@sentry/browser"
import { config } from "../app/app-config"

const release = config.release.revision
const dsn = config.sentry.dsn

if (config.isProd) {
    Sentry.init({
        dsn,
        environment: "production",
        release,
    })
}

export const reportErrorToSentry =
    config.isProd ? Sentry.captureException : () => undefined
