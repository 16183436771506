import { FormattedMessage, useIntl } from "react-intl"
import Dialog from "@rio-cloud/rio-uikit/Dialog"
import Notification from "@rio-cloud/rio-uikit/Notification"
import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger"
import Tag from "@rio-cloud/rio-uikit/Tag"
import Tooltip from "@rio-cloud/rio-uikit/Tooltip"
import FilePicker from "@rio-cloud/rio-uikit/FilePicker"
import Button from "@rio-cloud/rio-uikit/Button"

import { ReactElement, useState } from "react"
import { Asset } from "../../model/asset/asset"
import { MainRunDataCsvReader, MainRunDataCsvWriter } from "../../helper/mainRunDataCsvTransformer"
import { ICrossDock } from "../../model/crossdock/crossDock"
import { saveAs } from "file-saver"
import { saveMainRunData } from "../../actions/mainRunData"
import { MainRunViolationError } from "../../model/mainrun/mainRunData"
import { MenuItemProps } from "@rio-cloud/rio-uikit/MenuItem"


export const mainRunUploadMenuItem = (onSelect: () => void): MenuItemProps => {
    return {
        value:  <OverlayTrigger
            placement={"bottom"}
            overlay={
                <Tooltip id="tooltip" allowOnTouch width={300} textAlignment={"left"}>
                    <FormattedMessage id="mainRun.popover.information"/>
                </Tooltip>
            }
        >
            <div>
                <span className="rioglyph rioglyph-cloud-upload margin-right-5"/>
                <FormattedMessage id={"common.upload"}/>
            </div>
        </OverlayTrigger>,
        onSelect,
    }
}

interface MainRunUploadDialogProps {
  assets: Asset[]
  crossDocks: ICrossDock[]
  showDialog: boolean
  closeDialog: () => void
}

const DropArea = ({ selectedFileName, isDragActive, onFileRemoveClickHandler }): ReactElement => {
    let uploadInformationToShow: ReactElement
    const classNames: string[] = [
        "rounded",
        "border",
        "text-center",
        "text-size-18",
        "padding-20",
        "cursor-pointer"
    ]

    if (selectedFileName && !isDragActive) {
        uploadInformationToShow = (
            <span className={"display-flex align-items-center justify-content-center"}>
                {selectedFileName}
                <button onClick={(e): void => {
                    e.stopPropagation()
                    onFileRemoveClickHandler()
                }} type="button" className="btn btn-muted btn-xs btn-icon-only">
                    <span className="rioglyph rioglyph-trash text-size-18 margin-left-5" aria-hidden="true"/>
                </button>
            </span>
        )
        classNames.push("bg-highlight-lighter", "border-style-solid", "border border-color-success")
    } else if (isDragActive) {
        uploadInformationToShow = <FormattedMessage id={"mainRun.upload.dropArea.dragActive"}/>
        classNames.push("bg-highlight-lighter", "border-style-solid")
    } else {
        uploadInformationToShow = <><FormattedMessage id={"mainRun.upload.dropArea.dragInactive"}/>
            <ClickHereLabel/>
        </>
        classNames.push("bg-lightest", "text-color-darker", "border-style-dashed", "border-color-gray")
    }

    return (
        <div className={classNames.join(" ")}>
            <span
                className={"rioglyph rioglyph-csv text-size-h1 " + (selectedFileName !== undefined ? "opacity-100" : "opacity-50")}/>
            <div>{uploadInformationToShow}</div>
        </div>
    )
}

const ClickHereLabel = (): ReactElement => {
    return (
        <div>
            <Tag size="small">
                <FormattedMessage id={"mainRun.upload.dropArea.dragInactive.clickInfo"}/>
            </Tag>
        </div>
    )
}

export const MainRunUploadDialog = (props: MainRunUploadDialogProps): ReactElement => {
    const { assets, crossDocks, closeDialog, showDialog } = props
    const [selectedCsvFile, setSelectedCsvFile] = useState<File | null>(null)
    const i18n = useIntl()

    const csvReader = new MainRunDataCsvReader()
    const csvWriter = new MainRunDataCsvWriter((error: MainRunViolationError): string => {
        return i18n.formatMessage({ id: "mainRun.upload.error." + error })
    })

    const handleOnFileRemoveClickEvent = (): void => {
        setSelectedCsvFile(null)
    }

    const dialogBody = (
        <>
            <div className={"margin-bottom-20"}>
                <FormattedMessage id={"mainRun.dialoge.information"}/>
            </div>
            <FilePicker
                displayMode={"dropzone"}
                onPick={(files: FileList | null): void => {
                    if (files && files?.length > 0) {
                        setSelectedCsvFile(files[0])
                    } else {
                        setSelectedCsvFile(null)
                    }
                }}
                multiple={false}
                accept={{
                    "text/csv": [".csv", ".txt"],
                }}
            >
                {({ isDragActive }): ReactElement => <DropArea selectedFileName={selectedCsvFile?.name}
                    isDragActive={isDragActive}
                    onFileRemoveClickHandler={handleOnFileRemoveClickEvent}/>}
            </FilePicker>
        </>
    )

    const startUpload = (): void => {
        if (selectedCsvFile === null) {
            return
        }

        csvReader.parseMainRunDataCsv(assets, crossDocks, selectedCsvFile)
            .then(parsedCsv => saveMainRunData(parsedCsv)
                .then(() => {
                    closeDialog()
                    Notification.success(
                        <div data-testid={"notification-confirmation-success"}>
                            <FormattedMessage id={"mainRun.upload.successNotification"}/>
                        </div>
                    )
                }).catch(error => {
                    if (error.violations !== undefined) {
                        saveCsvFile(csvWriter.unparseMainRunDataCsv(parsedCsv, assets, crossDocks, error.violations), "mainRunDataError.csv")
                        Notification.error(
                            <div data-testid={"notification-confirmation-error"}>
                                <FormattedMessage id={"mainRun.upload.error.violations"}/>
                            </div>
                        )
                    } else {
                        Notification.error(
                            <div data-testid={"notification-confirmation-error"}>
                                <FormattedMessage id={"mainRun.upload.error.general"}/>
                            </div>
                        )
                    }
                }))
    }

    const saveCsvFile = (content: string, fileName: string): void => {
        saveAs(new Blob([content], { type: "text/csv" }), fileName)
    }

    const dialogFooter = (
        <>
            <Button className={"btn btn-default"} onClick={(): void => {
                saveCsvFile(csvWriter.generateCsvTemplate(), "mainRunTemplate.csv")
            }}>
                <span className={"rioglyph rioglyph-download"}/>
                <FormattedMessage id={"mainRun.upload.downloadTemplateButton.label"}/> {}
            </Button>
            <div className={"flex-1-1"}/>
            <Button className={"btn btn-primary margin-left-10"} disabled={selectedCsvFile === null} onClick={startUpload}>
                <span className={"rioglyph rioglyph-upload"}/>
                <FormattedMessage id={"mainRun.upload.uploadButton.label"}/>
            </Button>
        </>
    )

    return (
        <Dialog
            title={<FormattedMessage id="mainRun.upload.openDialogButton.label"/>}
            body={dialogBody}
            footer={dialogFooter}
            onClose={closeDialog}
            footerClassName={"display-flex"}
            show={showDialog}
        />
    )
}