import * as React from "react"
import { useContext, useEffect, useState } from "react"
import moment, { Moment } from "moment-timezone"
import DatePicker, { DatePickerProps } from "@rio-cloud/rio-uikit/DatePicker"
import { ValidatationState, ValidationContext } from "../../Form/validation/VaildationContext"
import { FormGroupWrapper } from "../../Form/FormGroupWrapper"
import { FormattedMessage } from "react-intl"
import { Validator } from "../../Form/validation/Validators"
import { getProfileLocale } from "../../../redux/lang.selector"
import { useAppSelector } from "../../../redux/store"

export interface Props {
    label: string | React.ReactElement
    onChange: (_: string | undefined) => void
    value?: string
    timezone?: string
    datePickerProps?: DatePickerProps
    required?: boolean
    validate?: Validator<Moment | string | undefined>
    className?: string
}

const convertToStringDropOffset = (input?: string | Moment, timezone = "UTC"): string | undefined => {
    if (input === undefined) {
        return undefined
    }
    if (typeof input === "string") {
        return input
    }
    return moment.tz(input.format("YYYY-MM-DDTHH:mm:ss.SSS"), timezone).toISOString(true)
}

const convertToMomentInUtcDropOffset = (value?: string | Moment, timezone = "UTC"): Moment | string | undefined => {
    if (value === undefined) {
        return undefined
    }
    const asMoment = moment(value, undefined, true)
    if (asMoment.isValid()) {
        const inTimeZone = moment.tz(asMoment, timezone)
        return moment.tz(inTimeZone.format("YYYY-MM-DDTHH:mm:ss.SSS"), "UTC")
    }
    return value
}

export const DateTimeInput = (props: Props): React.ReactElement => {

    const validationContextState = useContext(ValidationContext)
    const [value, setValue] = useState<string | undefined>(props.value)
    const [forceShowError, setForceShowError] = useState<boolean>(false)
    const [touched, setTouched] = useState<boolean>(false)
    const locale: string | undefined = useAppSelector(getProfileLocale)

    const componentId = validationContextState.registerFormComponent((validationState: ValidatationState) => {
        setForceShowError(validationState.forceShowValidationMessages)
    })

    useEffect(() => {
        return (): void => {
            validationContextState.deregisterFormComponent(componentId)
        }
    })

    const validateInput = (value: Moment | string | undefined): string | undefined => {
        const { validate } = props
        const errorCode: string | undefined = validate && validate(value)
        if (errorCode) {
            validationContextState.reportError(componentId)
        } else {
            validationContextState.resolveError(componentId)
        }
        return errorCode
    }

    const errorCode = validateInput(value)
    const showError = errorCode !== undefined && (touched || forceShowError)
    const hint = showError ? <FormattedMessage id={errorCode}/> : undefined

    return <FormGroupWrapper
        label={props.label}
        hasError={showError}
        hint={hint}
        required={props.required}
        className={props.className}
        component={<DatePicker
            locale={locale}
            {...(props.datePickerProps || {})}
            value={convertToMomentInUtcDropOffset(props.value, props.timezone)}
            initialValue={undefined}
            onChange={(newValue: Moment | string): void => {
                const strRepr = convertToStringDropOffset(newValue, props.timezone)
                setValue(strRepr)
                props.onChange(strRepr)
            }}
            inputProps={{
                ...props.datePickerProps?.inputProps,
                onBlur: (): void => {
                    setTouched(true)
                }
            }}
            minWidth={0}
            className={"margin-bottom-0"}
        />}
        dataTestId={"DateTimeInput"}
    />
}
