import { ThunkAction } from "redux-thunk"
import { Action } from "redux"
import { convertExternalTransportOrderBundle } from "../helper/transportOrderBundleConverter"
import Notification from "@rio-cloud/rio-uikit/Notification"
import { TransportOrder } from "../model/transportorder/transportOrder"
import {
    TransportOrderBundleFetchParams,
    TransportOrderHubClient
} from "../client/transportorder-hub/TransportOrderHubClient"
import { getLanguageSettings } from "../redux/lang.selector"
import { transportOrderSlice } from "../redux/transportorder.slice"
import { LoadingSpinner, uiSlice } from "../redux/ui.slice"
import { State } from "../redux/store"
import { config } from "../configuration/app/app-config"

export interface ISuccessFetchTransportOrderBundlesPayload {
    totalItems: number
    items: TransportOrder[]
}

export const fetchTransportOrderBundles = (params: TransportOrderBundleFetchParams): ThunkAction<Promise<void>, State, { transportOrderHubClient: TransportOrderHubClient }, Action> => {
    return (dispatch, getState, extra): Promise<void> => {
        return extra.transportOrderHubClient.getTransportOrderBundles(params)
            .then(result => ({
                items: result.items.map(element => convertExternalTransportOrderBundle(element)),
                totalItems: result.items.length,
            }))
            .then(orders => {
                dispatch(transportOrderSlice.actions.setTransportOrderBundles(orders))
            })
            .finally(() =>  dispatch(uiSlice.actions.setLoadingSpinner({ loadingSpinnerName: LoadingSpinner.FetchTransportOrders, spinning: false })))
    }
}

let poller: number | undefined = undefined
export const fetchTransportOrderBundlesAndEnablePolling = (params: TransportOrderBundleFetchParams): ThunkAction<Promise<void>, State, { transportOrderHubClient: TransportOrderHubClient }, Action> => {
    if(poller) {
        window.clearInterval(poller)
    }
    return (dispatch): Promise<void> => {

        dispatch(uiSlice.actions.setLoadingSpinner({ loadingSpinnerName: LoadingSpinner.FetchTransportOrders, spinning: true }))

        poller = window.setInterval(() => {
            return dispatch(fetchTransportOrderBundles(params))
        }, config.transportOrderApi.pollIntervalMillis)
        return dispatch(fetchTransportOrderBundles(params))
    }
}

export const fetchTransportOrderBundle = (id: string): ThunkAction<Promise<void>, State, { transportOrderHubClient: TransportOrderHubClient }, Action> => {
    return (dispatch, getState, extra): Promise<void> => {
        return extra.transportOrderHubClient.getTransportOrderBundle(id)
            .then(response => {
                dispatch(transportOrderSlice.actions.setTransportOrderBundle(convertExternalTransportOrderBundle(response)))
            })
            .catch(() => {
                dispatch(transportOrderSlice.actions.setTransportOrderBundleFetchError({
                    transportOrderId: id,
                }))
                const languageSettings = getLanguageSettings(getState())
                Notification.error(languageSettings.intl.formatMessage({ id: "transportOrder.error.not.found" }))
            })
    }
}
