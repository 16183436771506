import { getTokenFromSessionStorage } from "../common/auth"
import { CrossDock, ICrossDock } from "../../model/crossdock/crossDock"
import { ExternalMainRunData } from "./model"
import { config } from "../../configuration/app/app-config"

export interface IExternalCrossDock {
    id: string
    name: string
}

type IExternalCrossDockConverter = {
    toDomain: () => ICrossDock
}

export const ExternalCrossDock = (id: string, name: string): IExternalCrossDock & IExternalCrossDockConverter => {
    return {
        toDomain(): ICrossDock {
            return CrossDock(id,name)
        }, id, name }
}

class AsnMatchingClient {

    private url = config.asnMatchingApi.baseUrl

    public saveMainRunData = async (mainRunData: ExternalMainRunData[]): Promise<{ items: ExternalMainRunData[] }> => {
        const token = await getTokenFromSessionStorage()
        const requestInit = {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ items: mainRunData })
        }
        return fetch(`${this.url}/main-runs`, requestInit)
            .then(resp => {
                if (!resp.ok) {
                    return resp.json().then(err => Promise.reject(err))
                } else {
                    return resp.json()
                }
            })
    }

    public loadMainRunData = async (): Promise<{ items: ExternalMainRunData[] }> => {
        const token = await getTokenFromSessionStorage()
        const requestInit = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json",
            }
        }
        return this.doRequest(`${this.url}/main-runs`, requestInit)
    }

    public getCrossDocks = async (): Promise<Array<IExternalCrossDock>> => {
        const token = await getTokenFromSessionStorage()
        const requestInit = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            }
        }
        return this.doRequest(`${this.url}/cross-docks`, requestInit)
    }

    private doRequest = <T>(url: string, requestInit: RequestInit): Promise<T> => {
        return fetch(url, requestInit)
            .then(resp => {
                if (!resp.ok) {
                    return Promise.reject(`Error in request ${requestInit.method} ${url}: ${resp.status} ${resp.statusText}`)
                }
                if (resp.status !== 204) {
                    return resp.json()
                }
            })
    }
}

export default new AsnMatchingClient()
