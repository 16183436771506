
import { MouseEventHandler, PropsWithChildren, ReactElement, ReactNode } from "react"
import { Column, columnDetailsByColumn, columns, getSortByForColumnDetails } from "./Columns"
import { FormattedMessage, useIntl } from "react-intl"
import SortArrows from "@rio-cloud/rio-uikit/SortArrows"
import UiKitSortDirection from "@rio-cloud/rio-uikit/SortDirection"
import { extractTableParamsFromUrl, SortBy, SortDirection } from "../../../redux/transportorder.slice"

export const TransportOrderTableHead = (props: { onSortByChanged: (sortBy: SortBy) => void }): ReactElement => {
    const tableHeads = columns.map(column =>
        <TransportOrderTableTH column={column} key={column} onSortByChanged={props.onSortByChanged}/>
    )

    return (
        <thead className="table-head">
            <tr>{tableHeads}</tr>
        </thead>
    )
}

const TransportOrderTableTH = (props: { column: Column, onSortByChanged: (sortBy: SortBy) => void }): ReactElement => {
    const columnDetails = columnDetailsByColumn[props.column]
    const tableParams = extractTableParamsFromUrl(location.search)
    const textCenterClass = [Column.WEIGHT, Column.VOLUME, Column.QUANTITY, Column.DANGEROUS_GOODS].includes(props.column)
        ? "text-center"
        : ""
    const thContent = columnDetails.alternateHead ?? columnDetails.label
        ? <FormattedMessage id={columnDetails.label}/>
        : undefined

    const sortBy = getSortByForColumnDetails(columnDetails)
    if (sortBy) {
        return (
            <IntlTh
                label={columnDetails.label}
                className={`user-select-none sort-column ellipsis-1 ${textCenterClass}`}
                key={props.column}
                onClick={(): void => props.onSortByChanged(sortBy)}>
                <span>
                    {tableParams.sortBy === columnDetails.sortBy
                        ? <SortArrows direction={tableParams.sortDirection === SortDirection.ASC
                            ? UiKitSortDirection.ASCENDING
                            : UiKitSortDirection.DESCENDING
                        }/>
                        : <SortArrows/>}
                    {thContent}
                </span>
            </IntlTh>
        )
    }
    return (
        <IntlTh
            label={columnDetails.label}
            className={`ellipsis-1 ${textCenterClass}`}
            key={props.column}>
            {thContent}
        </IntlTh>
    )
}

interface IntlThProps {
    label?: string
    key?: string | number
    className?: string
    onClick?: MouseEventHandler
}

const IntlTh = (props: PropsWithChildren<IntlThProps>): ReactNode => {
    const intl = useIntl()

    return (
        <th
            title={props.label ? intl.formatMessage({ id: props.label }) : undefined}
            className={props.className}
            onClick={props.onClick}
        >
            {props.children}
        </th>
    )
}
