import { ReactElement } from "react"
import { FormattedMessage } from "react-intl"

export type CancelledStatusBadgeProps = {
    show: boolean
}

export const CancelledStatusBadge = (props: CancelledStatusBadgeProps): ReactElement => {
    if (!props.show) {
        return <></>
    }
    return (
        <span className={"badge bg-rating-1 margin-left-10 margin-top-5 margin-bottom-5"}>
            <FormattedMessage id={`shipment.status.cancelled`}/>
        </span>
    )
}