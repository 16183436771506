import { LengthPresenter } from "../Form/LengthPresenter"
import { FormattedMessage } from "react-intl"
import { HandlingUnitPresenter } from "./HandlingUnitPresenter"
import { CargoFreight } from "../../model/transportorder/transportOrder"
import { ReactNode } from "react"

export interface CargoFreightPresenterProps {
    freight: CargoFreight
}

export const CargoFreightPresenter = (props: CargoFreightPresenterProps): ReactNode => {
    const freight = props.freight
    return (
        <>
            <LengthPresenter
                length={freight.loading_meters}
                label={<FormattedMessage id="transportOrder.shipment.cargo.loadmeters" />}
            />
            {
                freight.handling_units.map((handlingUnit) =>
                    <div key={handlingUnit._frontendKey}>
                        <hr className="margin-top-20 margin-bottom-20 border-width-1 border-color-light margin-top-0-print" />
                        <h6>
                            <FormattedMessage id="transportOrder.freight.handlingUnit" />
                        </h6>
                        <HandlingUnitPresenter handlingUnit={handlingUnit} />
                    </div>
                )
            }
        </>
    )
}
