import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout"
import { config } from "../../configuration/app/app-config"

export const ClosingDaysFrame = () => {
    return <ApplicationLayout.Body className={"print-area"} innerClassName={"padding-0"}>
        <iframe
            src={config.closingDaysFrontend.url}
            data-testid={"ClosingDaysIframe"}
            height={"100%"}
            width={"100%"}
            style={{ border: "0px" }}
        />
    </ApplicationLayout.Body>
}
