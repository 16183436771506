import { ReactElement, useEffect, useState } from "react"
import * as React from "react"
import Notification from "@rio-cloud/rio-uikit/Notification"
import ConfirmationDialog from "@rio-cloud/rio-uikit/ConfirmationDialog"
import Checkbox from "@rio-cloud/rio-uikit/Checkbox"
import { FormattedMessage, injectIntl, useIntl } from "react-intl"
import AgreementApi from "./AgreementApi"
import { v4 as uuid } from "uuid"

interface Agreement {
    id: string
    accepted_at: Date
}

type Agreements = Agreement[]

function AgreementDialog(): React.ReactElement | null {

    const [isLoading, setIsLoading]: [boolean, (boolean) => void] = useState(false)
    const [fetchAgreementsDone, setFetchAgreementsDone]: [boolean, (boolean) => void] = useState(false)
    const [consentedAgreements, setConsentedAgreements]: [Agreements, (Agreements) => void] = useState([])
    const [termsAndConditionsAgreementChecked, setTermsAndConditionsAgreementChecked]: [boolean, (boolean) => void] = useState(false)
    const [dataProcessingAgreementChecked, setDataProcessingAgreementChecked]: [boolean, (boolean) => void] = useState(false)

    const intl = useIntl()

    const apiClient = AgreementApi

    const userHasAgreed = (): boolean => {
        if (!fetchAgreementsDone) {
            return true // Optimistic
        }
        return consentedAgreements.length > 0
    }

    useEffect((): void => {
        apiClient.getAgreements()
            .then(agreements => {
                setConsentedAgreements(agreements)
                setFetchAgreementsDone(true)
            })
            .catch(() => {
                setConsentedAgreements([])
                setFetchAgreementsDone(true)
            }).finally(() => {
                setIsLoading(false)
            })
    }, [apiClient])

    const handleTermsAndConditionsAgreementCheck = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setTermsAndConditionsAgreementChecked(event.target.checked)
    }

    const handleDataProcessingAgreementCheck = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setDataProcessingAgreementChecked(event.target.checked)
    }

    const handleSubmit = (): Promise<void> => {
        const agreementId = uuid()
        setIsLoading(true)
        return apiClient.putAgreement(agreementId).then(agreement => {
            setConsentedAgreements([...consentedAgreements, agreement])
        }).catch(() => {
            Notification.error(<FormattedMessage id={"termsAndConditions.agreement.dialog.agreementFailed"}/>)
        })
    }

    if (userHasAgreed()) {
        return null
    }

    const renderDialogContent = (): ReactElement => {
        return (
            <div className={"display-flex flex-column"}>
                <p>
                    <FormattedMessage id={"termsAndConditions.agreement.dialog.text"}/>
                </p>
                <div className={"display-flex flex-row"}>
                    <Checkbox onChange={(event): void => handleTermsAndConditionsAgreementCheck(event)}>
                        <FormattedMessage id={"termsAndConditions.agreement.dialog.checkTermsAndConditionsLabel"} values={{
                            _linkTermsConditions: <a href={intl.formatMessage({ id: "termsAndConditions.agreement.dialog.checkTermsAndConditionsLabel.linkTermsConditions.href" })}
                                target="_blank"
                                rel="noopener noreferrer">
                                <FormattedMessage id={"termsAndConditions.agreement.dialog.checkTermsAndConditionsLabel.linkTermsConditions.text"}/>
                            </a>,
                            _linkSpecifications: <a href={intl.formatMessage({ id: "termsAndConditions.agreement.dialog.checkTermsAndConditionsLabel.linkSpecifications.href" })}
                                target="_blank"
                                rel="noopener noreferrer">
                                <FormattedMessage id={"termsAndConditions.agreement.dialog.checkTermsAndConditionsLabel.linkSpecifications.text"}/>
                            </a>,
                        }}/>
                    </Checkbox>
                </div>
                <div className={"display-flex flex-row"}>
                    <Checkbox onChange={(event): void => handleDataProcessingAgreementCheck(event)}>
                        <FormattedMessage id={"termsAndConditions.agreement.dialog.checkDataProcessingLabel"} values={{
                            _link: <a href={intl.formatMessage({ id: "termsAndConditions.agreement.dialog.checkDataProcessingLabel.link.href" })}
                                target="_blank"
                                rel="noopener noreferrer">
                                <FormattedMessage id={"termsAndConditions.agreement.dialog.checkDataProcessingLabel.link.text"}/>
                            </a>,
                        }}/>
                    </Checkbox>
                </div>
            </div>
        )
    }

    return (
        <ConfirmationDialog
            show={!userHasAgreed()}
            content={renderDialogContent()}
            title={<FormattedMessage id={"termsAndConditions.agreement.dialog.title"}/>}
            useOverflow={false}
            cancelButtonText={<FormattedMessage id={"termsAndConditions.agreement.dialog.cancel"}/>}
            confirmButtonText={<FormattedMessage id={"termsAndConditions.agreement.dialog.confirm"}/>}
            disableConfirm={!termsAndConditionsAgreementChecked || !dataProcessingAgreementChecked || isLoading}
            onClickCancel={(): void => {
                window.history.back()
            }}
            onClickConfirm={(): Promise<void> => handleSubmit()}
        />
    )
}

export const Agreement = injectIntl(AgreementDialog)
