import { ExternalGoods } from "./goods.model"
import { ExternalFreightForwarderResponse } from "./FreightForwarderResponse.model"

export interface ExternalBuyer {
    name: string
    address?: ExternalAddress
}

export interface ExternalAddress {
    street: string
    postal_code: string
    city: string
    country: string
}

export interface ExternalContact {
    name?: string
    email?: string
    phone?: string
}

export interface ExternalPartyExternalIdentifiers {
    duns?: string
    plant_code?: string
    supplier_code?: string
}

export interface ExternalShippingParty {
    name: string
    address: ExternalAddress
    contact?: ExternalContact
    external_identifiers: ExternalPartyExternalIdentifiers
}

export interface ExternalTimeWindow {
    time_from: string
    time_to: string
}

export interface ExternalLoading {
    time_window: ExternalTimeWindow
    access_code?: string
    place_of_loading?: string
    shipper: ExternalShippingParty
}

export interface ExternalUnloading {
    time_window?: ExternalTimeWindow
    access_code?: string
    place_of_discharge?: string
    recipient: ExternalShippingParty
}

export interface ExternalFreightPayer {
    name: string
}

export enum ExternalPriority {
    REGULAR = "REGULAR",
    URGENT = "URGENT",
}

export type ExternalProposedVehicleType = "PASSENGER_CAR" | "VAN" | "LIGHT_TRUCK" | "MEDIUM_TRUCK"

export interface ExternalTransportOrder {
    id: string
    bundle_id: string
    external_id: string
    cancelled?: boolean
    process_indicator: "LTL" | "FTL" | "KEP"
    freight_payer?: ExternalFreightPayer
    buyer?: ExternalBuyer
    proposed_vehicle_type?: ExternalProposedVehicleType
    requires_two_drivers?: boolean
    priority: ExternalPriority
    loading: ExternalLoading
    unloading: ExternalUnloading
    goods: ExternalGoods
    freight_forwarder_response?: ExternalFreightForwarderResponse
    last_modified_at: string
    external_order_created_date: string
    etag: number
}

export interface ExternalTransportOrderBundle {
    id: string
    external_bundle_id?: string
    created_at: string
    _embedded: {
        transport_orders: Array<ExternalTransportOrder>
    }
}

export interface ExternalTransportOrderBundleList {
    items: ExternalTransportOrderBundle[]
}
