import Select from "@rio-cloud/rio-uikit/Select"
import { ReactElement, useEffect } from "react"
import { FormGroupWrapper } from "./FormGroupWrapper"
import { FormattedMessage } from "react-intl"
import { SelectOption } from "@rio-cloud/rio-uikit"

type CrossDockId = string

export type CrossDockDescriptor = {
    id: CrossDockId
    name: string
}

export type CrossDockIdSelectProps = {
    hide?: boolean
    options: CrossDockDescriptor[]
    value: CrossDockId | undefined
    onChange: (value: CrossDockId | undefined) => void
    className?: string
    disabled?: boolean
}

export const CrossDockIdSelect = (props: CrossDockIdSelectProps): ReactElement => {
    const { value, onChange, hide, className } = props
    const options = props.options.slice().sort((a, b) => a.name.localeCompare(b.name))
    const disabled = props.disabled || options.length === 0
    const selectOptions: SelectOption[] = options.map(({ id, name }) => ({
        id: id,
        label: name,
        selected: value == id
    }))
    selectOptions.push({
        id: "",
        label: "---",
        selected: !value
    })

    useEffect(() => {
        const valueExistsInOptions = options.filter(({ id }) => id == value).length > 0
        if (value && !valueExistsInOptions && !disabled && !hide) {
            onChange(undefined)
        }
    }, [disabled, value, options, onChange, hide])

    if (hide) {
        return <></>
    }

    return (
        <FormGroupWrapper
            label={<FormattedMessage id={"transportOrder.crossDock"} />}
            className={className}
            component={
                <Select
                    disabled={disabled}
                    options={selectOptions}
                    onChange={(newValue?: SelectOption): void => {
                        if (newValue && newValue.id !== "") {
                            onChange(newValue.id)
                        } else {
                            onChange(undefined)
                        }
                    }}
                />
            }/>
    )
}