import { createSlice, type PayloadAction } from "@reduxjs/toolkit"

export type UserProfile = {
    sub?: string
    azp?: string
    account?: string
    givenName?: string
    familyName?: string
    name?: string
    locale?: string
    email?: string
};

export type LoginState = {
    hasUserSessionEverExpired: boolean
    userProfile: UserProfile | null
    userSessionExpired: boolean
};

const initialState: LoginState = {
    hasUserSessionEverExpired: false,
    userProfile: null,
    userSessionExpired: false,
}

export const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        userProfileObtained: (state, action: PayloadAction<UserProfile>) => {
            state.userProfile = action.payload
        },
        userSessionExpired: (state) => {
            state.hasUserSessionEverExpired = true
            state.userSessionExpired = true
        },
        userSessionRenewed: (state) => {
            state.userSessionExpired = false
        },
    },
})
