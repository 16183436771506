import { PropsWithChildren, ReactElement, useState } from "react"

interface IProps<T> {
    onClick: () => Promise<T>
    onSuccess?: (success: T) => void
    disableSuccessDifferentiation?: boolean
    onError?: (error: Error) => void
    btnClass?: string
    dataTestId?: string
    disabled?: boolean
}

export const ActionButton = <T, >(props: PropsWithChildren<IProps<T>>): ReactElement => {
    const [state, setState] = useState({ inProgress: false, success: false })
    const { onClick, onError, onSuccess, children, btnClass, disabled } = props
    const useSuccessDifferentiation = !props.disableSuccessDifferentiation
    const disabledClass = state.inProgress || (state.success && useSuccessDifferentiation) || disabled ? "disabled" : ""
    const classes = `btn${btnClass ? " " + btnClass : ""} ${state.inProgress ? " btn-loading-overlay " : ""}${disabledClass}`
    const handleClick = (): Promise<void> => {
        setState({ ...state, inProgress: true })

        return onClick()
            .then(
                (success: T) => {
                    onSuccess && onSuccess(success)
                    setState({ success: true, inProgress: false })
                    return Promise.resolve()
                },
            )
            .catch((error: Error) => {
                onError && onError(error)
                setState({ success: false, inProgress: false })
                return Promise.resolve()
            })
    }

    const content = useSuccessDifferentiation && state.success
        ? <span className="rioglyph rioglyph-ok" aria-hidden="true"/>
        : children

    return (
        <div className={classes} data-testid={props.dataTestId} onClick={(): void => {
            !state.inProgress && (!state.success || !useSuccessDifferentiation) && handleClick()
        }}>
            {content}
        </div>
    )
}

export default ActionButton
