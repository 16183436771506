import { ReactElement, useState } from "react"
import VolumeForecastClient from "../../client/transportorder-hub/VolumeForecastClient"
import Notification from "@rio-cloud/rio-uikit/Notification"
import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger"
import Tooltip from "@rio-cloud/rio-uikit/Tooltip"
import Button from "@rio-cloud/rio-uikit/Button"
import { FormattedMessage } from "react-intl"
import { saveAs } from "file-saver"

const saveVolumeForecasts = (): Promise<void> => {
    return VolumeForecastClient.getVolumeForecasts()
        .then(volumeForecasts => {
            if (volumeForecasts == "NO_DATA") {
                Notification.warning(<FormattedMessage id="volumeForecast.warning.empty" />)
            } else {
                saveAs(volumeForecasts.fileContent, volumeForecasts.fileName)
            }
        })
        .catch(() => {
            Notification.error(<FormattedMessage id={"volumeForecasts.error.fetch"}/>)
        })
}

export const VolumeForecastDownloadButton = (): ReactElement => {
    const [isDownloading, setIsDownloading] = useState(false)

    const handleClick = (): void => {
        setIsDownloading(true)
        saveVolumeForecasts().then(() => {
            setIsDownloading(false)
        })
    }

    return (
        <div className="volume-forecast-download-button">
            <OverlayTrigger
                placement={"bottom"}
                overlay={
                    <Tooltip id="tooltip" allowOnTouch width={300} textAlignment={"left"}>
                        <FormattedMessage id="volumeForecast.explanation" />
                    </Tooltip>
                }
            >
                <Button disabled={isDownloading} onClick={handleClick}>
                    <span className="rioglyph rioglyph-document-out"/>
                    <FormattedMessage id="volumeForecast.download" />
                </Button>
            </OverlayTrigger>
        </div>
    )
}
