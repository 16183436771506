import moment, { Moment } from "moment"

export type Validator<T> = (value?: T) => string | undefined

export const NotNullValidator: Validator<{} | null> = (value?: {} | null) => (
    value === undefined || value === null ? "validation.must.not.be.null" : undefined
)

export const NotBlankValidator: Validator<string | null> = (value?: string | null) => (
    value?.trim().length === 0 ? "validation.must.not.be.blank" : undefined
)

export const NotNullOrBlankValidator: Validator<string | null> = (value?: string | null) => (
    NotNullValidator(value) || NotBlankValidator(value)
)

export const OnlyDigitsValidator:  Validator<string> = (value?: string) => {
    return value ? /^\d+$/.test(value) ? undefined : "validation.must.contain.only.digits" : undefined
}

export const DateTimeValidator: Validator<Moment | string | null | undefined> = (value: Moment | string | null | undefined) => {
    if (value === null || value === undefined) {
        return undefined
    } else if (typeof value === "string") {
        const notBlankErrorCode = NotBlankValidator(value)
        const parseErrorCode = !moment.utc(value, undefined, true).isValid() ? "validation.invalid.date" : undefined
        return notBlankErrorCode || parseErrorCode
    } else {
        return value?.isValid() ? undefined : "validation.invalid.date"
    }
}

export const GreaterOrEqualToZeroValidator: Validator<number> = (value?: number) => (
    value !== undefined && value < 0.0 ? "validation.must.be.positive_or_zero.number" : undefined
)

export const GreaterThanZeroValidator: Validator<number> = (value?: number) => (
    value !== undefined && value <= 0.0 ? "validation.must.be.positive.number" : undefined
)
