import { useFeatureFlag, User } from "configcat-react"
import { useAppSelector } from "../../redux/store"
import { accountIdSelector, subjectSelector } from "../login/login.selector"

export const useFeatureToggle = (featureToggle: string, defaultValue: boolean): boolean => {
    const subject = useAppSelector(subjectSelector)
    const accountId = useAppSelector(accountIdSelector)
    const featureFlag = useFeatureFlag<boolean>(featureToggle, defaultValue, new User(
        subject || "unidentified-user",
        undefined,
        undefined,
        {
            accountId: accountId || "unidentified-account"
        }
    ))
    return featureFlag.loading ? defaultValue : featureFlag.value
}
