import { ExternalTransportOrderBundle, ExternalTransportOrderBundleList } from "./model"
import { getTokenFromSessionStorage } from "../common/auth"
import { ExternalFreightForwarderResponse } from "./FreightForwarderResponse.model"
import { config } from "../../configuration/app/app-config"

export type TransportOrderBundleFetchParams = {
    fromCreatedAt: Date
    toCreatedAt: Date
}

export class TransportOrderHubClient {

    private url = config.transportOrderApi.baseUrl

    public getTransportOrderBundle = async (id: string): Promise<ExternalTransportOrderBundle> => {
        const token = await getTokenFromSessionStorage()
        const requestInit = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            }
        }
        return this.doRequest<ExternalTransportOrderBundle>(`${this.url}/transport-order-bundles/${id}?embed=transport_orders`, requestInit)
    }

    public getTransportOrderBundles = async (params: TransportOrderBundleFetchParams): Promise<ExternalTransportOrderBundleList> => {
        const url = new URL(`${this.url}/transport-order-bundles`)
        url.searchParams.append("embed", "transport_orders")
        url.searchParams.append("from_created_at", params.fromCreatedAt.toISOString())
        url.searchParams.append("to_created_at", params.toCreatedAt.toISOString())
        const token = await getTokenFromSessionStorage()
        const requestInit = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            },
        }
        return this.doRequest<ExternalTransportOrderBundleList>(url.href, requestInit)
    }

    public createOrUpdateFreightForwarderResponse = async (
        transportOrderId: string,
        freightForwarderResponse: ExternalFreightForwarderResponse,
        ifMatch: number,
    ): Promise<void> => {
        const token = await getTokenFromSessionStorage()
        const requestInit = {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json",
                "Content-Type": "application/json",
                "If-Match": `${ifMatch}`,
            },
            body: JSON.stringify(freightForwarderResponse)
        }
        return this.doRequest<void>(`${this.url}/transport-orders/${transportOrderId}/freight-forwarder-responses`, requestInit)
    }

    private doRequest = <T>(url: string, requestInit: RequestInit): Promise<T> => {
        return fetch(url, requestInit)
            .then(resp => {
                if (!resp.ok) {
                    return Promise.reject(`Error in request ${requestInit.method} ${url}: ${resp.status} ${resp.statusText}`)
                }
                if (resp.status !== 204) {
                    return resp.json()
                }
            })
    }
}

export default new TransportOrderHubClient()
