import Papa, { ParseResult } from "papaparse"
import { Asset } from "../model/asset/asset"
import { ICrossDock } from "../model/crossdock/crossDock"
import { MainRunData, MainRunViolation, MainRunViolationError } from "../model/mainrun/mainRunData"

const headerNames = [
    "License Plate",
    "Consignment Number",
    "Supplier DUNS",
    "Loading Time Window From",
    "Loading Time Window To",
    "Bordero Number",
    "Access Code At Unloading",
    "Cross Dock Name",
] as const

type Violations = "Violations"

type CsvMainRunDataRow = {
    [key in typeof headerNames[number] | Violations]: string
}

export class MainRunDataCsvWriter {

    private translateViolation: (error: MainRunViolationError) => string
    private papaUnparseConfig: Papa.UnparseConfig = {
        header: true,
        quotes: true,
        columns: [...headerNames],
        skipEmptyLines: "greedy",
        newline: "\n",
        delimiter: ";",
    }
    private papaUnparseWithViolationsConfig: Papa.UnparseConfig = {
        ...this.papaUnparseConfig,
        columns: [...headerNames, "Violations"],
    }

    constructor(translateViolation: (error: MainRunViolationError) => string) {
        this.translateViolation = translateViolation
    }

    public generateCsvTemplate = (): string => Papa.unparse([{}], this.papaUnparseConfig)

    public unparseMainRunDataCsv = (
        mainRunData: MainRunData[],
        assets: Asset[],
        crossDocks: ICrossDock[],
        violations?: MainRunViolation[],
    ): string => {
        const assetById = this.mapById(assets)
        const getLicensePlateByAssetId = (assetId: string): string | undefined => assetById[assetId]?.licensePlate
        const crossDockById = this.mapById(crossDocks)
        const getCrossDockNameById = (crossDockId: string): string | undefined => crossDockById[crossDockId]?.name

        const csvData = mainRunData.map((mainRun, idx) => {
            const violation = violations?.find(it => it.index == idx)
            return this.toCsvMainRunDataRow(mainRun, getLicensePlateByAssetId, getCrossDockNameById, violation)
        })

        // push empty object to force Papa to generate the headers even if csvData was empty
        csvData.push({
            "Consignment Number": "",
            "Supplier DUNS": "",
            "Loading Time Window From": "",
            "Loading Time Window To": "",
            "Cross Dock Name": "",
            "Bordero Number": "",
            "License Plate": "",
            "Access Code At Unloading": "",
        } as CsvMainRunDataRow)

        return violations !== undefined && violations.length > 0
            ? Papa.unparse(csvData, this.papaUnparseWithViolationsConfig)
            : Papa.unparse(csvData, this.papaUnparseConfig)
    }

    private mapById<T extends { id: string }>(items: T[]): { [id: string]: T } {
        return items.reduce((acc, item) => ({ ...acc, [item.id]: item }), {})
    }

    private toCsvMainRunDataRow(
        mainRun: MainRunData,
        getLicensePlateByAssetId: (assetId: string) => string | undefined,
        getCrossDockNameById: (crossDockId: string) => string | undefined,
        mainRunViolation: MainRunViolation | undefined,
    ): CsvMainRunDataRow {
        return {
            "Consignment Number": mainRun.asnTrackingNumber,
            "Supplier DUNS": mainRun.supplierDuns,
            "Loading Time Window From": mainRun.loadingTimeWindow.timeFrom,
            "Loading Time Window To": mainRun.loadingTimeWindow.timeTo,
            "Cross Dock Name": getCrossDockNameById(mainRun.crossDockId) ?? "",
            "Bordero Number": mainRun.mainRunCarrier.type === "SELF" ? mainRun.mainRunCarrier.loadId : "",
            "License Plate": (mainRun.mainRunCarrier.type === "SELF" ? getLicensePlateByAssetId(mainRun.mainRunCarrier.assetId) : "") ?? "",
            "Access Code At Unloading": mainRun.mainRunCarrier.type === "SELF" ? mainRun.mainRunCarrier.accessCode : "",
            "Violations": mainRunViolation?.errors?.map(this.translateViolation).join(" | ") ?? "",
        }
    }
}

export class MainRunDataCsvReader {

    private defaultUuid = "00000000-0000-0000-0000-000000000000"

    public parseMainRunDataCsv = (assets: Asset[], crossDocks: ICrossDock[], file: File): Promise<MainRunData[]> => {
        const assetByLicensePlate = this.mapByLicensePlateUpperCaseAndTrimmed(assets)
        const getAssetIdByLicensePlate = (licensePlate: string): string => assetByLicensePlate[licensePlate.trim().toUpperCase()]?.id ?? this.defaultUuid
        const crossDockByName = this.mapByNameUpperCaseAndTrimmed(crossDocks)
        const getCrossDockIdByName = (crossDockName: string): string => crossDockByName[crossDockName.trim().toUpperCase()]?.id ?? this.defaultUuid
        const replaceUndefinedButKnownFieldsWithEmptyStrings = this.replaceUndefinedButKnownFieldsWithEmptyStrings

        return new Promise<CsvMainRunDataRow[]>((resolve) => {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete(results: ParseResult<CsvMainRunDataRow>) {
                    // Papa sets unread fields to undefined instead of empty strings
                    resolve(results.data.map(replaceUndefinedButKnownFieldsWithEmptyStrings))
                }
            })
        }).then((rows: CsvMainRunDataRow[]) => {
            return rows.map((row: CsvMainRunDataRow): MainRunData => {
                return this.toMainRunData(row, getCrossDockIdByName, getAssetIdByLicensePlate)
            })
        })
    }

    private toMainRunData(
        csvRow: CsvMainRunDataRow,
        getCrossDockIdByName: (crossDockName: string) => string,
        getAssetIdByLicensePlate: (licensePlate: string) => string
    ): MainRunData {
        return {
            asnTrackingNumber: csvRow["Consignment Number"],
            crossDockId: getCrossDockIdByName(csvRow["Cross Dock Name"]),
            supplierDuns: csvRow["Supplier DUNS"],
            mainRunCarrier: {
                type: "SELF",
                loadId: csvRow["Bordero Number"],
                // License Plate contains the assetId as it was mapped in the Papa transform function
                assetId: getAssetIdByLicensePlate(csvRow["License Plate"]),
                accessCode: csvRow["Access Code At Unloading"]
            },
            loadingTimeWindow: {
                timeFrom: csvRow["Loading Time Window From"],
                timeTo: csvRow["Loading Time Window To"]
            }
        }
    }

    private mapByLicensePlateUpperCaseAndTrimmed = (assets: Asset[]): Record<string, Asset> => {
        return assets.reduce((acc, asset) => {
            if (asset.licensePlate) {
                acc[asset.licensePlate.trim().toUpperCase()] = asset
            }

            return acc
        }, {} as Record<string, Asset>)
    }

    private mapByNameUpperCaseAndTrimmed = (crossDocks: ICrossDock[]): Record<string, ICrossDock> => {
        return crossDocks.reduce((acc, crossDock) => {
            acc[crossDock.name.trim().toUpperCase()] = crossDock
            return acc
        }, {} as Record<string, ICrossDock>)
    }

    private replaceUndefinedButKnownFieldsWithEmptyStrings = <T>(row: T): T => {
        headerNames.forEach(headerName => {
            if (!row[headerName]) {
                row[headerName] = ""
            }
        })
        return row
    }

}
