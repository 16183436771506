import { ShipmentConfirmation } from "../model/confirmation/Confirmation"
import { ThunkAction } from "redux-thunk"
import { Action } from "redux"
import { convertConfirmation as convertConfirmationToHubModel } from "../helper/transportOrderBundleConverter"
import { TransportOrderHubClient } from "../client/transportorder-hub/TransportOrderHubClient"
import { State } from "../redux/store"

export type ThunkExtras = { transportOrderHubClient: TransportOrderHubClient }

export const confirmShipment = (shipmentConfirmation: ShipmentConfirmation): ThunkAction<Promise<void>, State, ThunkExtras, Action> => {
    return (dispatch, getState, extra): Promise<void> => {
        return extra.transportOrderHubClient.createOrUpdateFreightForwarderResponse(
            shipmentConfirmation.shipmentId,
            convertConfirmationToHubModel(shipmentConfirmation.confirmation),
            shipmentConfirmation.version,
        )
    }
}

export const confirmShipments = (
    shipmentConfirmations: ShipmentConfirmation[],
): ThunkAction<Promise<void>, State, ThunkExtras, Action> => {
    return (dispatch): Promise<void> => {
        const sendConfirmation = shipmentConfirmation => dispatch(confirmShipment(shipmentConfirmation))
        return Promise.all(shipmentConfirmations.map(sendConfirmation))
            .then((): Promise<void> => Promise.resolve())
    }
}
