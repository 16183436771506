import { ReactElement } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Article } from "../../model/transportorder/transportOrder"
import { DangerousGoodsPresenter } from "./dangerousgoods/DangerousGoodsPresenter"
import { WeightPresenter } from "../Form/WeightPresenter"
import { ReadOnlyDetailViewFormValue } from "../Form/ReadOnlyDetailViewFormValue"
import { CountryOfOriginPresenter } from "./CountryOfOriginPresenter"

interface IArticlePresenterProps {
    article: Article
}

export const ArticlePresenter = (props: IArticlePresenterProps): ReactElement => {
    const intl = useIntl()
    const article = props.article
    return (
        <div className={"form-group word-break-all-print"}>
            <div className="row">
                <div className="col-md-6 width-50pct-print float-left-print">
                    <div className="row">
                        <ReadOnlyDetailViewFormValue
                            value={article.article_id}
                            label={<FormattedMessage id="transportOrder.shipment.cargo.article.id"/>}
                            md={4}
                            print={50}
                        />
                        <ReadOnlyDetailViewFormValue
                            value={article.name}
                            label={<FormattedMessage id="transportOrder.shipment.cargo.article.name"/>}
                            md={8}
                            print={50}
                        />
                    </div>
                </div>
                <ReadOnlyDetailViewFormValue
                    data={article}
                    property={"quantity"}
                    formatProperty={intl.formatNumber}
                    label={<FormattedMessage id="transportOrder.shipment.cargo.quantity"/>}
                    md={2}
                    print={20}
                />
                <ReadOnlyDetailViewFormValue
                    value={article.internal_place_of_destination}
                    label={<FormattedMessage id="transportOrder.shipment.cargo.article.internalPlaceOfDestination"/>}
                    md={3}
                    print={20}
                />
                <div className="col-md-1"/>
            </div>
            <div className="row">
                <div className="col-md-6 width-50pct-print float-left-print">
                    <div className="row">
                        <DangerousGoodsPresenter
                            value={article.dangerous_goods_information}
                            selectClassName={"col-md-2 float-left-print width-20pct-print"}
                            undgCodeClassName={"col-md-2 float-left-print width-20pct-print"}
                            descriptionClassName={"col-md-2 word-break-all float-left-print width-20pct-print"}
                            exceptionRegulationClassName={"col-md-2 word-break-all float-left-print width-20pct-print"}
                        />
                        <div className={"col-md-2 float-left-print width-20pct-print"}>
                            <WeightPresenter
                                weight={article.net_explosive_mass}
                                label={<FormattedMessage id="transportOrder.shipment.cargo.article.netExplosiveMass" />}
                            />
                        </div>
                    </div>
                </div>
                <CountryOfOriginPresenter
                    value={article.country_of_origin}
                    countryCodeClassName={"col-md-2 float-left-print width-20pct-print"}
                    dutiableGoodsClassName={"col-md-2 float-left-print width-20pct-print"}
                />
            </div>
        </div>
    )
}
