import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import type { UserProfile as Profile } from "oidc-client-ts"
import { accessToken } from "./accessToken"
import { State } from "../../redux/store"

export type AccessToken = string | undefined | null;

export interface AccessTokenState {
    accessToken: AccessToken
    idToken: Profile | null
}

const initialState: AccessTokenState = {
    accessToken: accessToken.getAccessToken(),
    idToken: null,
}

export const tokenSlice = createSlice({
    name: "token",
    initialState,
    reducers: {
        accessTokenStored: (state, action: PayloadAction<AccessToken>) => {
            state.accessToken = action.payload
        },
        idTokenStored: (state, action: PayloadAction<Profile>) => {
            state.idToken = action.payload
        },
    },
})

export const getAccessToken = (state: State) => state.token.accessToken ?? "NO_ACCESS_TOKEN_AVAILABLE"
export const getIdToken = (state: State) => state.token.idToken
