import { transportOrderSlice } from "./transportorder.slice"
import { uiSlice } from "./ui.slice"
import { combineReducers } from "redux"
import TransportOrderHubClient from "../client/transportorder-hub/TransportOrderHubClient"
import { configureStore } from "@reduxjs/toolkit"
import { notificationSettingsApi } from "../client/notification-settings/notificationSettingsApi"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { loginSlice } from "../configuration/login/login.slice"
import { tokenSlice } from "../configuration/token/token.slice"

export const reducers = combineReducers({
    [loginSlice.reducerPath]: loginSlice.reducer,
    [tokenSlice.reducerPath]: tokenSlice.reducer,
    [transportOrderSlice.reducerPath]: transportOrderSlice.reducer,
    [notificationSettingsApi.reducerPath]: notificationSettingsApi.reducer,
    [uiSlice.reducerPath]: uiSlice.reducer,
})

export const createStore = () => configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: {
                    transportOrderHubClient: TransportOrderHubClient,
                },
            },
        })
            .concat(notificationSettingsApi.middleware)
})

export const store = createStore()

export type State = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<State> = useSelector
