import { getTokenFromSessionStorage } from "../../client/common/auth"
import { config } from "../../configuration/app/app-config"

interface Agreement {
    id: string
    acceptedAt?: string
}

interface ExternalAgreement {
    id: string
    accepted_at?: string
}

interface ExternalAgreementList {
    items: Array<ExternalAgreement>
}

class ApiClient {

    private url = config.agreementApi.baseUrl

    public getAgreements = async (): Promise<Array<Agreement>> => {
        const token = await getTokenFromSessionStorage()
        const requestInit = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            },
            method: "GET"
        }
        return this.doRequest<ExternalAgreementList>(`${this.url}/agreements`, requestInit)
            .then(payload => payload.items.map(item => ({
                id: item.id,
                acceptedAt: item.accepted_at
            })))
    }

    public putAgreement = async (id: string): Promise<Agreement> => {
        const token = await getTokenFromSessionStorage()
        const requestInit = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            method: "PUT",
            body: JSON.stringify({
                id,
            })
        }
        return this.doRequest<ExternalAgreement>(`${this.url}/agreements/${id}`, requestInit)
            .then(payload => {
                return {
                    id: payload.id,
                    acceptedAt: payload.accepted_at,
                }
            })
    }

    private doRequest = <T>(url: string, requestInit: RequestInit): Promise<T> => {
        return fetch(url, requestInit)
            .then(resp => {
                if(!resp.ok) {
                    throw new Error(`Error in request to agreements backend: ${resp.status} ${resp.statusText}`)
                }
                return resp.json()
            })
    }
}

export default new ApiClient()
