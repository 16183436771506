import * as React from "react"
import { Reducer, useEffect, useReducer, useRef, useState } from "react"
import { FormattedMessage } from "react-intl"
import { ShipmentStatus, TimeWindow, TransportOrder, VehicleType } from "../../../model/transportorder/transportOrder"
import { AssetSelect } from "../../Asset/AssetSelect"
import { TimeWindowBookingIdInput } from "./TimeWindowBookingIdInput"
import {
    areConfirmationsEqual,
    Confirmation,
    confirmationsForNonCancelled,
    ShipmentConfirmation
} from "../../../model/confirmation/Confirmation"
import { TimeWindowInput } from "../timewindow/TimeWindowInput"
import Checkbox from "@rio-cloud/rio-uikit/Checkbox"
import { ValidFromAndTimeWindowCompleteValidator, ValidToAndTimeWindowCompleteValidator } from "./validator"
import {
    doesTransportOrderSupportDriverPhoneNumber,
    doesTransportOrderSupportVehicleType,
} from "../../../model/transportorder/transportOrderEditing"
import { DriverPhoneNumberInput } from "./DriverPhoneNumberInput"
import { VehicleTypeInput } from "./VehicleTypeInput"
import { CancelledStatusBadge } from "./CancelledStatusBadge"
import { BorderoNumberInput } from "./BorderoNumberInput"
import { timezoneOfLoadingAddress } from "../../../timezones/timezones"

export interface Props {
    transportOrder: TransportOrder

    onChange(_: ShipmentConfirmation[]): void
}

const replace = (shipmentConfirmations: ShipmentConfirmation[], replacement: ShipmentConfirmation): ShipmentConfirmation[] =>
    shipmentConfirmations.map(it => it.shipmentId === replacement.shipmentId ? replacement : it)

const changeNonCancelled = (shipmentConfirmations: ShipmentConfirmation[], fct: (_: ShipmentConfirmation) => ShipmentConfirmation): ShipmentConfirmation[] =>
    shipmentConfirmations.map(it => {
        if (it.shipmentStatus === ShipmentStatus.CANCELLED) {
            return it
        } else {
            return fct(it)
        }
    })

interface State {
    shipmentConfirmations: ShipmentConfirmation[]
    shipmentConfirmationsForNonCancelled: ShipmentConfirmation[]
    appliesToAllShipments: boolean
}

interface CheckAppliesToAllAction {
    type: "CHECK_APPLIES_TO_ALL"
}

interface UncheckAppliesToAllAction {
    type: "UNCHECK_APPLIES_TO_ALL"
}

interface ChangeConfirmationAction {
    type: "CHANGE_CONFIRMATION"
    payload: ShipmentConfirmation
}

type Action = CheckAppliesToAllAction | UncheckAppliesToAllAction | ChangeConfirmationAction

const reducer: Reducer<State, Action> = (state: State, action: Action): State => {
    switch (action.type) {
    case "CHECK_APPLIES_TO_ALL":
        const newShipmentConfirmations = changeNonCancelled(state.shipmentConfirmations, it => ({
            ...it,
            confirmation: state.shipmentConfirmationsForNonCancelled[0].confirmation,
        }))
        return {
            ...state,
            appliesToAllShipments: true,
            shipmentConfirmations: newShipmentConfirmations,
            shipmentConfirmationsForNonCancelled: confirmationsForNonCancelled(newShipmentConfirmations),
        }
    case "UNCHECK_APPLIES_TO_ALL":
        return {
            ...state,
            appliesToAllShipments: false,
        }
    case "CHANGE_CONFIRMATION":
        if (state.appliesToAllShipments) {
            const newShipmentConfirmations = changeNonCancelled(state.shipmentConfirmations, it => ({
                ...it,
                confirmation: action.payload.confirmation,
            }))
            return {
                ...state,
                shipmentConfirmations: newShipmentConfirmations,
                shipmentConfirmationsForNonCancelled: confirmationsForNonCancelled(newShipmentConfirmations),
            }
        } else {
            const newShipmentConfirmations = replace(state.shipmentConfirmations, action.payload)
            return {
                ...state,
                shipmentConfirmations: newShipmentConfirmations,
                shipmentConfirmationsForNonCancelled: confirmationsForNonCancelled(newShipmentConfirmations),
            }
        }
    }
}

const initialStateFor = (transportOrder: TransportOrder): State => {
    const shipmentConfirmations: ShipmentConfirmation[] = (transportOrder._embedded?.shipments ?? [])
        .map(shipment => ({
            shipmentId: shipment.id,
            shipmentStatus: shipment.status,
            confirmation: shipment.confirmationDraft,
            version: shipment.version,
        }))
    const shipmentConfirmationsForNonCancelled = confirmationsForNonCancelled(shipmentConfirmations)
    const appliesToAllShipments = shipmentConfirmationsForNonCancelled.every((shipmentConfirmation): boolean =>
        areConfirmationsEqual(shipmentConfirmation.confirmation, shipmentConfirmationsForNonCancelled[0].confirmation))
    return {
        shipmentConfirmations: shipmentConfirmations,
        shipmentConfirmationsForNonCancelled: shipmentConfirmationsForNonCancelled,
        appliesToAllShipments: appliesToAllShipments
    }
}

export const EmptiesOrderConfirmationInput = (props: Props): React.ReactElement => {

    const { transportOrder, onChange } = props
    const initialState = initialStateFor(transportOrder)

    const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, initialState)
    const isFirstRun = useRef(true)
    useEffect((): void => {
        if (isFirstRun.current) {
            isFirstRun.current = false
            return
        }
        onChange(state.shipmentConfirmations)
    }, [state]) // eslint-disable-line react-hooks/exhaustive-deps

    return <div className={"panel panel-default padding-bottom-5 callout callout-primary overflow-visible"} data-testid="EmptiesOrderConfirmationInput">
        <div className={"panel-heading text-color-darkest test-size-20"}>
            <h3 className={"margin-top-5 margin-bottom-5 text-size-20 text-color-darkest"}>
                <FormattedMessage id={"transportOrder.empties.confirmation.panel.title"}/>
            </h3>
        </div>
        <div className={"panel-body"}>
            <div className="display-flex justify-content-start">
                <Checkbox
                    onClick={(): void => {
                        if (state.appliesToAllShipments) {
                            dispatch({ type: "UNCHECK_APPLIES_TO_ALL" })
                        } else {
                            dispatch({ type: "CHECK_APPLIES_TO_ALL" })
                        }
                    }}
                    checked={state.appliesToAllShipments}
                    className={`padding-bottom-10 ${state.shipmentConfirmationsForNonCancelled.length <= 1 ? "hidden" : ""}`}
                    label={<FormattedMessage id={"transportOrder.confirmation.appliesToAllShipments.label"}/>}
                />
            </div>
            {(state.appliesToAllShipments && state.shipmentConfirmationsForNonCancelled.length > 0) ?
                <div className={"row display-flex align-items-center margin-bottom-5"} data-testid={"shipment-confirmation-row"}>
                    <ConfirmationRow
                        transportOrder={transportOrder}
                        shipmentConfirmation={state.shipmentConfirmationsForNonCancelled[0]}
                        title={undefined}
                        inputsVisible={true}
                        loadingTimeWindowTimeZone={timezoneOfLoadingAddress(transportOrder, state.shipmentConfirmationsForNonCancelled[0].shipmentId)}
                        onChange={(newShipmentConfirmation): void => dispatch({
                            type: "CHANGE_CONFIRMATION",
                            payload: newShipmentConfirmation
                        })}
                    />
                </div> : state.shipmentConfirmations.map((shipmentConfirmation, index) => {
                    const isCancelled = shipmentConfirmation.shipmentStatus === ShipmentStatus.CANCELLED
                    return (
                        <div className={"row display-flex align-items-center margin-bottom-5"} key={`shipmentConfirmationRow-${index}`}
                            data-testid={"shipment-confirmation-row"}>
                            <ConfirmationRow
                                transportOrder={transportOrder}
                                shipmentConfirmation={shipmentConfirmation}
                                title={
                                    <h6 className={`col-xs-3 ${isCancelled ? "text-decoration-line-through text-color-gray" : ""}`}>
                                        <FormattedMessage id={"transportOrder.shipment.label.enumerated"} values={{ num: index + 1 }}/>
                                        <CancelledStatusBadge show={isCancelled} />
                                    </h6>}
                                inputsVisible={!isCancelled}
                                loadingTimeWindowTimeZone={timezoneOfLoadingAddress(transportOrder, shipmentConfirmation.shipmentId)}
                                onChange={(newShipmentConfirmation): void => dispatch({
                                    type: "CHANGE_CONFIRMATION",
                                    payload: newShipmentConfirmation
                                })}
                            />
                        </div>
                    )
                })}
        </div>
    </div>
}

interface ConfirmationRowProps {
    title: React.ReactElement | undefined
    shipmentConfirmation: ShipmentConfirmation
    loadingTimeWindowTimeZone?: string
    inputsVisible: boolean
    transportOrder: TransportOrder

    onChange: (_: ShipmentConfirmation) => void
}

const ConfirmationRow = (props: ConfirmationRowProps): React.ReactElement => {

    const [shipmentConfirmation, setShipmentConfirmation] = useState<ShipmentConfirmation>(props.shipmentConfirmation)
    return <>
        {props.title}
        {props.inputsVisible ?
            <>
                <AssetSelect
                    label={<FormattedMessage id="transportOrder.confirmation.input.label.asset"/>}
                    assetId={shipmentConfirmation.confirmation?.asset?.id}
                    onAssetSelected={(asset): void => {
                        const newShipmentConfirmation: ShipmentConfirmation = {
                            ...shipmentConfirmation,
                            confirmation: {
                                ...shipmentConfirmation.confirmation,
                                asset: asset ? {
                                    id: asset.id,
                                    licensePlate: asset.licensePlate,
                                    countryCode: asset.licensePlateCountryCode
                                } : undefined
                            }
                        }
                        setShipmentConfirmation(newShipmentConfirmation)
                        props.onChange(newShipmentConfirmation)
                    }}
                    className={"col-xs-12 col-sm-3 col-md-2 col-lg-2 min-width-200"}
                />
                <VehicleTypeInput
                    className={"col-xs-12 col-sm-3 col-md-2 min-width-200"}
                    show={doesTransportOrderSupportVehicleType(props.transportOrder)}
                    value={shipmentConfirmation.confirmation.vehicleType}
                    onChange={(value: VehicleType | undefined): void => setShipmentConfirmation({
                        ...shipmentConfirmation,
                        confirmation: {
                            ...shipmentConfirmation.confirmation,
                            vehicleType: value,
                        }
                    })}
                />
                <TimeWindowInput
                    value={shipmentConfirmation.confirmation.loadingTimeWindow}
                    timezone={props.loadingTimeWindowTimeZone}
                    labelFrom={<FormattedMessage id="transportOrder.confirmation.input.label.loadingTimewindow.from"/>}
                    labelTo={<FormattedMessage id="transportOrder.confirmation.input.label.loadingTimewindow.to"/>}
                    validateFrom={ValidFromAndTimeWindowCompleteValidator}
                    validateTo={ValidToAndTimeWindowCompleteValidator}
                    className={"col-xs-12 col-sm-3 col-md-2 col-lg-1 min-width-200" }
                    onChange={(value: TimeWindow | undefined): void => {
                        const newShipmentConfirmation: ShipmentConfirmation = {
                            ...shipmentConfirmation,
                            confirmation: {
                                ...shipmentConfirmation.confirmation,
                                loadingTimeWindow: value,
                            }
                        }
                        setShipmentConfirmation(newShipmentConfirmation)
                        props.onChange(newShipmentConfirmation)
                    }}
                />
                <div className={"col-xs-12 col-sm-3 col-md-2 col-lg-1 min-width-200"}>
                    <TimeWindowBookingIdInput
                        confirmation={shipmentConfirmation.confirmation}
                        property={"accessCodeAtLoading"}
                        onChange={(value: Confirmation): void => {
                            const newShipmentConfirmation: ShipmentConfirmation = {
                                ...shipmentConfirmation,
                                confirmation: value
                            }
                            setShipmentConfirmation(newShipmentConfirmation)
                            props.onChange(newShipmentConfirmation)
                        }}
                    />
                </div>
                <DriverPhoneNumberInput
                    show={doesTransportOrderSupportDriverPhoneNumber(props.transportOrder)}
                    className={"col-xs-12 col-sm-3 col-md-2 min-width-200"}
                    value={shipmentConfirmation.confirmation.phoneNumber}
                    onChange={(value: string): void => {
                        const newShipmentConfirmation = {
                            ...shipmentConfirmation,
                            confirmation: {
                                ...shipmentConfirmation.confirmation,
                                phoneNumber: value || undefined,
                            },
                        }
                        setShipmentConfirmation(newShipmentConfirmation)
                        props.onChange(newShipmentConfirmation)
                    }}
                />
                <BorderoNumberInput
                    show={true}
                    className={"col-xs-12 col-sm-3 col-md-2 col-lg-1 min-width-200"}
                    confirmation={shipmentConfirmation.confirmation}
                    onChange={(value: Confirmation): void => {
                        const newShipmentConfirmation = {
                            ...shipmentConfirmation,
                            confirmation: {
                                ...shipmentConfirmation.confirmation,
                                loadId: value.loadId || undefined,
                            }
                        }
                        setShipmentConfirmation(newShipmentConfirmation)
                        props.onChange(newShipmentConfirmation)
                    }}
                />
            </> : undefined}
    </>
}
