import { uuid } from "./uuid"
import {
    Address,
    Article,
    CargoFreight,
    HandlingUnit,
    LoadingAddress, Packable,
    Shipment,
    ShipmentPriority,
    ShipmentStatus,
    TimeWindow,
    TransportOrder,
} from "../model/transportorder/transportOrder"
import { LengthMeasurementUnit } from "../model/transportorder/measurement"

export const createEmptyLoadingAddress = (time_window: TimeWindow = { time_to: "" }, optionalTimeWindow = false): LoadingAddress => ({
    name: "",
    time_window: optionalTimeWindow ? undefined : time_window
})

export const createEmptyShipment = (transportOrderId: string, priority: ShipmentPriority = ShipmentPriority.REGULAR): Shipment => ({
    id: uuid(),
    transport_order_id: transportOrderId,
    version: 0,
    isConfirmed: false,
    confirmationDraft: {},
    external_identification_type: "dispatch-web",
    processIndicator: "FTL",
    loading_address: createEmptyLoadingAddress(),
    unloading_address: createEmptyLoadingAddress(undefined, true),
    freight: {
        type: "CARGO",
        handling_units: []
    } as CargoFreight,
    status: ShipmentStatus.UNPLANNED,
    proposedVehicleType: "PASSENGER_CAR",
    priority: priority,
})


export const createEmptyAddress = (): Address => ({ street: "", city: "", postal_code: "", country: "DE" })

export const createHandlingUnit = (content: Packable[] = []): HandlingUnit => ({
    type: "HANDLING_UNIT",
    _frontendKey: `${uuid()}`,
    content: content,
    stacking_factor: 1,
    quantity: 1,
    packaging_material_id: "EURO",
    dimension: {
        length: { value: 800, unit: LengthMeasurementUnit.MMT },
        width: { value: 1200, unit: LengthMeasurementUnit.MMT }
    }
})

export const createEmptyArticle = (): Article => ({ type: "ARTICLE", _frontendKey: `${uuid()}`, quantity: 1, name: "VW Currywurst" })

export const containsUrgentShipment = (shipments?: Shipment[]): boolean => {
    const shipmentPriority = shipments?.find(shipment => shipment.priority === ShipmentPriority.URGENT)?.priority
    return shipmentPriority === ShipmentPriority.URGENT
}

export const getShipmentFromTransportOrder = (transportOrder: TransportOrder, shipmentId: string): Shipment | undefined => {
    const shipments = (transportOrder._embedded?.shipments ?? [])
    return shipmentId ? shipments.find(it => it.id === shipmentId) : shipments[0]
}
