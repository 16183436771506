import { convertToDomain, ExternalAssetList } from "./model"
import { getTokenFromSessionStorage } from "../common/auth"
import { Asset } from "../../model/asset/asset"
import { config } from "../../configuration/app/app-config"

class MfhClient {

    private url = config.mfhApi.baseUrl

    public getAssets = async (): Promise<Asset[]> => {
        const token = await getTokenFromSessionStorage()

        const requestInit = {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            },
            method: "GET"
        }
        return this.doRequest<ExternalAssetList>(`${this.url}/assets?status=active&limit=5000`, requestInit)
            .then(convertToDomain)
    }

    private doRequest = <T>(url: string, requestInit: RequestInit): Promise<T> => {
        return fetch(url, requestInit)
            .then(resp => {
                if (!resp.ok) {
                    return Promise.reject(`Error in request to MFH backend: ${resp.status} ${resp.statusText}`)
                }
                return resp.json()
            })
    }
}

export default new MfhClient()
