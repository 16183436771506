import * as React from "react"
import { ReactElement } from "react"
import { useIntl } from "react-intl"
import { ReadOnlyDetailViewFormValue } from "../../Form/ReadOnlyDetailViewFormValue"
import { TimeWindow } from "../../../model/transportorder/transportOrder"

export interface TimeWindowPresenterProps {
    value?: TimeWindow
    labelFrom: string | React.ReactElement
    labelTo: string | React.ReactElement
}

export const TimeWindowPresenter = (props: TimeWindowPresenterProps): ReactElement => {
    const intl = useIntl()
    const from = props.value?.time_from
    const to = props.value?.time_to
    const fromValue = from ? intl.formatDate(from) + " " + intl.formatTime(from) : "---"
    const toValue = to ? intl.formatDate(to) + " " + intl.formatTime(to) : "---"

    return (
        <div className={"form-group"}>
            <div className={"col-md-6"}>
                <ReadOnlyDetailViewFormValue label={props.labelFrom} value={fromValue} />
            </div>
            <div className={"col-md-6"}>
                <ReadOnlyDetailViewFormValue label={props.labelTo} value={toValue}/>
            </div>
        </div>
    )
}