import { BulkFreight, CargoFreight, Freight, FreightDiscriminator, HandlingUnit, ShipmentStatus, TransportOrder } from "./transportOrder"
import { Measurement, normalizeToKgm, normalizeToMTQ, VolumeMeasurementUnit, WeightMeasurementUnit } from "./measurement"

export enum AggregationHint {
    OK = "OK",
    NO_DATA = "NO_DATA",
    MISSING_DATA = "MISSING_DATA",
}

export type MeasurementWithAggregationHint<T> = Measurement<T> & { aggregationHint: AggregationHint }
export type WeightMeasurementWithAggregationHint = MeasurementWithAggregationHint<WeightMeasurementUnit>
export type VolumeMeasurementWithAggregationHint = MeasurementWithAggregationHint<VolumeMeasurementUnit>
export type CountWithAggregationHint = MeasurementWithAggregationHint<undefined>

interface FreightMeasurementSummary {
    weight: WeightMeasurementWithAggregationHint
    volume: VolumeMeasurementWithAggregationHint
    count: CountWithAggregationHint
}

export const hasContributions = (freightMeasurementSummary: FreightMeasurementSummary): boolean => {
    return freightMeasurementSummary.weight.aggregationHint !== AggregationHint.NO_DATA
        || freightMeasurementSummary.volume.aggregationHint !== AggregationHint.NO_DATA
        || freightMeasurementSummary.count.aggregationHint !== AggregationHint.NO_DATA
}

export type TransportOrderMeasurementSummary = { [K in FreightDiscriminator]: FreightMeasurementSummary }

export const summarizeMeasurements = (transportOrder: TransportOrder): TransportOrderMeasurementSummary => {
    let summary: TransportOrderMeasurementSummary = {
        CARGO: createEmptyFreightMeasurementSummary(),
        BULK_DRY: createEmptyFreightMeasurementSummary(),
        BULK_LIQUID: createEmptyFreightMeasurementSummary(),
    }
    const shipments = transportOrder._embedded?.shipments || []

    shipments
        .filter(shipment => shipment.status !== ShipmentStatus.CANCELLED)
        .map(shipment => {
            return {
                ...shipment,
                freight: {
                    ...shipment.freight,
                    gross_weight: normalizeToKgm(shipment.freight.gross_weight),
                    gross_volume: normalizeToMTQ(shipment.freight.gross_volume)
                }
            }
        }).forEach(shipment => summary = add(shipment.freight, summary))

    return summary
}

function createEmptyFreightMeasurementSummary(): FreightMeasurementSummary {
    return {
        volume: { value: undefined, unit: VolumeMeasurementUnit.MTQ, aggregationHint: AggregationHint.NO_DATA },
        weight: { value: undefined, unit: WeightMeasurementUnit.KGM, aggregationHint: AggregationHint.NO_DATA },
        count: { value: undefined, unit: undefined, aggregationHint: AggregationHint.NO_DATA },
    }
}

function add(freight: Freight, summary: TransportOrderMeasurementSummary): TransportOrderMeasurementSummary {
    switch (freight.type) {
    case "CARGO":
        return { ...summary, CARGO: addCargo(summary.CARGO, freight as CargoFreight) }
    case "BULK_DRY":
        return { ...summary, BULK_DRY: addBulk(summary.BULK_DRY, freight as BulkFreight) }
    case "BULK_LIQUID":
        return { ...summary, BULK_LIQUID: addBulk(summary.BULK_LIQUID, freight as BulkFreight) }
    }
}

function addBulk(freightMeasurementSummary: FreightMeasurementSummary, freight: BulkFreight): FreightMeasurementSummary {
    return {
        ...freightMeasurementSummary,
        weight: plus(freightMeasurementSummary.weight, withAggregationHint(freight.gross_weight, WeightMeasurementUnit.KGM)),
        volume: plus(freightMeasurementSummary.volume, withAggregationHint(freight.gross_volume, VolumeMeasurementUnit.MTQ)),
    }
}

function addCargo(freightMeasurementSummary: FreightMeasurementSummary, freight: CargoFreight): FreightMeasurementSummary {
    return {
        ...freightMeasurementSummary,
        weight: plus(freightMeasurementSummary.weight, withAggregationHint(freight.gross_weight, WeightMeasurementUnit.KGM)),
        volume: plus(freightMeasurementSummary.volume, withAggregationHint(freight.gross_volume, VolumeMeasurementUnit.MTQ)),
        count: plus(freightMeasurementSummary.count, aggregateHandlingUnitQuantities(freight.handling_units))
    }
}

function withAggregationHint<T>(measurement: Measurement<T> | undefined, unit: T): MeasurementWithAggregationHint<T> {
    if (measurement === undefined) {
        return { aggregationHint: AggregationHint.MISSING_DATA, value: undefined, unit }
    }
    if (measurement.value === undefined) {
        return { aggregationHint: AggregationHint.MISSING_DATA, value: undefined, unit: measurement.unit }
    }
    return { aggregationHint: AggregationHint.OK, value: measurement.value, unit: measurement.unit }
}

function plus<T>(aggregate: MeasurementWithAggregationHint<T>, toAdd: MeasurementWithAggregationHint<T>): MeasurementWithAggregationHint<T> {
    const firstValue = aggregate.value
    const secondValue = toAdd.value

    if (secondValue === undefined) {
        return { ...aggregate, aggregationHint: AggregationHint.MISSING_DATA }
    }

    if (firstValue === undefined) {
        return {
            ...aggregate,
            value: secondValue,
            aggregationHint: funnelHints(aggregate.aggregationHint, toAdd.aggregationHint),
        }
    } else {
        return {
            ...aggregate,
            value: firstValue + secondValue,
            aggregationHint: funnelHints(aggregate.aggregationHint, toAdd.aggregationHint),
        }
    }
}

function funnelHints(a: AggregationHint, b: AggregationHint): AggregationHint {
    switch (a) {
    case AggregationHint.OK:
        return b === AggregationHint.MISSING_DATA ? AggregationHint.MISSING_DATA : AggregationHint.OK
    case AggregationHint.NO_DATA:
        return b
    case AggregationHint.MISSING_DATA:
        return AggregationHint.MISSING_DATA
    }
}

function aggregateHandlingUnitQuantities(handlingUnits: HandlingUnit[]): CountWithAggregationHint {
    if (handlingUnits.length === 0) {
        return { unit: undefined, value: undefined, aggregationHint: AggregationHint.MISSING_DATA }
    }
    return handlingUnits.reduce<CountWithAggregationHint>(
        (prev: CountWithAggregationHint, curr: HandlingUnit) => {
            return plus(prev, countHandlingUnitQuantity(curr))
        },
        { unit: undefined, value: 0, aggregationHint: AggregationHint.NO_DATA })
}


function countHandlingUnitQuantity(handlingUnit: HandlingUnit): CountWithAggregationHint {
    return {
        value: handlingUnit.quantity,
        unit: undefined,
        aggregationHint: (handlingUnit.quantity !== undefined ? AggregationHint.OK : AggregationHint.MISSING_DATA)
    }
}
