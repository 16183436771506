import { getTimezoneOfLoading } from "../../../timezones/timezones"
import { FormattedMessage } from "react-intl"
import { ValidFromAndTimeWindowCompleteValidator, ValidToAndTimeWindowCompleteValidator } from "./validator"
import { Shipment, TimeWindow } from "../../../model/transportorder/transportOrder"
import { TimeWindowInput } from "../timewindow/TimeWindowInput"
import { Props as DateTimeInputProps } from "../timewindow/DateTimeInput"
import moment from "moment-timezone"
import OverlayTrigger from "@rio-cloud/rio-uikit/OverlayTrigger"
import Tooltip from "@rio-cloud/rio-uikit/Tooltip"
import { Validator } from "../../Form/validation/Validators"

interface Props {
    value?: TimeWindow
    show?: boolean
    shipment?: Shipment
    dateTimePropsFrom?: Partial<DateTimeInputProps>
    dateTimePropsTo?: Partial<DateTimeInputProps>
    onChange?: (_: TimeWindow | undefined) => void
}

export const MaterialOrderConfirmationLoadingTimeWindowInput = (props: Props) => {
    const { value, show, shipment, dateTimePropsFrom, dateTimePropsTo, onChange } = props
    const minDate = getMomentForRequestedPickupDate(shipment)

    return <TimeWindowInput
        value={value}
        show={show}
        timezone={getTimezoneOfLoading(shipment)}
        labelFrom={<FormattedMessageWithInfoPopover
            id={"transportOrder.confirmation.input.label.loadingTimewindow.from"}
            infoMessageId={"transportOrder.confirmation.input.label.loadingTimewindow.info"}
        />}
        labelTo={<FormattedMessage id="transportOrder.confirmation.input.label.loadingTimewindow.to" />}
        validateFrom={timeWindowValidatorFrom(shipment)}
        validateTo={timeWindowValidatorTo(shipment)}
        dateTimePropsFrom={{
            datePickerProps: { isValidDate: (currentDate) => isAfterRequestedPickupDate(currentDate, minDate) },
            ...dateTimePropsFrom,
        }}
        dateTimePropsTo={{
            datePickerProps: { isValidDate: (currentDate) => isAfterRequestedPickupDate(currentDate, minDate) },
            ...dateTimePropsTo,
        }}
        onChange={onChange}
    />
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isAfterRequestedPickupDate = (dateToEvaluate: any, minDate?: moment.Moment): boolean => {
    if (!minDate) {
        return true
    }
    if (moment.isMoment(dateToEvaluate) || dateToEvaluate instanceof Date) {
        return minDate.isSameOrBefore(dateToEvaluate, "day")
    }
    return true
}

const FormattedMessageWithInfoPopover = (props: {
    id: string
    infoMessageId: string
}) => {
    const tooltip = <Tooltip id="tooltip" allowOnTouch width={300} textAlignment={"left"}>
        <FormattedMessage id={props.infoMessageId} />
    </Tooltip>

    return <OverlayTrigger
        placement={"top"}
        overlay={tooltip}>
        <div>
            <FormattedMessage id={props.id} />
            <span className="rioglyph rioglyph-info-sign margin-left-5" />
        </div>
    </OverlayTrigger>
}

const timeWindowValidatorFrom = (shipment: Shipment | undefined):  Validator<TimeWindow> => {
    return (timeWindow: TimeWindow | undefined) =>
        ValidFromAndTimeWindowCompleteValidator(timeWindow) ||
        validateTimeStamp(timeWindow?.time_from, shipment, shipment?.confirmationDraft.loadingTimeWindow?.time_from)
}

const timeWindowValidatorTo = (shipment: Shipment | undefined):  Validator<TimeWindow> => {
    return (timeWindow: TimeWindow | undefined) =>
        ValidToAndTimeWindowCompleteValidator(timeWindow) ||
        validateTimeStamp(timeWindow?.time_to, shipment, shipment?.confirmationDraft.loadingTimeWindow?.time_to)
}

const validateTimeStamp = (
    timestamp: string | undefined,
    shipment: Shipment | undefined,
    existingValue: string | undefined,
): string | undefined => {
    if (!timestamp || !shipment) {
        return undefined
    }
    const requestedPickupDateTime = getMomentForRequestedPickupDate(shipment)
    if (!requestedPickupDateTime) {
        return undefined
    }
    const latestAllowedTimeToChangeTimeWindow = requestedPickupDateTime.clone()
        .tz("Europe/Berlin").startOf("day").add(-1, "days").hours(17)
    const startOfRequestedPickupDay = requestedPickupDateTime.clone()
        .tz(getTimezoneOfLoading(shipment) || "Europe/Berlin").startOf("day")
    const changed = !isSameInstant(timestamp, existingValue)
    if (changed) {
        return validateVsRequested(latestAllowedTimeToChangeTimeWindow, startOfRequestedPickupDay, timestamp)
    }
    return undefined
}

const getMomentForRequestedPickupDate = (shipment?: Shipment): moment.Moment | undefined => {
    const requestedPickUpFrom = shipment?.loading_address.time_window?.time_from
    if (!requestedPickUpFrom) {
        return undefined
    }
    return moment(requestedPickUpFrom)
}

const isSameInstant = (t1: string | undefined, t2: string | undefined): boolean => {
    if (!t1 && !t2) {
        return true
    }
    if (!t1 || !t2) {
        return false
    }
    const m1 = moment(t1)
    const m2 = moment(t2)
    return m1.isSame(m2)
}

function validateVsRequested(latestAllowedTimeToChange: moment.Moment, startOfRequestedPickupDay: moment.Moment, timestamp: string) {
    if (moment().isAfter(timestamp)) {
        return "validation.timestamp.must.not.be.in.past"
    }
    if (moment().isAfter(latestAllowedTimeToChange)) {
        return "validation.date.must.not.be.changed.after.eob.of.day.before.pickup"
    }
    if (startOfRequestedPickupDay.isAfter(timestamp)) {
        return "validation.date.must.not.be.before.given.pickup"
    }
    return undefined
}
