import { ReactElement, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Confirmation } from "../../../model/confirmation/Confirmation"
import Checkbox from "@rio-cloud/rio-uikit/Checkbox"
import { FormGroupWrapper } from "../../Form/FormGroupWrapper"

interface Props {
    show: boolean
    confirmation: Confirmation
    onChange: (checked: boolean) => void
    className?: string
}

export const TwoDriversCheckbox = (props: Props): ReactElement | null => {
    const [checked, setChecked] = useState(props.confirmation.twoDrivers === true)

    if (!props.show) {
        return null
    }

    return (
        <FormGroupWrapper
            label={<FormattedMessage id={"transportOrder.confirmation.input.label.vehicleCrew"} />}
            component={
                <div className={"form-group margin-top-5"}>
                    <Checkbox
                        onClick={(): void => {
                            const newValue = !checked
                            setChecked(newValue)
                            props.onChange(newValue)
                        }}
                        checked={checked}
                        label={<FormattedMessage id={"transportOrder.confirmation.input.label.twoDrivers"}/>}
                    />
                </div>
            }
            className={props.className}
            data-testid={"two-drivers-checkbox"}
        />
    )
}
