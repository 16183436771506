import { LoadingAddress, LoadingAddressType, Shipment } from "../model/transportorder/transportOrder"
import { nameAndAddressEqual } from "./loadingAddressHelper"
import moment from "moment"

export const sortShipmentByDueDate = (shipments: Shipment[]): Shipment[] => {
    return shipments.toSorted((a, b) => {
        const aDue = a.unloading_address.time_window?.time_to ?? 0
        const bDue = b.unloading_address.time_window?.time_to ?? 0
        /*
           if one of the time windows is undefined it will be sorted below the other one,
           because moment(0) results in 1970-01-01
           whereas moment(undefined) results in "now"
         */
        return moment(aDue).diff(moment(bDue))
    })
}

export const getNumDistinctAddresses = (shipments: Shipment[], loadingAddressType: LoadingAddressType): number => {
    const distinctAddresses: LoadingAddress[] = []
    shipments
        .map(it => "UNLOAD" === loadingAddressType ? it.unloading_address : it.loading_address)
        .forEach(loadingAddress => {
            const alreadySeen = distinctAddresses.find(element => nameAndAddressEqual(element, loadingAddress)) !== undefined
            if (!alreadySeen) {
                distinctAddresses.push(loadingAddress)
            }
        })
    return distinctAddresses.length
}
