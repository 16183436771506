import moment from "moment-timezone"
import { Address, LoadingAddress, Shipment, TransportOrder } from "../model/transportorder/transportOrder"
import { CountryToTimezone } from "./CountryToTimeZone"

export const timezoneOfLoadingAddress = (transportOrder: TransportOrder, shipmentId?: string): string | undefined => {
    const shipments = (transportOrder._embedded?.shipments ?? [])
    const shipment = shipmentId ? shipments.find(it => it.id === shipmentId) : shipments[0]
    return getTimezoneOfLoading(shipment)
}

export const timezoneOfUnloadingAddress = (transportOrder: TransportOrder, shipmentId?: string): string | undefined => {
    const shipments = (transportOrder._embedded?.shipments ?? [])
    const shipment = shipmentId ? shipments.find(it => it.id === shipmentId) : shipments[0]
    return getTimezoneOfUnloading(shipment)
}

export const getTimezoneOfLoading = (shipment?: Shipment): string | undefined =>
    getTimezoneAtLoadingAddress(shipment?.loading_address)

export const getTimezoneOfUnloading = (shipment?: Shipment): string | undefined =>
    getTimezoneAtLoadingAddress(shipment?.unloading_address)

export const getTimezoneAtLoadingAddress = (loadingAddress: LoadingAddress | undefined): string | undefined =>
    getTimezoneAtAddress(loadingAddress?.address)

const getTimezoneAtAddress = (address: Address | undefined): string | undefined => {
    const country = address?.country
    return country ? CountryToTimezone.get(country) : undefined
}

export const getClientTimezone = (): string => moment.tz.guess(true)
