import * as React from "react"
import { ClearableStringPropertyInput } from "../../Form/ClearableStringPropertyInput"
import { FormattedMessage } from "react-intl"
import { OnlyDigitsValidator, Validator } from "../../Form/validation/Validators"
import { Confirmation } from "model/confirmation/Confirmation"


interface Props {
    confirmation: Confirmation
    onChange: (confirmation: Confirmation) => void
    property: "accessCodeAtLoading" | "accessCodeAtUnloading"
    xs?: number
    sm?: number
    md?: number
    print?: number
    disabled?: boolean
    className?: string
    warnIfMissing?: boolean
}

export const TimeWindowBookingIdInput = (props: Props): React.ReactElement =>
    <div data-testid={"timewindow-id-input"} className={props.className}>
        <ClearableStringPropertyInput
            data={props.confirmation}
            onPropertyChange={props.onChange}
            xs={props.xs}
            sm={props.sm}
            md={props.md}
            print={props.print}
            property={props.property}
            label={<FormattedMessage id={"transportOrder.confirmation.input.label.timewindowbookingid"}/>}
            required={false}
            disabled={props.disabled}
            validate={((value): string | undefined => (OnlyDigitsValidator(value) || TimeWindowBookingValidator(value)))}
            validateWarnings={((value): string | undefined => props.warnIfMissing ? TimeWindowBookingMissingValidator(value) : undefined)}
        />
    </div>

const TimeWindowBookingValidator: Validator<string> = (value?: string) => {
    return value ? value.length <= 15 ? undefined : `validation.timewindowbookingid.improper.length` : undefined
}

const TimeWindowBookingMissingValidator: Validator<string> = (value?: string) => {
    return value === undefined || value === null || value.trim().length === 0 ? `transportOrder.confirmation.input.label.timewindowbookingid.missing` : undefined
}
