import { Address, LoadingAddress, LocationIdentification } from "../model/transportorder/transportOrder"
import { isEqualIgnoringUndefined } from "./equalityHelper"

export const nameAndAddressEqual = (a: LoadingAddress, b: LoadingAddress): boolean => {
    return a.name === b.name && isEqualIgnoringUndefined(a.address, b.address)
}

// for compatability reasons we still use address.addition as a fallback; furthermore this is the field that is edited, when
// we have a manually created transport order.
export const retrieveAppropriateLocationDetails = (locationIdentification: LocationIdentification | undefined, address: Address): string | undefined => {
    if (!locationIdentification) {
        return address.addition
    }
    if (locationIdentification.place_of_loading) {
        return locationIdentification.place_of_loading
    }

    if(locationIdentification.place_of_discharge) {
        return locationIdentification.place_of_discharge
    }

    return undefined
}
