import { ShipmentStatus, TimeWindow, TransportOrder, VehicleType } from "../transportorder/transportOrder"
import omit from "lodash/omit"
import { isEqualIgnoringUndefined } from "../../helper/equalityHelper"

export interface Confirmation {
    updatedAt?: string
    asset?: Asset
    accessCodeAtLoading?: string
    accessCodeAtUnloading?: string
    loadingTimeWindow?: TimeWindow
    unloadingTimeWindow?: TimeWindow
    phoneNumber?: string
    vehicleType?: VehicleType
    twoDrivers?: boolean
    loadId?: string
    crossDockId?: string
}

export interface Asset {
    id: string
    licensePlate?: string
    countryCode?: string
}

export interface ShipmentConfirmation {
    shipmentId: string
    shipmentStatus?: ShipmentStatus
    version: number
    confirmation: Confirmation
}

export const applyConfirmationToAllShipments = (confirmation: Confirmation, transportOrder: TransportOrder): ShipmentConfirmation[] => {
    const allConfirmations = transportOrder._embedded?.shipments.map(shipment => ({
        shipmentId: shipment.id,
        shipmentStatus: shipment.status,
        version: shipment.version,
        confirmation: confirmation,
    })) ?? []
    return confirmationsForNonCancelled(allConfirmations)
}

export const confirmationsForNonCancelled = (confirmations: ShipmentConfirmation[]): ShipmentConfirmation[] =>
    confirmations.filter(it => it.shipmentStatus !== ShipmentStatus.CANCELLED)

export const areConfirmationsEqual = (one: Confirmation, another: Confirmation): boolean => {
    return isEqualIgnoringUndefined(
        omit(one, ["updatedAt"]),
        omit(another, ["updatedAt"])
    )
}

export const areShipmentConfirmationsEqual = (one: ShipmentConfirmation[], another: ShipmentConfirmation[]): boolean => {
    return (
        one.length === another.length &&
        one.every(oneConfirmation => {
            const other = another.find(anotherConfirmation => anotherConfirmation.shipmentId === oneConfirmation.shipmentId)

            return other ? areConfirmationsEqual(oneConfirmation.confirmation, other.confirmation) : false
        }) &&
        another.every(oneConfirmation => {
            const other = one.find(anotherConfirmation => anotherConfirmation.shipmentId === oneConfirmation.shipmentId)

            return other ? areConfirmationsEqual(oneConfirmation.confirmation, other.confirmation) : false
        })
    )
}
