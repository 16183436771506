import { ReactElement } from "react"
import { FormattedMessage } from "react-intl"
import ClearableInput from "@rio-cloud/rio-uikit/ClearableInput"
import { FormGroupWrapper } from "../../Form/FormGroupWrapper"

export interface DriverPhoneNumberInputProps {
    show?: boolean
    className?: string
    value?: string
    onChange: (value: string) => void
}

export const DriverPhoneNumberInput = (props: DriverPhoneNumberInputProps): ReactElement => {
    if (props.show == false) { // iff props.show set to true or undefined
        return <></>
    }
    return (<FormGroupWrapper
        dataTestId={"driver-phonenumber-input"}
        className={props.className}
        label={<FormattedMessage id="transportOrder.confirmation.input.label.phoneNumber" />}
        component={
            <ClearableInput
                value={props.value}
                onChange={(value: string): void => props.onChange(value)}
            />
        }
    />)
}