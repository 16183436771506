import { getTokenFromSessionStorage } from "../common/auth"
import { config } from "../../configuration/app/app-config"

export interface SuccessFetchVolumeForecastsResult {
    fileName: string
    fileContent: Blob
}

export type NoData = "NO_DATA"

export type FetchVolumeForecastsResult = SuccessFetchVolumeForecastsResult | NoData

class VolumeForecastClient {

    private url = config.transportOrderApi.baseUrl

    public getVolumeForecasts = async (): Promise<FetchVolumeForecastsResult> => {
        const token = await getTokenFromSessionStorage()
        const requestInit = {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Accept": "application/zip"
            },
        }

        const url = `${this.url}/volume-forecasts`
        return fetch(url, requestInit)
            .then(this.toFetchVolumeForecastsResult)
    }

    private toFetchVolumeForecastsResult = (resp: Response): Promise<FetchVolumeForecastsResult> => {
        if (!resp.ok) {
            return Promise.reject<FetchVolumeForecastsResult>(`Error in request ${resp.status} ${resp.statusText}`)
        }
        if (resp.status === 204) {
            return Promise.resolve("NO_DATA")
        }

        const fileName = this.getFileName(resp)
        if (!fileName) {
            return Promise.reject(
                `Error in request ${resp.status} ${resp.statusText}.
                    Unable to extract fileName from header ${resp.headers.get("Content-Disposition")}`
            )
        }

        return resp.blob().then(fileContent => ({ fileName, fileContent }))
    }

    private getFileName = (resp: Response): string | undefined => {
        const contentDisposition = resp.headers.get("Content-Disposition")
        if (contentDisposition) {
            const matches = contentDisposition.match(/filename="(.+)"/)
            if (matches) {
                return matches[1]
            }
        }
        return undefined
    }
}

export default new VolumeForecastClient()
