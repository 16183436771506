import { DateTimeValidator, Validator } from "../../Form/validation/Validators"
import { TimeWindow } from "../../../model/transportorder/transportOrder"

export const TimeFromNotNullIfTimeToNotNullValidator: Validator<TimeWindow> = (timeWindow: TimeWindow | undefined) => {
    if (timeWindow === undefined || timeWindow.time_to === undefined) {
        return undefined
    }
    return timeWindow.time_from === undefined ? "validation.timewindow.both.values.required" : undefined
}

export const TimeToNotNullIfTimeFromNotNullValidator: Validator<TimeWindow> = (timeWindow: TimeWindow | undefined) => {
    if (timeWindow === undefined || timeWindow?.time_from === undefined) {
        return undefined
    }
    return timeWindow.time_to === undefined ? "validation.timewindow.both.values.required" : undefined
}

export const ValidFromAndTimeWindowCompleteValidator = (timeWindow: TimeWindow | undefined): string | undefined => {
    return DateTimeValidator(timeWindow?.time_from) || TimeFromNotNullIfTimeToNotNullValidator(timeWindow)
}

export const ValidToAndTimeWindowCompleteValidator = (timeWindow: TimeWindow | undefined): string | undefined => {
    return DateTimeValidator(timeWindow?.time_to) || TimeToNotNullIfTimeFromNotNullValidator(timeWindow)
}
