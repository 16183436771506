import * as React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { VehicleType } from "../../model/transportorder/transportOrder"
import { ReadOnlyDetailViewFormValue } from "../Form/ReadOnlyDetailViewFormValue"

export interface ProposedVehicleTypeProps {
    proposedVehicleType: VehicleType
}

const getVehicleTypeIcon = (vehicleType: VehicleType): string => {
    if (vehicleType == "PASSENGER_CAR") {
        return "rioglyph rioglyph-car"
    } else if (vehicleType == "VAN") {
        return "rioglyph rioglyph-van"
    } else if (vehicleType == "LIGHT_TRUCK") {
        return "rioglyph rioglyph-truck"
    } else if (vehicleType == "MEDIUM_TRUCK") {
        return "rioglyph rioglyph-truck"
    } else {
        return "rioglyph rioglyph-truck"
    }
}

const getVehicleTypeIntlId = (vehicleType: VehicleType): string => {
    return "transportOrder.shipment.vehicle." + vehicleType.replace("_", "").toLocaleLowerCase()
}

export const ProposedVehicleType = (props: ProposedVehicleTypeProps): React.ReactElement => {
    const intl = useIntl()
    return (<span>
        <label><FormattedMessage id="transportOrder.shipment.vehicle.proposed"/></label>
        <div className={"input-group"}>
            <span className={getVehicleTypeIcon(props.proposedVehicleType)}/>
            <ReadOnlyDetailViewFormValue
                value={intl.formatMessage({ id: getVehicleTypeIntlId(props.proposedVehicleType) })}
            />
        </div>
    </span>)
}

